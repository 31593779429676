<h2 mat-dialog-title>Delete clip Bin</h2>
<div mat-dialog-content>
    <p>Are you sure you want to delete this clip bin? The associated shared links will also be revoked.</p>
</div>
<div mat-dialog-actions>
    <button
        mat-button
        mat-dialog-close
        mam-firebase-ga="Cancel clip bin deletion"
        [mam-firebase-resource]="data.resource.iasData.label.displayName"
    >
        Cancel
    </button>
    <button
        mat-button
        [mat-dialog-close]="true"
        (keyup.enter)="dialogRef.close(true)"
        color="primary"
        mam-firebase-ga="Confirm clip bin deletion"
        [mam-firebase-resource]="data.resource.iasData.label.displayName"
    >
        Delete
    </button>
</div>
