import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output, TemplateRef,
} from '@angular/core';

import {
  OrganizersActionOptions,
  OrganizerTypes
} from '../../../landing/clip-bin-section/service/resource-types';

/**
 * New action button with a custom MatButton color scheme.
 */
@Component({
  selector: 'mam-new-action-button',
  templateUrl: './new-action-button.ng.html',
  styleUrls: ['./new-action-button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewActionButton {
  @Input() actions: Map<OrganizerTypes, OrganizersActionOptions> = new Map();
  @Input() label: string = '';
  @Input() icon: string = 'add';
  @Input() btnClass: string = '';
  @Input() optionSpacing: boolean = true;
  @Input() disabled: boolean = false;
  @Input() squared: boolean = false;
  @Input() small: boolean = false;
  @Input() showErrorMsg: boolean = true;
  // use the order field to order the list of actions
  @Input() ordering: boolean = true;
  // Where the button is rendered
  @Input() origin: string = '';

  @Output() clickAction = new EventEmitter<OrganizerTypes>();
  @Output() uploadAction = new EventEmitter<HTMLInputElement>();

  /** Upload Files Input. */
    @ContentChild('type') type!: TemplateRef<never>;

  get actionList() {
    const list = Array.from(this.actions.values());
    if (!this.ordering) return list;
    return list.sort((a, b) => a.order - b.order);
  }

  onClick(job: OrganizerTypes) {
    this.clickAction.emit(job);
  }


}
