import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router, UrlCreationOptions } from '@angular/router';
import {  map, Observable, ReplaySubject, takeUntil } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { ResourceAccessInfo } from '../../access_management/models/access_management.model';
import { AccessManagementService } from '../../access_management/services/access_management.service';
import { AccessManagementActionsService } from '../../access_management/services/access_management_actions.service';
import { AuthService } from '../../auth/auth_service';
import { environment } from '../../environments/environment';
import { FeatureFlagService } from '../../feature_flag/feature_flag_service';
import { FirebaseAnalyticsService } from '../../firebase/firebase_analytics_service';
import { FirebasePerformanceService, Trace, TraceName } from '../../firebase/firebase_performance_service';
import { PluginService } from '../../plugin/plugin_service';
import { StagingService } from '../../right_panel/staging_service';
import { MetadataField } from '../../services/asset_api_service';
import { Asset, Original } from '../../services/asset_service';
import { FileState } from '../../services/media_cache_service';
import { PreferencesService } from '../../services/preferences_service';
import { ProgressbarService } from '../../services/progressbar_service';
import { ResizeObserverService } from '../../services/resize_observer_service';
import { SearchInputService } from '../../services/search_input_service';
import { SearchMode, SearchType } from '../../services/search_service';
import { StateService } from '../../services/state_service';
import { TableUtils } from '../../services/table_utils';
import {
  DEFAULT_LIST_PAGE_SIZE,
  DisplayMode,
  PaddedSegment,
  VodSearchService
} from '../../services/vod_search_service';
import { BatchOperationService } from '../../shared/batch_operation_service';
import { MultiSelectOption, MultiSelectOptions } from '../../transfer_monitor/multiselect_table_header';
import { RowEvent, StorageItemState, StorageType, TableCol, TableSort } from '../../ui/ui_table.type';


const ALL_COLUMNS = [
  'select', 'title', 'duration', 'content-type', 'event-time', 'last-modified', 'permission',
  'location', 'action'
] as const;

const enum TableWidthBreakpoint {
  LARGE = 1250,
  MEDIUM = 800
}

export const GRID_PAGE_SIZE = 24;

/**Limit the request to 100 assets to avoid performance issues */
const LIMIT_OF_REQUEST_TO_RESTRICT_ASSETS = 100;

interface SearchResponse {
  segments: PaddedSegment[];
  isInitialFacetsResponse: boolean;
}

/** Page size options for list view. */
const PAGE_SIZE_OPTIONS = [30, 50, 100, 200];

@Component({
  selector: 'mam-search-list-view',
  templateUrl: './search-list-view.ng.html',
  styleUrl: './search-list-view.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchListView implements OnDestroy, AfterViewInit {
  @ViewChild('searchResultsEl') searchResultsEl!: ElementRef<HTMLElement>;

  /** Visible table columns for list view. Changed based on screen size. */
  displayedColumns = [...ALL_COLUMNS];

  /** Asset that will be used for multi-select (shift+click) operations.  */
  private selectionAnchorAssetName = '';
  readonly PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  /**
   * Search request results. Contain padded segments for the red areas on each
   * card, and whether they are the result of the initial facets empty response.
   */
  readonly results$: Observable<SearchResponse | undefined>;

  /** Indicates what search was used for the recent search. */
  readonly searchMode$: Observable<SearchMode | undefined>;

  readonly SearchMode = SearchMode;

  private readonly performanceTrace?: Trace;
  private readonly destroyed$ = new ReplaySubject<void>(1);

  /** Flag to use Access Management feature. */
  readonly isEnabledAccessManagement = this.featureService.featureOn('enable-access-management');

  /** Flag to use List views feature. */
  readonly showListView = this.featureService.featureOn('use-table-list-views');

  cols: TableCol[] =  [];

  private initialCols = (isEnabledAccessManagement:boolean, userHasRestrictedAssets: boolean) : TableCol[] => {
    return [
    {
      key: 'checkbox',
      name: '',
      resizer:false,
      dragger: false,
      headerTpl: 'checkBoxTpl',
      cellTpl: 'checkBoxTpl',
      headerStyle: {
        width: 'var(--table-checkbox-width)',
        minWidth: 'var(--table-checkbox-minwidth)',
        maxWidth: 'var(--table-checkbox-width)',
      },
      cellStyle: {
        textOverflow: 'clip'
      },
      order: 0,
      orderMenu: 0
    },
    {
      key: 'title',
      name: 'Title / File name',
      optioner: true,
      resizer: true,
      sorter: true,
      dragger: false,
      disabled: true,
      cellTpl: 'nameTpl',
      headerStyle: {
        width: '180px',
        minWidth: '120px'
      },
      classCel: ['main-column'],
      class: ['icon-with-text'],
      order: 1,
      orderMenu: 1
    },
    {
      key: 'duration',
      name: 'Duration',
      optioner: true,
      sorter: true,
      resizer: true,
      dragger: true,
      cellTpl: 'durationTpl',
      headerStyle: {
        width: '100px',
        minWidth: '80px'
      },
      order: 2,
      orderMenu: 2
    },
    {
      key: 'content-type',
      name: 'Content Type',
      optioner: true,
      sorter: true,
      resizer: true,
      dragger: true,
      cellTpl: 'contentTypeTpl',
      headerStyle: {
        width: '130px',
        minWidth: '100px'
      },
      order: 3,
      orderMenu: 3
    },
    {
      key: 'event-time',
      name: 'Event Time',
      optioner: true,
      sorter: true,
      resizer: true,
      dragger: true,
      cellTpl: 'eventTimeTpl',
      headerStyle: {
        width: '135px',
        minWidth: '80px'
      },
      order: 4,
      orderMenu: 4
    },
    {
      key: 'last-modified',
      name: 'Last Modified',
      optioner: true,
      sorter: true,
      resizer: true,
      dragger: true,
      cellTpl: 'lastModifiedTpl',
      headerStyle: {
        width: '130px',
        minWidth: '80px'
      },
      order: 5,
      orderMenu: 5
    },
    {
      key: 'permission',
      name: 'Permission',
      optioner: true,
      sorter: false,
      resizer: true,
      dragger: true,
      hidden: !isEnabledAccessManagement || !userHasRestrictedAssets,
      disabled: !isEnabledAccessManagement || !userHasRestrictedAssets,
      cellTpl: 'permissionTpl',
      headerStyle: {
        width: '100px',
        minWidth: '70px'
      },
      order: 6,
      orderMenu: 6
    },
    {
      key: 'location',
      name: 'Storage',
      resizer: true,
      dragger: true,
      headerTpl: 'storageTpl',
      cellTpl: 'storageTpl',
      cellStyle: {
        textAlign: 'center',
        paddingRight: '0',
      },
      headerStyle: {
        width: '110px',
        minWidth: '60px'
      },
      order: 7,
      orderMenu: 7
    },
    {
      key: 'action',
      name: '',
      sticky: true,
      stickyEnd: true,
      dragger: false,
      cellTpl: 'actionTpl',
      colStyle: {
        minWidth: '48px',
        textAlign: 'center'
      },
      headerStyle: {
        width: '48px',
        minWidth: '48px'
      },
      order: 8,
      orderMenu: 8
    }
    ];
  };

  tableId:string;

  tableData: PaddedSegment[] = [];

  /** Options for status filter. */
  storageOptions: MultiSelectOptions<StorageType> = [
    {title: 'All', selected: true},
    {title: 'Cloud', value: FileState.FILE_CLOUD_ONLY},
    {title: 'OnPrem', value: FileState.FILE_ONPREM_ONLY},
    {title: 'Cloud/OnPrem', value: FileState.FILE_CLOUD_AND_ONPREM},
  ];

  storageList: Map<string,StorageItemState> = new Map();
  storageType: StorageType = FileState.FILE_CLOUD_ONLY;
  selectedStorageType: string = 'Storage';

  constructor(
    private router: Router,
    readonly tableUtils: TableUtils,
    readonly stateService: StateService,
    private readonly host: ElementRef<HTMLElement>,
    private readonly cdr: ChangeDetectorRef,
    private readonly preferences: PreferencesService,
    private readonly resizeObserver: ResizeObserverService,
    readonly pluginService: PluginService,
    private readonly batchOperationService: BatchOperationService,
    searchInputService: SearchInputService,
    readonly stagingService: StagingService,
    readonly progressbar: ProgressbarService,
    readonly vodSearchService: VodSearchService,
    performanceService: FirebasePerformanceService,
    private readonly analyticsService: FirebaseAnalyticsService,
    readonly featureService: FeatureFlagService,
    private readonly accessManagementService: AccessManagementService,
    private readonly accessManagementActionsService: AccessManagementActionsService,
    readonly authService: AuthService
  ) {

    this.tableId = environment.tableInfoId['searchListViewTable'];

    this.performanceTrace =
      performanceService.startTrace(TraceName.SEARCH_RESULT_PAGE_READY);
    searchInputService.searchType$.next(SearchType.VOD);

    // Converts results to padded segments bound in the template
    this.results$ =
      this.vodSearchService.searchResponse$.pipe(
        concatMap((response) =>this.accessManagementService.userHasRestrictedAssets(this.authService.getUserEmail(), 'RESTRICTION', 'ASSET')
          .pipe(
            map((hasRestriction) =>  ({ response, hasRestriction })))
        ),
        map(({response, hasRestriction}) => {
          // Do not display anything until we have results. A `null` value is
          // received when the chips have been cleared.
          if (response === null) return undefined;
          this.cols = this.initialCols(this.isEnabledAccessManagement, this.authService.isAdmin || hasRestriction);

          const isInitialFacetsResponse =
            Boolean(response.isInitialFacetsResponse);
          this.performanceTrace?.stopAfter(response.videoSegments.length);
          this.analyticsService.logSearchEvent('VoD search');
          const segments =
            this.vodSearchService.withPadding(response.videoSegments);
          this.tableData = this.sortByDate(segments);

          return { segments, isInitialFacetsResponse };
        })
      );

    this.searchMode$ = this.vodSearchService.searchResponse$.pipe(
      map(response => response?.searchMode));

    // When search was done in SEGMENT mode, force switch UI to Grid view.
    this.searchMode$.pipe(takeUntil(this.destroyed$)).subscribe(mode => {
      if (mode === SearchMode.SEGMENT) {
        this.vodSearchService.displayMode$.next(DisplayMode.GRID);
      }
    });
  }

  ngAfterViewInit() {
    this.resizeObserver.observe(this.searchResultsEl.nativeElement)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(searchResultsRect => {
          this.updateVisibleColumnsInListView(searchResultsRect.width);
        });
  }

  loadingFromAccessRestriction() {
    this.accessManagementActionsService.loading$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe({
      next: (loading) => {
        if(loading) {
          this.progressbar.show();
        }
      },
      error: () => this.progressbar.hide(),
      complete: () => this.progressbar.hide()
    });
  }

  storageInfoList(ev: StorageItemState) {
    ev.storageName = this.storageOptions.find((el) => el.value === ev.state)?.title;

    this.storageList.set(ev.name, ev);
  }

  onTypeFilterChanged(selected: MultiSelectOption) {
    const option = selected as  MultiSelectOption<StorageType>;
    this.storageType = option.value || 'all';
    this.selectedStorageType = option.title;

    // Change a selected option
    this.storageOptions = this.tableUtils.onMultiSelectOptionUpdate(this.storageOptions ,option.value);

    if (this.storageType === 'all') this.tableData = [
      ...this.sortByDate(this.tableData)
    ];

    // Convert sortList to an array and sort it by `type`
    const sortedList = Array.from(this.storageList.entries())
      .filter(([, value]) => value.state === this.storageType)
      .map(([key]) => key);

    const sortedTableRows = this.tableData.sort((a, b) => {
      const aIndex = sortedList.indexOf(a.asset.name);
      const bIndex = sortedList.indexOf(b.asset.name);

      // Items with the targetType come first, followed by others
      if (aIndex === -1 && bIndex === -1) return 0;
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });

    this.tableData = [...sortedTableRows];
  }

  /**
   * Changes the selected option from this.storageOptions using the given value.
   * If the value is left empty if selects the default option.
   *
   * @param selectedOption - Value of the selected option.
   */
  onStorageOptionUpdate(selectedOption : MultiSelectOption['value'] = undefined) {
    this.storageOptions = this.storageOptions.map(opt => {
      opt.selected = opt.value === selectedOption;
      return opt;
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  getFormattedContentType(asset: Original) {
    const contentType =
      asset.assetMetadata.jsonMetadata[MetadataField.CONTENT_TYPE] as unknown;
    if (contentType == null) return '';
    if (Array.isArray(contentType)) return contentType.join(', ');

    return String(contentType);
  }

  isSelected(segment: PaddedSegment) {
    return this.vodSearchService.selectedSegmentNames.has(segment.name);
  }

  getSelectionInfo(segments: PaddedSegment[], selectedNames: Set<string>) {
    return this.tableUtils.getSelectionInfo(
      segments,
      selectedNames,
      seg => this.canBeSelected(seg),
    );
  }

  /**
   * Toggle segment selection if shift is not pressed.
   * Otherwise, toggles a block of items based on target asset and anchor asset.
   */
  toggleSelection(
    segment: PaddedSegment, allSegments: PaddedSegment[],
    shiftPressed = false) {
    const selectedNames = this.vodSearchService.selectedSegmentNames;

    const { itemsToSelect, itemsToUnSelect, anchorName } =
      this.tableUtils.processMultiSelect({
        items: allSegments,
        target: segment,
        selectedNames,
        shiftPressed,
        anchorName: this.selectionAnchorAssetName,
        canBeSelected: seg => this.canBeSelected(seg),
      });

    this.selectionAnchorAssetName = anchorName;

    for (const item of itemsToSelect) {
      selectedNames.add(item.name);
    }
    for (const item of itemsToUnSelect) {
      selectedNames.delete(item.name);
    }

    // Remove selection that can be caused by shift-clicking.
    document.getSelection()?.removeAllRanges();
  }

  /**
   * By default, clicking on the asset row opens asset details page.
   * However, when the shift key is pressed, then this method kicks in and performs row
   * multi-select logic.
   */
  toggleSelectionOnShift(
    segment: PaddedSegment, allSegments: PaddedSegment[], event: MouseEvent) {
    if (!event.shiftKey) return;

    event.stopPropagation();
    this.toggleSelection(segment, allSegments, true);
  }

  trackSegment(index: number, segment: PaddedSegment) {
    return segment.name;
  }


  canBeSelected({ asset }: PaddedSegment) {
    return !asset.isDeleted;
  }

  /**
   * Sorts the provided rows based on the specified active column.
   *
   * @param sortParams - TableSort parameters.
   * @param sortParams.active - The column key name to sort by.
   * @param rows - The rows to be sorted.
   * @returns A new array containing the sorted rows.
   */
  onSort(sortParams: TableSort, rows: PaddedSegment[]): PaddedSegment[] {
    return this.tableUtils.sortByField<PaddedSegment>(rows, sortParams.active, true);
  }

  /**
   * Navigates to the asset details page.
   *
   * @param row The current row to open the video player
   * @param rows The list of rows with PaddedSegment type
   */
  routerTo(row: PaddedSegment & RowEvent, rows: PaddedSegment[]) {
    if (row.event.shiftKey) {
      this.toggleSelectionOnShift(row, rows, row.event);
      return;
    }
    const command = ['/asset', row.asset.name];
    const navigationExtras: UrlCreationOptions = {
      queryParams: {
        'type': 'search', 'initialIndex': this.vodSearchService.getAbsoluteIndex(row.index)
      },
      queryParamsHandling: 'merge'
    };
    this.router.navigate(command, navigationExtras);
  }

  isList(displayMode: DisplayMode) {
    return displayMode === DisplayMode.LIST;
  }

  select(segments: PaddedSegment[]) {
    this.vodSearchService.selectedSegmentNames.clear();
    for (const segment of segments) {
      this.vodSearchService.selectedSegmentNames.add(segment.name);
    }
  }

  async addClipsToBins(segments: PaddedSegment[]) {
    await this.batchOperationService.addClipsToBinsWithConfirmation(
        segments.map(s => s.asset));
  }

  async edit(segments: PaddedSegment[]) {
    this.stateService.currentPersistentTab$.next('staging');
    this.stagingService.edit(segments.map(s => s.asset));
  }

  exportOriginalAssets(segments: PaddedSegment[]) {
    this.batchOperationService.exportAssetsWithDialog(
        segments.map(s => s.asset));
  }

  async extendAssetsTtl(segments: PaddedSegment[]) {
    await this.batchOperationService.extendTtlWithDatePicker(
        segments.map(s => s.asset));
  }


  async deleteAssets(segments: PaddedSegment[]) {
    await this.batchOperationService.deleteAssetsWithConfirmation(
        segments.map(s => s.asset));
  }

  async purgeAssets(segments: PaddedSegment[]) {
    await this.batchOperationService.purgeAssetsWithConfirmation(
        segments.map(s => s.asset));
  }

  onPageChange({previousPageIndex, pageIndex, pageSize}: PageEvent) {
    // Abandon performance trace if the page has changed.
    // It is either completed at this point or will log incorrect results.
    this.performanceTrace?.abort();

    // Page size is changed
    if (pageSize !== this.vodSearchService.pageSize$.value) {
      this.vodSearchService.pageSize$.next(pageSize);
      // Re-trigger search from the first page.
      this.vodSearchService.pageChange$.next(0);
      this.analyticsService.logSearchEvent(
          'Page size changed', {number1: pageSize});
      this.storePageSize(pageSize);
      return;
    }

    this.vodSearchService.pageChange$.next(pageIndex);

    this.storageList.clear();
    this.onStorageOptionUpdate();
    this.storageType = 'all';
    this.storageOptions = this.tableUtils.onMultiSelectOptionUpdate(this.storageOptions, undefined);

    if (pageIndex > (previousPageIndex ?? 0)) {
      this.analyticsService.logSearchEvent(
          'Next search result page', {number1: pageIndex});
    } else {
      this.analyticsService.logSearchEvent(
          'Previous search result page', {number1: pageIndex});
    }
  }

  private restorePageSize() {
    const pageSize = Number(this.preferences.load('search_list_page_size'));
    return PAGE_SIZE_OPTIONS.includes(pageSize) ? pageSize :
                                                  DEFAULT_LIST_PAGE_SIZE;
  }

  private updateVisibleColumnsInListView(availableWidth: number) {
    this.cdr.markForCheck();

    // Large screen.
    if (availableWidth > TableWidthBreakpoint.LARGE) {
      this.displayedColumns = [...ALL_COLUMNS];
      return;
    }

    // Medium screen
    const visible = new Set<typeof ALL_COLUMNS[number]>(
        ['select', 'title', 'duration', 'event-time', 'location', 'action']);

    // Small screen
    if (availableWidth < TableWidthBreakpoint.MEDIUM) {
      visible.delete('duration');
      visible.delete('event-time');
    }

    this.displayedColumns = ALL_COLUMNS.filter(c => visible.has(c));
  }


  addUsersOnSelected() {
    const selectedResourcesDocumentId:Partial<ResourceAccessInfo>[] = [];
    const selectedPublicAsset:Partial<Asset>[] = [];
    Array.from(this.vodSearchService.selectedSegmentNames).forEach((assetId) => {
      const asset = this.tableData?.find(f => f.name === assetId);
      if(!asset) return;
      const {permissions, permissionsDocumentId: documentId} = asset;
      if(permissions && documentId) {
        selectedResourcesDocumentId.push({permissions, documentId});
      } else {
        selectedPublicAsset.push(asset.asset);
      }
    });
    this.accessManagementActionsService.openAddUsersAndMakeRestrictOnSelectedIds(selectedResourcesDocumentId as ResourceAccessInfo[], selectedPublicAsset)
    .subscribe({
      next: () => this.filterRestricted(DisplayMode.LIST)
    });
  }

  makePublicSelected() {
    const selectedResourcesDocumentId:Partial<ResourceAccessInfo>[] = [];
    Array.from(this.vodSearchService.selectedSegmentNames).forEach((assetId) => {
        const { permissions, permissionsDocumentId:documentId } = this.tableData?.find(f => f.name === assetId) || {};
        if(permissions && documentId) {
          selectedResourcesDocumentId.push({permissions, documentId});
        }
    });

    const docsId = selectedResourcesDocumentId.map(f => f.documentId).filter(f => f) as string[];

    this.accessManagementActionsService.openMakePublicSelectedIds(docsId)
    .subscribe({
      next: () => this.filterRestricted(DisplayMode.LIST)
    });
  }


  updateAssetRestriction(segment: Partial<Original>, event?:Partial<ResourceAccessInfo>) {
    segment.permissions = event?.permissions;
    segment.permissionsDocumentId = event?.documentId;
  }

  private storePageSize(pageSize: number) {
    this.preferences.save('search_list_page_size', String(pageSize));
  }

  private sortByDate(list: PaddedSegment[]): PaddedSegment[] {
    return list.sort((a, b) => a.asset.eventTime - b.asset.eventTime);
  }

  filterRestricted(displayMode: DisplayMode) {
      const pageSize =
        displayMode === DisplayMode.GRID ? GRID_PAGE_SIZE : this.restorePageSize();
      const indexPage = this.vodSearchService.pagination.pageIndex;

      this.vodSearchService.pageSize$.next(this.vodSearchService.restrictedList ? LIMIT_OF_REQUEST_TO_RESTRICT_ASSETS : pageSize);
      this.vodSearchService.pageChange$.next(this.vodSearchService.restrictedList ? 0 : indexPage);
      this.cdr.detectChanges();
    }
}
