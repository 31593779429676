import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, ReplaySubject, switchMap, takeUntil } from 'rxjs';

import { AuthService } from '../../auth/auth_service';
import { ResourceTypes } from '../../landing/clip-bin-section/service/resource-types';
import { Resource, ResourceResult, ResourceService } from '../../landing/clip-bin-section/service/resource.service';
import { Bin } from '../../services/bin.service';
import { ClipbinsOwner } from '../../services/bin_api.service';
import { SnackBarService } from '../../services/snackbar_service';

/** Clipbin Bin move dialog */
@Component({
    selector: 'mam-move-bin-dialog',
    templateUrl: './clipbin_bin_move_dialog.ng.html',
    styleUrl: './clipbin_bin_move_dialog.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClipBinBinMoveDialog implements OnInit, OnDestroy {
    private readonly destroyed$ = new ReplaySubject<void>(1);
    resources$ = new BehaviorSubject<Resource[]>([]);
    selectedFolder$ = new BehaviorSubject<Resource | null>(null);
    selectedFolder: Resource | null = null;

    currentResources: Resource[] = [];

    isNestedFolder: boolean = false;
    selectedNestedFolder: Resource | null = null;

    resource?: Resource;

    isLoading: boolean = false;

    keyEnabled = true;

    hasFolderInside$ = new BehaviorSubject<boolean>(false);

    static readonly dialogOptions = { hasBackdrop: true };

    constructor(
        readonly dialogRef: MatDialogRef<ClipBinBinMoveDialog>,
        private readonly snackBar: SnackBarService,
        private readonly resourceService: ResourceService,
        private readonly router: Router,
        private readonly authService: AuthService,
        private readonly cdr: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: Resource | Bin
    ) {}

    ngOnInit(): void {
        this.resource = this.convertToResource(this.data);
        this.getResources();

        this.selectedFolder$.pipe(takeUntil(this.destroyed$)).subscribe((folder) => {
            this.selectedFolder = folder;
            this.selectedNestedFolder = folder && folder.breadcrumb && folder.breadcrumb.length > 0 ? folder : null;
            this.cdr.markForCheck();
        });
      this.dialogRef
        .beforeClosed()
        .pipe(
          takeUntil(this.destroyed$),
          switchMap(()=> {
            const searchMode = this.resourceService.store$.value?.searchMode === 'bins' ? ResourceTypes.CLIPBIN : ResourceTypes.FOLDER;
            let owner;
            if (this.resourceService.currentOwner.value === ClipbinsOwner.USER) {
              owner = searchMode === ResourceTypes.CLIPBIN ? `current_user` : this.authService.getUserEmail();
            } else {
              owner = undefined;
            }
            return this.resourceService.getResource(searchMode, { limit: this.resourceService.BASE_LIMIT, offset: 0, startAfter: undefined }, { owner, searchTerm: '', level: 0 });
          })
        )
        .subscribe();
    }

    getResources() {
        this.isLoading = true;
        this.isNestedFolder = false;
        this.hasFolderInside$.next(false);
        this.resourceService
            .getResource(
                ResourceTypes.FOLDER,
                { limit: 100, offset: 0 },
                { owner: this.authService.getUserEmail(), searchTerm: '' },
                false,
                true
            )
            .pipe(takeUntil(this.destroyed$))
            .subscribe({
                next: (resources) => {
                    const result = resources as ResourceResult;
                    if (this.resource && this.resource.id) {
                        this.resources$.next(
                            result.folders.filter((folder) => folder.id != this.resource?.id && folder.level === 0)
                        );
                    } else {
                        this.resources$.next(
                            result.folders.filter(
                                (folder) => folder.id != this.resource?.iasData.label.name && folder.level === 0
                            )
                        );
                    }
                },
                complete: () => {
                    this.isLoading = false;
                    this.cdr.markForCheck();
                }
            });
    }

    getNestedResources(folder: Resource) {
        this.isLoading = true;
        this.hasFolderInside$.next(false);
        if (folder && folder.id) {
            this.resourceService
                .getResourceChildren(ResourceTypes.FOLDER, folder.id, { limit: 100, offset: 0 }, false, true)
                .pipe(takeUntil(this.destroyed$))
                .subscribe({
                    next: (result) => {
                        this.isNestedFolder = true;
                        this.selectedNestedFolder = result.parent as unknown as Resource;
                        const folderIndex = result.parent.children.findIndex((val) => val.type === 'folder');
                        if (folderIndex >= 0) this.hasFolderInside$.next(true);

                        this.resources$.next(result.parent.children.filter((res) => res.type === 'folder'));
                    },
                    error: () => {
                        this.isNestedFolder = false;
                        this.selectedNestedFolder = null;
                    },
                    complete: () => {
                        this.isLoading = false;
                        this.cdr.markForCheck();
                    }
                });
        }
    }

    selectFolder(folder: Resource) {
        this.hasFolderInside$.next(false);

        if (!this.hasFolderInside$.value && folder.directChildrenCount && folder.directChildrenCount > 0) {
            this.getNestedResources(folder);
        }

        this.selectedFolder$.next(folder);
    }

    selectHome() {
      const homeFolder: Resource = {
        id: '0',
        name: 'Home',
        createdAt: '',
        displayName: 'Home',
        subTreeDepth: 0,
        level: 0
      };
      this.selectedFolder$.next(homeFolder);
    }

    moveButtonDisableCondition() {
        return (
            !this.selectedFolder$.value ||
            !this.keyEnabled ||
            this.hasFolderInside$.value
        );
    }

    moveFolderToSelected() {
        this.keyEnabled = false;

        const selectedFolder = this.selectedFolder$.value;

        if (this.resource && this.resource.iasId) this.resource.id = this.resource.iasId;
        else if (this.resource && this.resource.iasData.label.name)
            this.resource.id = this.resource.iasData.label.name;

        if (this.resource && this.resource.id && selectedFolder && selectedFolder.id) {
            this.resourceService.moveResource(ResourceTypes.CLIPBIN, this.resource.id, selectedFolder.id).subscribe({
                next: (result) => {
                    this.snackBar.message('Clipbin has been moved successfully.');
                    if(this.resource) {
                      const queryParams = { viewMode: this.resource.displayMode };
                      if(selectedFolder.id === '0'){
                        this.router.navigate(['/'], {
                          queryParams,
                          queryParamsHandling: 'merge',
                          onSameUrlNavigation: 'reload'
                        });
                      } else {
                        this.router.navigate(['/folders', result?.parent?.id], {
                          queryParams,
                          queryParamsHandling: 'merge',
                        });
                      }
                    }
                    this.dialogRef.close();
                },
                error: (error) => {
                    this.snackBar.error('Folder could not be moved.', undefined, error);
                    this.keyEnabled = true;
                }
            });
        }
    }

    backClicked() {
        this.selectedFolder$.next(null);
        this.getResources();
    }

    convertToResource(data: Resource | Bin): Resource {
        const bin = data as Bin;

        return bin && bin.title
            ? {
                  level: 0,
                  subTreeDepth: bin.assetCount != '' ? Number.parseInt(bin.assetCount) : 0,
                  createdAt: '',
                  displayName: bin.title,
                  id: bin.name,
                  name: bin.title
              }
            : (data as Resource);
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
