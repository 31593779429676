import { Clip, Original } from "../../services/asset_service";

export type DashBoardReport = {
  title?:string
}

export type topAsset = {
  asset_name?: string,
  subclip_name?: string,
  resource?: string,
  resource_name?: string,

  event_count?: number,
  resource_count?: number,
  download_count?: number,
  export_count?: number,

  year?: string;
  month?: string;
  week?: string;
  day?: string;

  original?: Original,
  clip?: Clip,
  url?: string,
  icon?: string,
}

export enum AssetKeys {
  topClippedAssets = 'topClippedAssets',
  topDownloadAssets = 'topDownloadAssets',
  // TODO rename topDownloadedSubclips -> topDownloadSubclips
  topDownloadedSubclips = 'topDownloadedSubclips',
  topSharedAssets = 'topSharedAssets',
  topExportedFullAssets = 'topExportedFullAssets',
  topExportedSubclips = 'topExportedSubclips',
  topImportedPremierTitle = 'topImportedPremierTitle',
  topViewedAssets = 'topViewedAssets',
}

export interface BarChartData {
  y: string,
  x: number,
}
