import {A11yModule} from '@angular/cdk/a11y';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBar} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MAT_LUXON_DATE_FORMATS, MatLuxonDateModule} from '@angular/material-luxon-adapter';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';

import {AccessConfirmDialog} from "../access_management/component/access-confirm-dialog/access-confirm-dialog.component";
import {AccessDetailsAssetDialog} from '../access_management/component/access-details-asset-dialog/access-details-asset-dialog.component';
import {AccessRestrictionAssetDialog} from "../access_management/component/access-restriction-asset-dialog/access-restriction-asset-dialog.component";
import {AccessRestrictionIconComponent} from "../access_management/component/access-restriction-icon/access-restriction-icon.component";
import {AccessRestrictionOptionsComponent} from "../access_management/component/access-restriction-options/access-restriction-options.component";
import {HasRestrictionsPipe} from '../access_management/pipe/has-restrictions.pipe';
import {AuthModule} from '../auth/auth_module';
import {FeatureFlagModule} from '../feature_flag/feature_flag_module';
import {FirebaseModule} from '../firebase/firebase_module';
import {PipesModule} from '../pipes/pipes_module';
import {PluginModule} from '../plugin/plugin_module';
import {ServicesModule} from '../services/services.module';

import {AddClipDialog} from './add_clip_dialog';
import {AssetExtraActions} from './asset_extra_actions';
import {AssetInfoIconButton} from './asset_info_icon_button';
import {AssetTypeIcon} from './asset_type_icon';
import {ChangeRegionComponent} from './change_region';
import {ClipBinOwnerSelector} from './clip_bin_owner_selector';
import {ClipbinSearchPanel} from './clip_bin_search_panel';
import {ClipBinBinMoveDialog} from './clipbin_bin_move_dialog/clipbin_bin_move_dialog';
import {ClipbinFolderCreationDialog} from './clipbin_folder_creation_dialog/clipbin_folder_creation_dialog';
import {ClipBinFolderDeleteDialog} from './clipbin_folder_delete_dialog/clipbin_folder_delete_dialog';
import {ClipBinFolderMoveDialog} from './clipbin_folder_move_dialog/clipbin_folder_move_dialog';
import {ClipBinFolderUpdateDialog} from './clipbin_folder_update_dialog/clipbin_folder_update_dialog';
import {CloudIngestCreationDialog} from './cloud_ingest_creation_dialog';
import {SharedAssetThumbnail} from './components/asset-thumbnail/asset-thumbnail.component';
import {AssetLocationStatus} from './components/asset_location_status/asset_location_status';
import {NewActionButton} from './components/new-action-button/new-action-button';
import {UrlCopy} from './components/url_copy/url_copy.component';
import {CreateBinDialog} from './create_bin_dialog';
import {DeleteBinDialog} from './delete_bin_dialog';
import {DeleteClipDialog} from './delete_clip_dialog';
import {DeleteMultipleBinDialog} from './delete_multiple_bin_dialog/delete_multiple_bin_dialog';
import {ExportAssetDialog} from './export_asset_dialog';
import {ExportCompReelDialog} from './export_comp_reel_dialog';
import {ExtendTtlDialog} from './extend_ttl_dialog';
import {GetLinkForBinDialog} from './get_link_for_bin_dialog';
import {GetLinkForVideoDialog} from './get_link_for_video_dialog';
import {InfiniteScroll} from './infinite_scroll';
import {MoveBinDialog} from './move_bin_dialog/move_bin_dialog';
import {NavigateToClipBinDialog} from './navigate_to_clip_bin_dialog';
import {OnpremOperation} from './onprem_operation';
import {OnPremPathDialog} from './onprem_path_dialog';
import {ProcessingStatus} from './processing_status';
import {RenameBinDialog} from './rename_bin_dialog';
import {RenameClipDialog} from './rename_clip_dialog';
import {RestoreScrollDirective, ScrollerFactory} from './restore-scroll.directive';
import {SelectBinsDialog} from './select_bins_dialog';
import {SelectSchema} from './select_schema';
import {SharedLinksManagerDialog} from './shared_links_manager_dialog';
import {SyncMetadataDialog} from './sync_metadata_dialog';
import {ToolbarMenu} from './toolbar_menu';
import {TrimClipDialog} from './trim_clip_dialog';

const DATE_INPUT_DELIMITTERS = ['-', '/'];
const DATE_INPUT_PARSE_FORMATS = DATE_INPUT_DELIMITTERS.flatMap(
  delimitter => [
    `yyyy${delimitter}L${delimitter}d`,
    `L${delimitter}d${delimitter}yyyy`,
  ]);

@NgModule({
  imports: [
    A11yModule,
    AuthModule,
    BrowserAnimationsModule,
    ClipboardModule,
    CommonModule,
    FeatureFlagModule,
    FirebaseModule,
    FormsModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatTableModule,
    MatTooltipModule,
    MatSelectModule,
    PipesModule,
    PluginModule,
    ReactiveFormsModule,
    RouterModule,
    ServicesModule,
    MatDatepickerModule,
    MatLuxonDateModule,
    MatProgressBar,
    MatAutocompleteModule
  ],
  declarations: [
    AddClipDialog,
    AssetInfoIconButton,
    AssetLocationStatus,
    AssetExtraActions,
    AssetTypeIcon,
    ClipbinSearchPanel,
    ClipBinOwnerSelector,
    ClipbinFolderCreationDialog,
    CreateBinDialog,
    CloudIngestCreationDialog,
    DeleteBinDialog,
    DeleteMultipleBinDialog,
    DeleteClipDialog,
    ExtendTtlDialog,
    GetLinkForBinDialog,
    GetLinkForVideoDialog,
    InfiniteScroll,
    ExportCompReelDialog,
    ExportAssetDialog,
    NavigateToClipBinDialog,
    ProcessingStatus,
    RenameBinDialog,
    RenameClipDialog,
    ChangeRegionComponent,
    SharedLinksManagerDialog,
    OnpremOperation,
    ToolbarMenu,
    TrimClipDialog,
    SelectBinsDialog,
    SelectSchema,
    SyncMetadataDialog,
    OnPremPathDialog,
    UrlCopy,
    RestoreScrollDirective,
    SharedAssetThumbnail,
    ClipBinFolderUpdateDialog,
    ClipBinFolderDeleteDialog,
    ClipBinFolderMoveDialog,
    MoveBinDialog,
    ClipBinBinMoveDialog,
    AccessRestrictionOptionsComponent,
    AccessRestrictionIconComponent,
    AccessConfirmDialog,
    AccessRestrictionAssetDialog,
    AccessDetailsAssetDialog,
    HasRestrictionsPipe,
    NewActionButton
    ],
  exports: [
    AssetInfoIconButton,
    AssetLocationStatus,
    AssetTypeIcon,
    ClipbinSearchPanel,
    ClipBinOwnerSelector,
    CloudIngestCreationDialog,
    CreateBinDialog,
    DeleteBinDialog,
    DeleteMultipleBinDialog,
    DeleteClipDialog,
    RenameBinDialog,
    NavigateToClipBinDialog,
    ProcessingStatus,
    AssetExtraActions,
    RenameClipDialog,
    InfiniteScroll,
    ToolbarMenu,
    TrimClipDialog,
    SelectBinsDialog,
    SelectSchema,
    SyncMetadataDialog,
    ChangeRegionComponent,
    UrlCopy,
    RestoreScrollDirective,
    SharedAssetThumbnail,
    ClipBinFolderMoveDialog,
    MoveBinDialog,
    ClipBinBinMoveDialog,
    AccessRestrictionOptionsComponent,
    AccessRestrictionIconComponent,
    AccessConfirmDialog,
    AccessRestrictionAssetDialog,
    AccessDetailsAssetDialog,
    HasRestrictionsPipe,
    NewActionButton,
  ],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: DATE_INPUT_PARSE_FORMATS,
        },
        display: {
          ...MAT_LUXON_DATE_FORMATS.display,
          dateInput: 'L/d/yyyy',
        },
      },
    },
    {
      provide: ScrollerFactory,
      useClass: ScrollerFactory
    }
  ],
})
export class SharedModule {
}
