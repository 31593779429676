import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatPaginatorIntl, PageEvent} from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import {of, ReplaySubject} from 'rxjs';
import {debounceTime, finalize, map, switchMap, takeUntil, tap} from 'rxjs/operators';

import {assertTruthy} from '../asserts/asserts';
import { environment } from '../environments/environment';
import {isErrorResponse} from '../error_service/error_response';
import {FeatureFlagService} from '../feature_flag/feature_flag_service';
import {StagingService, StagingView} from '../right_panel/staging_service';
import {AssetService, ListResponse, Original} from '../services/asset_service';
import { FileState } from '../services/media_cache_service';
import {PaginatorIntl, UNKNOWN_LENGTH} from '../services/paginator-intl';
import {PreferencesService} from '../services/preferences_service';
import {ProgressbarService} from '../services/progressbar_service';
import {SnackBarService} from '../services/snackbar_service';
import {StateService} from '../services/state_service';
import {TableUtils} from '../services/table_utils';
import { StorageItemState, StorageType, TableCol } from '../ui/ui_table.type';

import { MultiSelectOption, MultiSelectOptions } from './multiselect_table_header';
import {StagingTable} from './staging_table_base';

export const ALL_COLUMNS = [
  'checkbox',
  'title',
  'source',
  'duration',
  'schema',
  'approved',
  'error',
  'storage'
] as const;
type Column = typeof ALL_COLUMNS[number];

// LEGACY Table with List views OFF
export const LEGACY_COLUMNS = [
  'select',
  'title',
  'source',
  'date',
  'schema',
  'approved',
  'error',
  'location'
] as const;

type LegacyColumn = typeof LEGACY_COLUMNS[number];

interface VoDErrorSort extends Sort {
  active: Column;
}

const DEFAULT_SORT: VoDErrorSort = {
  active: 'title',
  direction: 'desc'
};

/** Page size options for list view. */
const PAGE_SIZE_OPTIONS = [30, 50, 100, 200];

/** Default page size */
const DEFAULT_PAGE_SIZE = 100;

/** When page size selector is disabled we mimic older MAM UI */
const LEGACY_PAGE_SIZE = 30;

export enum BreakPoint {
}

/**
 * Staging table that display all VoD assets in error state
 */
@Component({
  selector: 'mam-vod-staging-error-table',
  templateUrl: './vod_staging_error_table.ng.html',
  styleUrls: ['./vod_staging_error_table.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{provide: MatPaginatorIntl, useClass: PaginatorIntl}]
})
export class VodStagingErrorTable extends StagingTable implements OnInit {

  pageSize = this.restorePageSize();

  /** Form control for search input. */
  search = new FormControl<string>('');

  /** Current query entered by the user that is be used in asset request. */
  userQuery = '';

  readonly dataRequest$ =
      new ReplaySubject<{pageIndex: number, pageSize: number}>(1);

  currentPageIndex = 0;

  /** Total asset count across all pages. */
  totalCount = UNKNOWN_LENGTH;

  /** Page size options for vod table. */
  readonly PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  readonly view: StagingView = 'vod';

  /** Flag to show assets' source column. */
  readonly showAssetsSource = this.featureService.featureOn('show-user-information');

  /** Flag to use List views feature. */
  readonly showListView = this.featureService.featureOn('use-table-list-views');

  cols: TableCol[] = [
    {
      key: 'checkbox',
      name: '',
      headerTpl: 'checkBoxTpl',
      cellTpl: 'checkBoxTpl',
      order: 0,
      resizer:false,
      dragger: false,
      headerStyle: {
        width: 'var(--table-checkbox-width)',
        minWidth: 'var(--table-checkbox-minwidth)',
        maxWidth: 'var(--table-checkbox-width)',
      },
      cellStyle: {
        textOverflow: 'clip',
      },
      orderMenu: 0
    },
    {
      key: 'title',
      name: 'Title / File name',
      optioner: true,
      resizer: true,
      sorter: true,
      dragger: false,
      disabled: true,
      // headerTpl: 'nameTpl',
      cellTpl: 'nameTpl',
      headerStyle: {
        width: '120px',
        minWidth: '100px',
      },
      classCel: ['main-column'],
      class: ['icon-with-text'],
      order: 1,
      orderMenu: 1
    },
    {
      key: 'source',
      name: 'Source',
      optioner: true,
      dragger: true,
      resizer: true,
      sorter: true,
      order: 2,
      // headerTpl: "sourceTpl",
      headerStyle: {
        width: '120px',
        minWidth: '100px',
        maxWidth: '200px',
      },
      orderMenu: 2
    },
    {
      key: 'createTime',
      name: 'Date',
      optioner: true,
      dragger: true,
      resizer: true,
      sorter: true,
      order: 3,
      pipe: 'tzdate',
      pipeArg: 'MMM d, y, h:mm',
      headerStyle: {
        width: '130px',
        minWidth: '130px',
        maxWidth: '200px',
      },
      orderMenu: 3
    },
    {
      key: 'duration',
      name: 'Duration',
      dragger: true,
      resizer: true,
      sorter: true,
      cellTpl: 'durationTpl',
      headerStyle: {
        width: '90px',
        minWidth: '80px',
        maxWidth: '160px',
      },
      order: 4,
      orderMenu: 4
    },
    {
      key: 'schema',
      name: 'Schema Name',
      optioner: true,
      dragger: true,
      resizer: true,
      sorter: true,
      cellTpl: 'schemaTpl',
      headerStyle: {
        width: '110px',
        minWidth: '110px',
        maxWidth: '200px',
      },
      order: 5,
      orderMenu: 5
    },
    {
      key: 'approved',
      name: 'Approved',
      optioner: true,
      dragger: true,
      resizer: true,
      sorter: true,
      cellTpl: 'approvedTpl',
      headerStyle: {
        width: '90px',
        minWidth: '80px',
        maxWidth: '150px',
      },
      order: 6,
      orderMenu: 6
    },
    {
      key: 'error',
      name: 'Error',
      optioner: true,
      dragger: true,
      resizer: true,
      sorter: true,
      cellTpl: 'errorTpl',
      headerStyle: {
        minWidth: '120px',
        width: '210px',
        maxWidth: '500px',
      },
      order: 7,
      orderMenu: 7
    },
    {
      key: 'storage',
      name: 'Storage',
      dragger: true,
      stickyEnd: true,
      resizer: true,
      headerTpl: 'storageTpl',
      cellTpl: 'storageTpl',
      headerStyle: {
        width: '105px',
        minWidth: '90px',
        textAlign: 'right'
      },
      order: 8,
      orderMenu: 8
    }
  ];

  displayedColumns: LegacyColumn[] = [];

  activeSort: VoDErrorSort = {...DEFAULT_SORT};
  tableId:string;

   /** Options for status filter. */
  storageOptions: MultiSelectOptions<StorageType> = [
    {title: 'All', selected: true},
    {title: 'Cloud', value: FileState.FILE_CLOUD_ONLY},
    {title: 'OnPrem', value: FileState.FILE_ONPREM_ONLY},
    {title: 'Cloud/OnPrem', value: FileState.FILE_CLOUD_AND_ONPREM},
  ];
  storageList: Map<string,StorageItemState> = new Map();
  storageType: StorageType = FileState.FILE_CLOUD_ONLY;
  selectedStorageType: string = 'Storage';

  constructor(
      readonly featureService: FeatureFlagService,
      private readonly progressbar: ProgressbarService,
      private readonly preferences: PreferencesService,
      private readonly snackbar: SnackBarService,
      private readonly elementRef: ElementRef,
      stagingService: StagingService,
      assetService: AssetService,
      tableUtils: TableUtils,
      cdr: ChangeDetectorRef,
      stateService: StateService,
      router: Router
  ) {
    super(stagingService, tableUtils, assetService, cdr, stateService, router);
    this.tableId = environment.tableInfoId['vodStagingErrorTable'];

    this.search.valueChanges.pipe(takeUntil(this.destroyed$), debounceTime(300))
        .subscribe(query => {
          this.scrollTopNeeded.emit();
          this.userQuery = query ?? '';
          this.refreshTable();
        });

    if (!this.showListView) this.startResponsiveLayout();
  }

  override ngOnInit() {
    super.ngOnInit();

    this.startFetchingAssetsOnPageChange();

    // Trigger asset fetching.
    this.dataRequest$.next({pageIndex: 0, pageSize: this.pageSize});
  }

  storageInfoList(ev: StorageItemState) {
    ev.storageName = this.storageOptions.find((el) => el.value === ev.state)?.title;
    this.storageList.set(ev.name, ev);
  }

  onStorageFilterChanged(option: string) {
    this.storageOptions = this.storageOptions.map(opt => {
      opt.selected = opt.value === option;
      return opt;
    });
  }

  onTypeFilterChanged(selected: MultiSelectOption) {
    const option = selected as MultiSelectOption<StorageType>;
    this.storageType = option.value || 'all';
    this.selectedStorageType = option.title;

    // Change selected option
    this.storageOptions = this.tableUtils.onMultiSelectOptionUpdate(this.storageOptions ,option.value);

    if (this.storageType === 'all') this.assets = [
      ...this.sortByDate(this.assets as Original[])
    ];

    // Convert sortList to an array and sort it by `type`
    const sortedList = Array.from(this.storageList.entries())
      .filter(([, value]) => value.state === this.storageType)
      .map(([key]) => key);

    const sortedTableRows = this.assets?.sort((a, b) => {
      const aIndex = sortedList.indexOf(a.name);
      const bIndex = sortedList.indexOf(b.name);

      // Items with the targetType come first, followed by others
      if (aIndex === -1 && bIndex === -1) return 0;
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });

    this.assets = [...<[]>sortedTableRows];
  }


  private sortByDate(list: Original[]): Original[] {
    return list.sort((a, b) => a.createTime - b.createTime);
  }

  changePage({pageIndex, pageSize}: PageEvent) {
    this.scrollTopNeeded.emit();
    this.dataRequest$.next({pageIndex, pageSize});
    this.storageOptions = this.tableUtils.onMultiSelectOptionUpdate(this.storageOptions, undefined);
  }

  formatError(asset: Original) {
    return asset.errorReason ?? '';
  }

  /** Refreshes the table from the first page. */
  refreshTable() {
    this.dataRequest$.next({pageIndex: 0, pageSize: this.pageSize});
  }

  protected override updateCache(changedItems: Map<string, Original>) {
    for (const cachedPage of Array.from(this.assetListResponseCache.values())) {
      for (let i = 0; i < cachedPage.assets.length; i++) {
        const changedItem = changedItems.get(cachedPage.assets[i].name);
        if (changedItem) {
          cachedPage.assets[i] = changedItem;
        }
      }
    }
  }

  onSortChanged(sort: Sort, rows: Original[]) {
    this.activeSort = sort as VoDErrorSort;
    this.assets =  this.tableUtils.sortByField<Original>(rows, this.activeSort.active, this.activeSort.direction === 'desc');
  }

  /**
   * Contains cached asset list responses. Array index correlates with
   * pageIndex.
   */
  private assetListResponseCache: Array<ListResponse<Original>> = [];

  // TODO: Refactor duplicated logic between this and VoD Staging
  /**
   * Listens to the emissions from `dataRequest$` and if `refresh` param is off
   * fetches data for the requested page from the cache or the data service.
   * When `refresh` is true, drops the whole cache and fetches data from first
   * page and up to the requested one.
   *
   * Paginator has to be manually disabled until the request is done because we
   * can't get `N+1` page until we have result for `N` because we would lack
   * the `nextPageToken`.
   */
  private startFetchingAssetsOnPageChange() {
    this.dataRequest$
        .pipe(
            takeUntil(this.destroyed$), tap(() => {
              this.cdr.markForCheck();
              this.loading = true;
              this.progressbar.show();
            }),
            switchMap(({pageIndex, pageSize}) => {
              // When page size is changed store new value and drop the user to
              // the first page. This will clear the cache as well (line 183)
              if (pageSize !== this.pageSize) {
                pageIndex = 0;
                this.pageSize = pageSize;
                this.storePageSize(pageSize);
              }

              // Always re-fetch the first page and drop the cache.
              if (pageIndex === 0) {
                this.assetListResponseCache = [];
                this.totalCount = UNKNOWN_LENGTH;
              } else {
                const cached = this.assetListResponseCache[pageIndex];
                if (cached) return of({response: cached, pageIndex});
              }

              const mustUsePageToken = pageIndex !== 0;
              const pageToken = mustUsePageToken ?
                  this.assetListResponseCache[pageIndex - 1]?.nextPageToken :
                  undefined;
              if (mustUsePageToken) {
                assertTruthy(
                    pageToken,
                    `Vod Staging Errors: No page token for page index ${
                        pageIndex}`);
              }

              return this.stagingService
                  .getVodErrorAssets({
                    pageSize: this.pageSize,
                    query: this.userQuery,
                    pageToken,
                    sortOptions: this.tableUtils.buildVoDSortOptions<Omit<Column,'title'>>(this.activeSort),
                  })
                  .pipe(map(response => ({response, pageIndex})));
            }),
            finalize(() => {
              this.progressbar.hide();
            }))
        .subscribe(({response, pageIndex}) => {
          this.cdr.markForCheck();
          this.loading = false;
          this.progressbar.hide();
          this.currentPageIndex = pageIndex;

          if (isErrorResponse(response)) {
            this.snackbar.message('Failed to load staging assets');
            this.assets = [];
            // Prevent further navigation after an error.
            this.totalCount = this.currentPageIndex * this.pageSize;
            this.refreshActiveAndSelectedItems();
            return;
          }

          this.assetListResponseCache[pageIndex] = response;
          this.assets = response.assets;
          this.refreshActiveAndSelectedItems();

          if (!response.nextPageToken) {
            // This is the last page, set total count which will disable next
            // page in the paginator.
            this.totalCount =
                this.currentPageIndex * this.pageSize + this.assets.length;
          }
        });
  }

  private restorePageSize() {
    const pageSize =
        Number(this.preferences.load('vod_staging_error_page_size'));
    const defaultPageSize =
        this.featureService.featureOn('use-vod-staging-page-size-selector') ?
        DEFAULT_PAGE_SIZE :
        LEGACY_PAGE_SIZE;
    return PAGE_SIZE_OPTIONS.includes(pageSize) ? pageSize : defaultPageSize;
  }

  /** Works on legacy tables with the List-view feature flag off  */
  private startResponsiveLayout() {
    this.tableUtils
      .observeWidth(
          this.elementRef.nativeElement,
            [
              {name: 'S', minWidth: 0, add: ['select', 'title', 'source', 'date', 'error']},
              {
                name: 'XL',
                minWidth: 1200,
                add: ['schema', 'approved', 'location']
              },
            ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(({columns}) => {
        assertTruthy(columns, 'At least one breakpoint should be activated.');
        this.cdr.markForCheck();
        this.displayedColumns = LEGACY_COLUMNS.filter(c => columns.includes(c));
      });
  }

  private storePageSize(pageSize: number) {
    // Don't store page size when page selector is not available.
    if (this.featureService.featureOff('use-vod-staging-page-size-selector')) {
      return;
    }
    this.preferences.save('vod_staging_error_page_size', String(pageSize));
  }

  onSortByField(rows: Original[]) {
    this.assets = rows;
    this.cdr.detectChanges();
  }
}
