/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { combineLatest, mergeMap, of, ReplaySubject, Subject, switchMap, takeUntil, tap } from 'rxjs';

import { environment } from '../environments/environment';
import { isErrorResponse } from '../error_service/error_response';
import { FeatureFlagService } from '../feature_flag/feature_flag_service';
import { ResourceTypeName, ResourceTypes } from '../landing/clip-bin-section/service/resource-types';
import { LastResourcePerPage, PaginationInfo, Resource, ResourceService, SearchOptions } from '../landing/clip-bin-section/service/resource.service';
import { Original } from '../services/asset_service';
import { DialogService } from '../services/dialog_service';
import { PaginatorIntl } from '../services/paginator-intl';
import { ProgressbarService } from '../services/progressbar_service';
import { SnackBarService } from '../services/snackbar_service';
import { TableUtils } from '../services/table_utils';
import { TaskUsersService } from '../services/task_user_service';
import { TableCol } from '../ui/ui_table.type';

import { MultiSelectOption, MultiSelectOptions } from './multiselect_table_header';


const ALL_COLUMNS = [
  'title',
  'type',
  'deletedBy',
  'lastModified',
  'deletedAt',
  'undelete',
] as const;

interface AssetDeletionRowSort {
  active: 'title'|'type'|'deletedBy'|'lastModified'|'deletedAt'|'undelete';
  direction: 'asc'|'desc';
}

/**
 * Asset deletion table component.
 */
@Component({
  selector: 'mam-item-deletion-table',
  templateUrl: './asset_deletion_table.ng.html',
  styleUrls: ['./asset_deletion_table.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MatPaginatorIntl, useClass: PaginatorIntl }],
})
export class AssetDeletionTable implements OnInit, OnDestroy {
  @Output() readonly scrollTopNeeded = new EventEmitter();

  cols: TableCol[] = [
    {
      key: 'title',
      name: 'Title',
      sorter: true,
      optioner: true,
      dragger: false,
      resizer: true,
      disabled: true,
      cellTpl: 'titleTpl',
      headerStyle: {
        width: '180px',
        minWidth: '200px',
      },
      order: 0,
      orderMenu: 0
    },
    {
      key: 'type',
      name: 'Type',
      sorter: false,
      optioner: false,
      dragger: true,
      resizer: true,
      headerTpl: 'typeTpl',
      cellTpl: 'typeTpl',
      headerStyle: {
        width: '100px',
        minWidth: '80px',
      },
      order: 1,
      orderMenu: 1
    },
    {
      key: 'deletedBy',
      name: 'Deleted by',
      sorter: true,
      optioner: true,
      dragger: true,
      resizer: true,
      cellTpl: 'userTpl',
      headerStyle: {
        width: '140px',
        minWidth: '100px',
      },
      order: 2,
      orderMenu: 2
    },
    {
      key: 'lastModified',
      name: 'Last Modified',
      sorter: true,
      optioner: true,
      dragger: true,
      resizer: true,
      cellTpl: 'updateTimeTpl',
      headerStyle: {
        width: '160px',
        minWidth: '90px',
      },
      order: 3,
      orderMenu: 3
    },
    {
      key: 'deletedAt',
      name: 'Deleted date',
      sorter: true,
      optioner: true,
      dragger: true,
      resizer: true,
      cellTpl: 'deletedAtTpl',
      headerStyle: {
        width: '160px',
        minWidth: '90px',
      },
      order: 4,
      orderMenu: 4
    },
    {
      key: 'undelete',
      name: '',
      sorter: false,
      optioner: true,
      dragger: true,
      resizer: false,
      stickyEnd: true,
      cellTpl: 'undeleteTpl',
      headerStyle: {
        width: '90px',
        minWidth: '90px',
      },
      order: 5,
      orderMenu: 5
    }
  ];

  lastResourcePerPageArray: LastResourcePerPage[] = [];

  assets: Original[] | null = null;

  deletedAssets: Resource[] = [];

  /** Record the undeleted asset to dim the matching row. */
  undeletedAssetSet = new Set<string>();

  /** Fixed page size of the asset deletion table. */
  pageSize = 30;
  pageIndex = 0;
  totalItems = 0;

  /** Form control for search input. */
  search = new FormControl<string>('');

  pagination: PaginationInfo = {
    offset: 0,
    startAfter: undefined,
    limit: 0
  };

  /** List of columns to render in the table. */
  displayedColumns = ALL_COLUMNS;

  activeSort: AssetDeletionRowSort = { active: 'title', direction: 'desc' };

  pagination$ = new Subject<PaginationInfo>();

  resourceSearchOptions: { type?: ResourceTypeName, searchTerm: string, owner: string } = {
    searchTerm: '',
    owner: '',
  };

  resourceSearchOptions$ = new Subject<SearchOptions>();

  resourceTypes: string[] = Object.values(ResourceTypes).filter(type => (type.name !== ResourceTypes.CLIP.name) && (type.name !== ResourceTypes.ASSET.name)).map(type => type.name);

  deletedModes = ['assets', 'organizers'];

  currentDeletedMode = 'assets';

  tableId:string;

  /** Options for type filter. */
  typeFilterOptions: MultiSelectOptions<string> = [
    {title: 'Any type', selected: true},
    {title: 'Folder', value: 'folder'},
    {title: 'Clip bin', value: 'clip-bin'}
  ];

  /** Flag to show assets' source column. */
  readonly showAssetsSource = this.featureService.featureOn('show-user-information');

  /** Flag to use List views feature. */
  readonly showListView = this.featureService.featureOn('use-table-list-views');

  private readonly destroyed$ = new ReplaySubject<void>(1);

  constructor(
    readonly progressbar: ProgressbarService,
    private readonly cdr: ChangeDetectorRef,
    private readonly dialogService: DialogService,
    private readonly snackbar: SnackBarService,
    readonly featureService: FeatureFlagService,
    protected readonly taskUserService: TaskUsersService,
    private  readonly  tableUtils: TableUtils,
    protected readonly resourceService: ResourceService,

  ) {
    this.tableId = environment.tableInfoId['deletedOrganizersTable'];
  }

  ngOnInit() {

    this.getDeletedResources();
    this.getSearchChanges();
    this.resourceSearchOptions$.next(this.resourceSearchOptions);
    this.pagination$.next(this.pagination);
  }

  onStatusFilterChanged(option: MultiSelectOption) {
    if (option.title === 'Any type') {
      this.resourceSearchOptions.type = undefined;
    } else {
      this.resourceSearchOptions.type = option.value === this.resourceSearchOptions.type ? undefined : option.value as ResourceTypeName;
    }

    this.typeFilterOptions = this.typeFilterOptions.map(opt => {
      opt.selected = opt.title === option.title;
      return opt;
    });

    this.resetPagination();
  }

  getSearchChanges() {
    this.search.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe((searchTerm:string|null) => {
        this.resourceSearchOptions.searchTerm = searchTerm || '';
        this.resourceSearchOptions$.next(this.resourceSearchOptions);
        this.cdr.detectChanges();
      });
  }

  getDeletedResources() {
    this.progressbar.show();
    combineLatest([
      this.pagination$,
      this.resourceSearchOptions$,
    ])
      .pipe(
        mergeMap(([pagination, resourceSearchOptions]) => {
          this.pagination.startAfter = pagination.startAfter;
          pagination.limit = this.pageSize;
          return this.resourceService.getDeletedResources(pagination, resourceSearchOptions);
        }),
        takeUntil(this.destroyed$),
        tap(() => {
          this.deletedAssets = [];
          this.cdr.detectChanges();
        }),
      )
      .subscribe((response) => {
        this.totalItems = response.paginationData.totalItems!;
        this.deletedAssets = response.nodes;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.deletedAssets.sort( (a,b) => new Date(b.deletedAt!).getTime() - new Date(a.deletedAt!).getTime());
        this.progressbar.hide();
        this.cdr.detectChanges();
      });
  }

  resetPagination() {
    this.pagination = {
      offset: 0,
      startAfter: undefined,
      limit: this.pageSize
    };
    this.lastResourcePerPageArray = [];
    this.pagination$.next(this.pagination);
    this.pageIndex = 0;
  }

  changePage(event: PageEvent) {
    this.lastResourcePerPageArray[this.pageIndex] = {
      pageIndex: this.pageIndex,
      resourceId: this.deletedAssets[this.deletedAssets.length - 1]?.id as string,
    };
    const lastResourceId = this.lastResourcePerPageArray[this.pageIndex]?.resourceId;
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    this.pagination$.next({
      ...event,
      limit: this.pageSize,
      offset: event.pageIndex * event.pageSize,
      startAfter: this.pageIndex > 0 ? lastResourceId : undefined,
    });

    this.scrollTopNeeded.emit();
  }

  openUndeleteAssetDialog(asset: Resource) {
    const type = asset.type === ResourceTypes.CLIPBIN.apiResourceType ? 'clip bin' :
      (asset.type === ResourceTypes.FOLDER.apiResourceType ? 'folder' : 'asset');


    const confirmed$ = this.dialogService.showConfirmation({
      title: `Undelete ${type}`,
      question:
        `Are you sure you want to restore this ${asset.type === 'clipbin' ? 'clip bin' : asset.type}?`,
      noQuestionSpacing: true,
      primaryButtonText: 'Undelete',
    });
    confirmed$
      .pipe(
        switchMap((e) => {
          if (!e) {
            return of(null);
          }
          if (!asset || !asset.id) {
            return of(null);
          }
          return asset.iasData ?
            this.resourceService.undeleteResource(ResourceTypes.CLIPBIN, asset.iasData.label.name) :
            this.resourceService.undeleteResource(ResourceTypes.FOLDER, asset.id);
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe((response) => {
        if (!response) return;

        if (isErrorResponse(response)) {
          this.snackbar.error({
            message: 'Failed to undelete asset.',
            details: response.message,
          });
        } else {
          this.snackbar.message('The asset has been undeleted.');
          this.undeletedAssetSet.add(asset.name);
          this.cdr.markForCheck();
        }
      });
  }

  getIconOpacity(type: string, index: number | string): number {
    const idx = Number(index);
    if (type === this.resourceSearchOptions.type) {
      return 1;
    }

    if (!this.resourceSearchOptions.type && idx === 0) {
      return 1;
    }

    return 0;
  }

  handleFilterChange(type: string) {
    if (type === 'Any type') {
      this.resourceSearchOptions.type = undefined;
    } else {
      this.resourceSearchOptions.type = type === this.resourceSearchOptions.type ? undefined : type as ResourceTypeName;
    }
    if (this.pagination)  this.pagination.startAfter = undefined;
    this.resourceSearchOptions$.next(this.resourceSearchOptions);
  }


  handleDeletedModeChange(mode: string) {
    this.currentDeletedMode = mode;
    this.cdr.detectChanges();
  }

  trackByAsset(index: number, asset: Original | Resource) {
    return asset.name;
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onSortByField(rows: Resource[]) {
    this.deletedAssets = rows;
    this.cdr.detectChanges();
  }

  onSort(sort: Sort, rows: Resource[]) {
    this.deletedAssets =  this.tableUtils.sortByField<Resource>(rows, sort.active, sort.direction === 'desc');
    this.cdr.detectChanges();
  }

}

