import { Injectable } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { DocumentReference } from "firebase/firestore";
import { asyncScheduler, BehaviorSubject, catchError, combineLatest, concatMap, delay, EMPTY, finalize, first, forkJoin, iif, map, Observable, of, reduce, scheduled, take, tap, toArray } from 'rxjs';

import { AccessConfirm, AccessConfirmDialog } from 'access_management/component/access-confirm-dialog/access-confirm-dialog.component';
import { AccessRestrictionAssetDialog } from 'access_management/component/access-restriction-asset-dialog/access-restriction-asset-dialog.component';
import { Restriction } from 'access_management/component/access-restriction-options/access-restriction-options.component';

import { SharedLink as SharedLinkFromClip } from '../../models/shared-link';
import { Asset, Clip } from '../../services/asset_service';
import { SharedLinksService } from '../../services/shared_links_service';
import { SnackBarService } from '../../services/snackbar_service';
import { SharedLink } from '../../shared_clipbin/models/shared_link_clipbin.model';
import { SharedLinkClipBinService } from '../../shared_clipbin/services/shared_link_clipbin.service';
import { PermissionDetail, ResourceAccessInfo } from '../models/access_management.model';

import { AccessManagementService } from './access_management.service';


export type RestrictionResult = { documentId: string | undefined, permissions: PermissionDetail[], restriction: Restriction } | undefined;

export type ClipbinsAndClips = { clips: Clip[], clipBinName: string, documentId: string | undefined, clipSharedLinks: SharedLink[] | undefined };

/**
 * This delay is necessary to give a interval between update the firebase and request the updates
 */
const DELAY_TIME = 2000;

export type AssetToRevoke = { name?: string, title?: string };

@Injectable({
  providedIn: 'root'
})
export class AccessManagementActionsService {

  private _loading$ = new BehaviorSubject(false);

  public get loading$() {
    return this._loading$;
  }
  public set loading$(value) {
    this._loading$ = value;
  }

  constructor(
    private readonly snackbar: SnackBarService,
    private readonly dialog: MatDialog,
    private readonly accessManagementService: AccessManagementService,
    private readonly clipbinSharedLinkService: SharedLinkClipBinService,
    private readonly sharedLinksService: SharedLinksService
  ) {}

  getRestriction(assetName: string): Observable<RestrictionResult> {
    if (!assetName) return of(undefined);

    return this.accessManagementService.getResourceAccessInfoByResource('RESTRICTION', assetName, 'ASSET')
      .pipe(
        first(),
        map(resources => ({
          documentId: resources[0]?.documentId,
          permissions: resources[0]?.permissions ?? [],
          restriction: resources[0]?.resourceAccessType === 'RESTRICTION' ? 'restrict' : 'public' as Restriction
        }))
      );
  }

  openAccessManagement(peopleAccess: PermissionDetail[], documentId: string) {
    return this.dialog.open(AccessRestrictionAssetDialog,
      {
        autoFocus: false,
        data: {
          permissions: peopleAccess.map((p) => { return { email: p.userId, displayName: p.displayName }; }),
          documentId: documentId
        }
      }).afterClosed();
  }

  openRestrictDialog(asset: Asset): Observable<undefined | DocumentReference> {

    const data: AccessConfirm = {
      icon: 'restrict__custom',
      title: 'Make Asset Restricted?',
      content: 'Are you sure you want to restrict this asset and associated clips? This will also revoke any existing shared links and will prevent users from creating shared links.',
      buttons: [
        {
          text: 'CANCEL',
          type: 'default',
          value: false
        },
        {
          text: 'CONFIRM',
          type: 'default',
          value: true
        }
      ]
    };

    return this.dialog.open(AccessConfirmDialog, { data })
      .afterClosed()
      .pipe(concatMap((confirm) => {
        if (confirm && asset) {
          const data: Partial<ResourceAccessInfo> = {
            permissions: [],
            resourceAccessType: 'RESTRICTION',
            resourceId: asset.name,
            resourceType: 'ASSET',
            title: asset.title
          };

          return scheduled(this.accessManagementService.createResourceAccessInfo(data), asyncScheduler)
            .pipe(
              tap(() => this.revokeClipbinRelated([{ name: asset.name, title: asset.title }]))
            );
        }

        return EMPTY;
      }));
  }

  openPublicDialog(documentId: string): Observable<void> {

    //Make Asset Public
    const data: AccessConfirm = {
      icon: 'public',
      title: 'Make Asset Public?',
      content: 'This will make the asset accessible to everyone. Ensure you\'re ready to share it publicly, as this action may impact privacy and access controls.',
      buttons: [
        {
          text: 'CANCEL',
          type: 'default',
          value: false
        },
        {
          text: 'C0NFIRM',
          type: 'default',
          value: true
        }
      ]
    };

    return this.dialog.open(AccessConfirmDialog, { data })
      .afterClosed()
      .pipe(concatMap((confirm) => {
        if (confirm) {
          return scheduled(this.accessManagementService.deleteDocument(documentId), asyncScheduler);
        }

        return EMPTY;
      }));
  }

  openMakePublicSelectedIds(documentIds?: string[]) {
    if (!documentIds?.length) {
      this.snackbar.message('These assets are already public.');
      return EMPTY;
    }
    //Make Assets Public
    const data: AccessConfirm = {
      icon: 'public',
      title: 'Make Asset Public?',
      content: 'This will make the asset accessible to everyone. Ensure you\'re ready to share it publicly, as this action may impact privacy and access controls.',
      buttons: [
        {
          text: 'CANCEL', type: 'default',
          value: false
        },
        {
          text: 'DONE', type: 'warn',
          value: true
        }
      ]
    };

    return this.dialog.open(AccessConfirmDialog, { data })
      .afterClosed()
      .pipe(
        concatMap((confirm) => {
          if (confirm) {
            this.loading$.next(true);
            return scheduled(this.accessManagementService.deleteManyDocumentsList(documentIds), asyncScheduler);
          }
          return EMPTY;
        }),
        delay(DELAY_TIME),
        concatMap(async () => this.snackbar.message('The assets are now accessible to the public.')),
        catchError(async () => this.snackbar.error('Error to make Public')),
        finalize(() => this.loading$.next(false))
      );
  }

  openAddUsersAndMakeRestrictOnSelectedIds(resourceUpdate: ResourceAccessInfo[], assetsToCreate: Partial<Asset>[] = []) {
    const assetsToRevoke: AssetToRevoke[] = [];

    return this.dialog.open(AccessRestrictionAssetDialog,
      {
        autoFocus: false,
        data: {
          permissions: [],
          documentId: resourceUpdate.map(r => r.documentId),
          updateManyAsset: true
        }
      })
      .afterClosed()
      .pipe(
        concatMap(
          (result: boolean | Partial<PermissionDetail>[]) =>
            //Complete the observable if the user CLOSE dialog
            iif(() => !result,
              EMPTY,
              of(result as Partial<PermissionDetail>[]))
        ),
        map((permissions) => {
          this.loading$.next(true);
          const requestedToUpdate: Observable<void>[] = [];
          const requestedToCreate: Observable<DocumentReference | undefined>[] = [];

          resourceUpdate.forEach((resource) => {
            assetsToRevoke.push({ name: resource.resourceId, title: resource.title });
            const oldPermissions: Partial<PermissionDetail>[] = [...resource.permissions];
            const newPermissions: Partial<PermissionDetail>[] = [];

            permissions.forEach(newPermission => {
              const existInOldPermission = oldPermissions.some(permissionUpdated => permissionUpdated.userId === newPermission.userId);
              if (existInOldPermission) return;

              newPermissions.push(newPermission);
            });

            if (newPermissions.length === 0) return;

            if (resource.documentId) {
              const mergedPermissions = [...oldPermissions, ...newPermissions];
              const req = scheduled(this.accessManagementService.updatePermissions(resource.documentId, mergedPermissions), asyncScheduler);
              requestedToUpdate.push(req);
            }
          });

          assetsToCreate.forEach((asset) => {
            assetsToRevoke.push({ name: asset.name, title: asset.title });
            const data: Partial<ResourceAccessInfo> = {
              permissions: [...permissions] as PermissionDetail[],
              resourceAccessType: 'RESTRICTION',
              resourceId: asset.name,
              resourceType: 'ASSET',
              title: asset.title
            };

            const reqCreate = scheduled(this.accessManagementService.createResourceAccessInfo(data), asyncScheduler);
            requestedToCreate.push(reqCreate);
          });

          return combineLatest([...requestedToUpdate, ...requestedToCreate]).pipe(take(1), delay(DELAY_TIME / 2));
        }),
        delay(DELAY_TIME),
        tap(() => this.revokeClipbinRelated(assetsToRevoke)),
        concatMap(async () => this.snackbar.message('Users added with success!')),
        catchError(async () => this.snackbar.error('Error to add users')),
        finalize(() => this.loading$.next(false))
      );
  }


  revokeClipbinRelated(idAssetsClipsNames: AssetToRevoke[]) {
    const restrictClipsSelectedList = idAssetsClipsNames.filter(f => !!f.name);

    if (!restrictClipsSelectedList?.length) return;

    this.clipbinSharedLinkService
      .getAllValidIASClipBinShareLink()
      .pipe(
        take(1),
        concatMap((clipsbins) => {
          const requestGroup: Observable<ClipbinsAndClips>[] = [];

          clipsbins.forEach(clipbin => {
            const requestToGetClip = this.clipbinSharedLinkService.getAllClips(decodeURIComponent(clipbin.clipBinName))
              .pipe(
                reduce((clips, clip) => {
                  clips.push(clip);
                  return clips;
                }, [] as Clip[]),
                take(1),
                map((clips) => {
                  return {
                    clipSharedLinks: clipbin.clipSharedLinks || [],
                    clipBinName: clipbin.clipBinName,
                    documentId: clipbin.documentId,
                    clips,
                  } as ClipbinsAndClips;
                })
              );

            requestGroup.push(requestToGetClip);
          });

          return combineLatest(requestGroup);
        }),
        map((clipbinsShared) => {
          const toRevoke = clipbinsShared.filter((clipbinShared) =>
            clipbinShared.clips
              //for each clip on the shared clipbins, check if has any restrict on it, from clip or assets
              .some((clip) => restrictClipsSelectedList
                .some((restrictClip) =>
                  //check if the clip into list has restrict, if yes it will be remain in the list to have the access revoked
                  clip.name === restrictClip.name || clip.original.name === restrictClip.name
                )));

          return toRevoke.filter(c => !!c.documentId);
        }),
        concatMap(clipbinsResult =>
          iif(() =>
            //If no clips are found within clipbins, check and revoke any single clips.
            //If restricted clips exist within clipbins, revoke each clip and its associated clipbin.
            clipbinsResult.length === 0,
            this.revokeClips(restrictClipsSelectedList),
            this.revokeClipBins(clipbinsResult)
          )
        )
      ).subscribe(
      //TODO check if we should show up the snackbar on success or fail to revoke
    );
  }



  private revokeClips(restrictClipsSelectedList: AssetToRevoke[]) {
    const requestClipsByTitle = restrictClipsSelectedList
      .map(revoke => this.sharedLinksService.search(revoke.title as string, 10) as Observable<{ sharedLinks: SharedLinkFromClip[] } | null>);

    if (!requestClipsByTitle?.length) return of();

    return forkJoin(requestClipsByTitle)
      .pipe(
        concatMap((sources) => {
          const sharedLinks = sources.flatMap(source => source?.sharedLinks);
          //find all clip into sharedLinks that has same origin name
          const toRevoke = sharedLinks.filter(sharedLink => restrictClipsSelectedList
            .some(restrictClip => restrictClip.name === sharedLink?.original))
            .map(foundClip => foundClip?.name as string);
          return toRevoke;
        }),
        toArray(),
        concatMap((revokeNames) => this.sharedLinksService.revokeAll(revokeNames))
      );
  }

  private revokeClipBins(clipBinNameRevoke: ClipbinsAndClips[]) {
    const req = clipBinNameRevoke.map(clipbins => {
      const assetsClipName = clipbins.clipSharedLinks?.map(clipLink => clipLink.assetName) || [];
      return this.clipbinSharedLinkService.revokeClipBinAndClipsIntoClipBin(assetsClipName, clipbins.documentId as string);
    });

    return forkJoin(req);
  }
}
