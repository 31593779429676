import { OrganizerTypes } from '../landing/clip-bin-section/service/resource-types';

/**
 * Returns an error message based on the current folder content and selected item type.
 *
 * @param isFolderContent Indicates if the current folder has existing folder content.
 * @param selectedItemType The type of item selected for creation (folder or clip bin).
 * @returns An error message string, or an empty string if no error condition is met.
 */
export const actionOptionErrorMessage = (isFolderContent: boolean, selectedItemType: OrganizerTypes, currentFolderLevel: number): string => {
  if (isFolderContent) {
      switch (selectedItemType) {
          case OrganizerTypes.CLIP_BIN:
              return 'Folders and clip bins cannot exist on the same level';
          default:
              return '';
      }
  }
  if (!isFolderContent) {
      if (selectedItemType === OrganizerTypes.FOLDER && currentFolderLevel === 2)
          return 'You cannot create folders more than 3 levels deep';
      switch (selectedItemType) {
          case OrganizerTypes.FOLDER:
              return 'Folders and clip bins cannot exist on the same level';
          default:
              return '';
      }
  }
  else return '';
};
