<button
  mat-fab
  color="new-action"
  aria-label="Choose creation job"
  class="{{ btnClass }}"
  [disabled]="disabled"
  [class.mam-fab-square]="squared"
  [class.mam-fab-small]="small"
  mam-firebase-ga="Choose creation job from {{ origin }}"
  [matMenuTriggerFor]="creationMenu"
>
  <mat-icon>{{ icon }}</mat-icon>
  @if (label) { <span>{{ label }}</span> }
</button>

<!-- New action Menu -->
<mat-menu
  #creationMenu="matMenu"
  class="menu-offset-down"
  backdropClass="add-pop-up"
>
  <button
    *ngFor="let action of actionList"
    mat-menu-item
    [class.option--spacing]="optionSpacing"
    [disabled]="!action.allow"
    mam-firebase-resource="action.key"
    [mam-firebase-ga]="'Trigger new ' + action.name + ' from ' + origin"
    [matTooltip]="showErrorMsg ? action.errorMsg : ''"
    matTooltipPosition="right"
    (click)="onClick(action.type)"
  >
    <ng-container [ngTemplateOutlet]="type" [ngTemplateOutletContext]="{$implicit: action.type}" />

    {{ action.name }}
  </button>
</mat-menu>
