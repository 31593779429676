import { Injectable, NgZone } from '@angular/core';
import {
  deleteDoc,
  doc,
  documentId,
  DocumentReference,
  Firestore,
  QueryFieldFilterConstraint,
  Timestamp,
  updateDoc,
  where
} from '@firebase/firestore';
import { combineLatest, Observable, of } from 'rxjs';
import { concatMap, first, map, take } from 'rxjs/operators';

import { AdminUsersService, IasUser } from '../../admin/admin_users_service';
import { AuthService } from '../../auth/auth_service';
import { FeatureFlagService } from '../../feature_flag/feature_flag_service';
import { FirebaseResolver } from '../../firebase/firebase_resolver';
import { Asset, Clip, Original } from '../../services/asset_service';
import {
  PermissionDetail,
  ResourceAccessInfo,
  ResourceAccessTypeEnum,
  ResourceAccessUser,
  ResourceTypeEnum
} from '../models/access_management.model';

export const ACCESS_MANAGEMENT_COLLECTION = 'ias-access-management';

//https://firebase.google.com/docs/firestore/query-data/queries#in_not-in_and_array-contains-any
/**
 * Use the in operator to combine up to 30 equality (==) clauses on the same field with a logical OR.
 *  An in query returns documents where the given field matches any of the comparison values. For example:
 */
const QUERY_LIMIT_FIREBASE = 30;

@Injectable({
  providedIn: 'root'
})
export class AccessManagementService {

  enableAccessManagementRestrictAssetFF = this.featureFlag.featureOn('enable-access-management');

  constructor(
    private readonly firebaseResolver: FirebaseResolver,
    private readonly ngZone: NgZone,
    private readonly adminUsersService: AdminUsersService,
    private readonly featureFlag: FeatureFlagService,
    private readonly authService: AuthService,
  ) {}

  async createResourceAccessInfo(data: Partial<ResourceAccessInfo>): Promise<undefined | DocumentReference> {
    data.createdAt = Timestamp.now();
    data.createdBy = this.authService.getUserEmail();

    return this.ngZone.runOutsideAngular(() => {
      return this.firebaseResolver.createFirestoreDoc(ACCESS_MANAGEMENT_COLLECTION, data);
    });
  }

  getResourceAccessInfoByResource(resourceAccessType: ResourceAccessTypeEnum, resourceId: string, resourceType: ResourceTypeEnum): Observable<ResourceAccessInfo[]> {
    const constraints: QueryFieldFilterConstraint[] = [
      where('resourceAccessType', '==', resourceAccessType),
      where('resourceId', '==', resourceId),
      where('resourceType', '==', resourceType),
    ];

    return this.queryResourceAccessInfo(constraints);
  }

  /**
   * Retrieves a paginated list of ResourceAccessInfo objects based on specified criteria.
   *
   * @param resourceAccessType - The type of resource access (e.g., "RESTRICTION").
   * @param resourceType - The type of resource (e.g., "ASSET").
   * @param lastDocumentId - The ID of the last document retrieved in the previous page.
   *                             Used for pagination.
   * @param pageSize - The number of resources to retrieve per page. Defaults to 30.
   * @param moveForward - A boolean indicating whether to retrieve the next page
   *                      (true) or the previous page (false). Defaults to true.
   * @param searchText - An optional search string to filter resources by title.
   *
   * @returns An Observable that emits an object containing:
   *   - **resources: ** An array of ResourceAccessInfo objects.
   *   - **count:** The total number of resources that match the given criteria.
   */
  getResourceAccessInfoByPagination(
    resourceAccessType: ResourceAccessTypeEnum,
    resourceType: ResourceTypeEnum,
    lastDocumentId: string | null = null,
    pageSize: number = 30,
    moveForward: boolean = true,
    searchText: string | undefined = undefined
  ): Observable<{ resources: ResourceAccessInfo[], count: number }> {
    const constraints: QueryFieldFilterConstraint[] = [
      where('resourceAccessType', '==', resourceAccessType),
      where('resourceType', '==', resourceType),
    ];

    if (searchText) {
      constraints.push(
        where('title', '>=', searchText),
        where('title', '<=', searchText + '~')
      );
    }

    return this.queryResourceAccessInfoPagination(constraints, lastDocumentId, pageSize, moveForward)
      .pipe(
        concatMap((resources) =>
          this.getCount(ACCESS_MANAGEMENT_COLLECTION, constraints)
            .pipe(map((count) => {
              return {
                resources,
                count
              };
            }))
        )
      );
  }

  getListResourceAccessInfoByResources(resourceAccessType: ResourceAccessTypeEnum, resourcesId: string[], resourceType: ResourceTypeEnum): Observable<ResourceAccessInfo[]> {
    const chunkSize = QUERY_LIMIT_FIREBASE;

    const groupOfIds: string[][] = [];
    const resources = [...new Set(resourcesId)];
    for (let i = 0; i < resources.length; i += chunkSize) {
      groupOfIds.push(resources.slice(i, i + chunkSize));
    }
    const chunksRequest = groupOfIds.map((resourceIdGroup) => {
      return this.getListOfResource(resourceAccessType, resourceIdGroup, resourceType);
    });

    return combineLatest(chunksRequest).pipe(
      map((results) => results.flat())
    );
  }

  private getListOfResource(resourceAccessType: ResourceAccessTypeEnum, resourcesId: string[], resourceType: ResourceTypeEnum): Observable<ResourceAccessInfo[]> {
    const constraints: QueryFieldFilterConstraint[] = [
      where('resourceAccessType', '==', resourceAccessType),
      where('resourceId', 'in', resourcesId),
      where('resourceType', '==', resourceType),
    ];

    return this.queryResourceAccessInfo(constraints);
  }

  public userHasRestrictedAssets(userId: string, resourceAccessType: ResourceAccessTypeEnum, resourceType: ResourceTypeEnum): Observable<boolean> {
    const constraints: QueryFieldFilterConstraint[] = [
      where('resourceAccessType', '==', resourceAccessType),
      where('resourceType', '==', resourceType),
    ];
    return this.queryDocument(ACCESS_MANAGEMENT_COLLECTION, constraints)
      .pipe(
        map(documents =>
          documents.map(document => ({ ...document.data() }) as Partial<ResourceAccessInfo>)
        ),
        map( (res) => {
          const filteredAssets = res.filter(asset =>
            asset.permissions && asset.permissions.some(permission => permission.userId === userId)
          );
          return !!filteredAssets.length;
        })

      );
  }

  private queryResourceAccessInfo(constraints: QueryFieldFilterConstraint[]) {
    return this.queryDocument(ACCESS_MANAGEMENT_COLLECTION, constraints)
      .pipe(
        map(documents =>
          documents.map(document => {
            return { ...document.data(), documentId: document.id } as ResourceAccessInfo;
          }))
      );
  }

  /**
   *
   * @param constraints The filters parameters
   * @param documentId the documentId limitation
   * @param pageSize the total of list
   * @param moveForward if the pagination is move forward or back
   * @returns a list of resource
   */
  private queryResourceAccessInfoPagination(constraints: QueryFieldFilterConstraint[], documentId: string | null, pageSize: number, moveForward: boolean) {
    return this.queryDocumentPagination(ACCESS_MANAGEMENT_COLLECTION, constraints, documentId, pageSize, moveForward)
      .pipe(
        map(documents =>
          documents.map(document => {
            return { ...document.data(), documentId: document.id } as ResourceAccessInfo;
          }))
      );
  }

  /**
   * Retrieves a list of documents from the given collection, filtered by the given constraints.
   *
   * @param collection The Firestore collection to query.
   * @param constraints The QueryFieldFilterConstraint array of conditions to filter the documents.
   * @returns An observable that emits an array of documents that match the given conditions.
   */
  private queryDocument(collection: string, constraints: QueryFieldFilterConstraint[]) {
    return this.firebaseResolver.queryCollectionWithoutLimitSize(collection, constraints);
  }

  /**
   * Retrieves a list of documents from the given collection, filtered by the given constraints.
   *
   * @param collection The Firestore collection to query.
   * @param constraints The QueryFieldFilterConstraint array of conditions to filter the documents.
   * @param start Start from documentId number X, if is null, it will start from begin
   * @param pageSize The length of list to return
   * @param moveForward If the pagination it forward or back
   * @returns An observable that emits an array of documents that match the given conditions.
   */
  private queryDocumentPagination(collection: string, constraints: QueryFieldFilterConstraint[], start: string | null = null, pageSize: number, moveForward: boolean) {
    return this.firebaseResolver.queryCollectionPagination(collection, constraints, start, pageSize, moveForward);
  }

  /** Get total of documents on a collection */
  getCount(collectionPath: string, constraints: QueryFieldFilterConstraint[]) {
    return this.firebaseResolver.countQuery(collectionPath, constraints);
  }

  public getIasUsers(): Observable<ResourceAccessUser[]> {
    return this.adminUsersService.searchUsers().pipe(
      take(1),
      map(response => {
        if (!response) {
          return [];
        }

        // Remove users that are not ACTIVE (like "DEPROVISIONED")
        let cleanedUsers = response.filter((user) => user.oktaStatus === 'ACTIVE');

        // Remove duplicate users based on the "email" property
        cleanedUsers = Array.from(
          new Map(cleanedUsers.map(item => [item.email, item])).values()
        );

        // Sort users by email in ascending order
        return cleanedUsers
          .map(user => this.transformIasUserToResourceAccessUser(user))
          .sort((a, b) => a.email.localeCompare(b.email));
      })
    );
  }

  private transformIasUserToResourceAccessUser(user: IasUser): ResourceAccessUser {
    return {
      displayName: user.displayName,
      email: user.email,
      access: user.access,
    };
  }

  async deleteDocument(id: string) {
    const ref = doc(this.getDB(), ACCESS_MANAGEMENT_COLLECTION, id);
    await deleteDoc(ref);
  }


  async deleteManyDocumentsList(ids: string[]) {
    const chunkSize = QUERY_LIMIT_FIREBASE;

    const groupOfIds: string[][] = [];
    const resources = [...new Set(ids)];
    for (let i = 0; i < resources.length; i += chunkSize) {
      groupOfIds.push(resources.slice(i, i + chunkSize));
    }
    const chunksRequest = groupOfIds.map((idsGroup) => {
      return this.deleteDocumentList(idsGroup);
    });

    return combineLatest(chunksRequest).pipe(
      map((results) => results.flat())
    );
  }

  async deleteDocumentList(ids: string[]) {
    const constraints: QueryFieldFilterConstraint[] = [
      where(documentId(), 'in', ids),
    ];
    await this.firebaseResolver.deleteCollectionBatchMode(ACCESS_MANAGEMENT_COLLECTION, constraints);
  }

  async updatePermissions(documentId: string, permissions: Partial<PermissionDetail>[]) {
    const ref = doc(this.getDB(), ACCESS_MANAGEMENT_COLLECTION, documentId);
    const updatedAt = Timestamp.now();
    const updatedBy = this.authService.getUserEmail();

    permissions = permissions.map((p) => {
      return {
        ...p,
        updatedAt,
        updatedBy,
      };
    });

    await updateDoc(ref, {
      permissions,
      updatedAt,
      updatedBy,
    });
  }

  private getDB() {
    return this.firebaseResolver.getFirestore() as Firestore;
  }

  /**
   * @param clips An array of clips.
   * @returns A new array of clips with associated permissions. Clips without user permissions are removed.
   */
  filterRestrictionOnAssetsClips(clips: Clip[]): Observable<Clip[]> {
    if (!this.enableAccessManagementRestrictAssetFF || !clips?.length) return of(clips);

    const { isAdmin } = this.authService;

    const clipsNames = clips.map(c => c.original?.name || c.name);
    const clipsRestrictionResult$ = this.getListResourceAccessInfoByResources('RESTRICTION', clipsNames, 'ASSET');
    const loggedUser = this.authService.getUserEmail();

    return isAdmin
      ? this.adminUser(clipsRestrictionResult$, clips)
      : this.regularUser(clipsRestrictionResult$, clips, loggedUser);

  }

  /**
   * @param clips An array of clips.
   * @returns A new array of clips without restrictions.
   */
  getFilteredPublicAssets(clips: Clip[]): Observable<Clip[]> {
    if (!this.enableAccessManagementRestrictAssetFF || !clips?.length) return of(clips);

    const clipsNames = clips.map(c => c.original?.name ?? c.name);
    const clipsRestrictionResult$ = this.getListResourceAccessInfoByResources('RESTRICTION', clipsNames, 'ASSET');

    return clipsRestrictionResult$
      .pipe(
        first(),
        map((restrictions) => {
          // Check if there is any restriction on the clips listed
          if (!restrictions?.length) return clips;

          return clips.filter(clip => {
            // Check if there is restriction on the current clip, if not return it.
            const hasRestriction = restrictions.find(r => r.resourceId === (clip.original?.name ?? clip.name));
            if (!hasRestriction) return clip;

            return false;
          });
        })
      );

  }

  filterRestrictionOnAssetName(assetName: string): Observable<ResourceAccessInfo[]> {
    if (!this.enableAccessManagementRestrictAssetFF || !assetName) return of();
    return this.getListResourceAccessInfoByResources('RESTRICTION', [assetName], 'ASSET');
  }

  private regularUser(clipsRestrictionResult$: Observable<ResourceAccessInfo[]>, clips: Clip[], loggedUser: string):
    Observable<Clip[]> {
    return clipsRestrictionResult$
      .pipe(
        first(),
        map((restrictions) => {
          // Check if there is any restriction on the clips listed
          if (!restrictions?.length) return clips;

          const resultClips: Clip[] = [];

          clips.forEach(clip => {
            // Check if there is restriction on the current clip, if not return it.
            const hasRestriction = restrictions.find(r => r.resourceId === (clip.original?.name ?? clip.name));

            if (!hasRestriction) {
              resultClips.push(clip);
              return;
            }
            // Check if the user logged has permission, if not return undefined
            const userHasAccess = hasRestriction.permissions?.some((permission) => permission.userId === loggedUser);

            if (userHasAccess) {
              clip.permissions = hasRestriction.permissions;
              resultClips.push(clip);
            }
          });
          return resultClips;
        })
      );
  }

  private adminUser(clipsRestrictionResult$: Observable<ResourceAccessInfo[]>, clips: Clip[]): Observable<Clip[]> {
    return clipsRestrictionResult$
      .pipe(
        first(),
        map((restrictions) => {
          // Check if there is any restriction on the clips listed
          if (!restrictions?.length) return clips;

          return clips.map(clip => {
            // Check if there is restriction on the current clip, if not return it.
            const hasRestriction = restrictions.find(r => r.resourceId === (clip.original?.name ?? clip.name));
            if (!hasRestriction) return clip;
            clip.permissions = hasRestriction.permissions;
            clip.permissionsDocumentId = hasRestriction.documentId;
            return clip;
          });
        })
      );
  }

  /**
   * Checks a clip for user access.
   *
   * @param clip The clip to check.
   * @returns The clip with associated restrictions,
   * or `undefined` if the user lacks access.
   */
  filterRestrictionOnAssetClip(clip: Clip): Observable<Clip | undefined> {
    if (!this.enableAccessManagementRestrictAssetFF || !clip) return of(clip);

    const { isAdmin } = this.authService;
    //No Restriction for ADMINS
    if (isAdmin) return of(clip);

    const clipsRestrictionResult$ = this.getResourceAccessInfoByResource('RESTRICTION', clip.original.name, 'ASSET');
    const loggedUser = this.authService.getUserEmail();

    return clipsRestrictionResult$
      .pipe(
        first(),
        map((restrictions) => {
          // Check if there is any restriction on the clip listed
          if (!restrictions?.length) return clip;

          // Check if there is restriction on the current clip, if not return it.
          const hasRestriction = restrictions.find(r => r.resourceId === clip.original.name);

          if (!hasRestriction) return clip;
          // Check if the user logged has permission, if not return undefined
          const userHasAccess = hasRestriction.permissions.some((permission) => permission.userId === loggedUser);

          return userHasAccess ? clip : undefined;
        }),
      );
  }

  /**
   * Checks an Asset for user access.
   *
   * @param asset The asset to check.
   * @returns The asset with associated restrictions,
   * or `undefined` if the user lacks access.
   */
  filterRestrictionOnAsset(asset: Asset | null): Observable<Asset | null> {
    if (!this.enableAccessManagementRestrictAssetFF || !asset) return of(asset);

    const { isAdmin } = this.authService;

    const assetName = asset.original?.name ?? asset.name;
    const clipsRestrictionResult$ = this.getResourceAccessInfoByResource('RESTRICTION', assetName, 'ASSET');
    const loggedUser = this.authService.getUserEmail();

    return clipsRestrictionResult$
      .pipe(
        first(),
        map((restrictions) => {
          // Check if there is any restriction on the clip listed
          if (!restrictions?.length) return asset;

          // Check if there is restriction on the current clip, if not return it.
          const hasRestriction = restrictions.find(r => r.resourceId === assetName);
          if (!hasRestriction) return asset;

          asset.permissions = hasRestriction.permissions;
          asset.permissionsDocumentId = hasRestriction.documentId;

          if (isAdmin) return asset;
          // Check if the user logged has permission, if not return undefined
          const userHasAccess = hasRestriction.permissions.some((permission) => permission.userId === loggedUser);

          return userHasAccess ? asset : null;
        }),
      );
  }


  filterAssetsByPermissions(assets: Original[]): Original[] {
    const { isAdmin } = this.authService;
    let recentAssets: Original[];
    if (isAdmin) {
      recentAssets = assets;
    } else {
      const loggedUser = this.authService.getUserEmail();
      recentAssets = assets.filter((clip) => {
        // Check if is a public clip, if so return it.
        if (!clip.permissions) return true;

        // Check if clip is only for admins
        if (clip.permissions && clip.permissions.length === 0) return false;

        // Check if clip has permission for logged user
        if (clip.permissions && clip.permissions.length > 0) {
          return clip.permissions.some((permission) => permission.userId === loggedUser);
        }

        return false;
      });
    }
    return recentAssets;
  }
}
