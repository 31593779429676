import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { BehaviorSubject, Subject, take } from 'rxjs';

import { AuthService } from '../auth/auth_service';
import { isErrorResponse } from '../error_service/error_response';
import { FeatureFlagService } from '../feature_flag/feature_flag_service';
import { ResourceTypes } from '../landing/clip-bin-section/service/resource-types';
import { ParentResource, ResourceService } from '../landing/clip-bin-section/service/resource.service';
import { SnackBarService } from '../services/snackbar_service';
import { StateService } from '../services/state_service';
import { preventSpecialChars } from '../utils/form.utils';

/** Clipbin creation dialog */
@Component({
    selector: 'mam-create-bin-dialog',
    templateUrl: './create_bin_dialog.ng.html',
    styleUrls: ['./create_bin_dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateBinDialog implements OnDestroy, OnInit {
  @ViewChild('input') inputRef!: ElementRef<MatInput>;

    static readonly dialogOptions = {
        hasBackdrop: true
    };
    processing = false;
    hasFolderInsideError$ = new BehaviorSubject<boolean>(false);
    isAnotherOwnedFolder: boolean = false;

    hasNotAllowedCharacters$ = new BehaviorSubject<boolean>(false);
    wordNotAllowed$ = new BehaviorSubject<boolean>(false);

    private readonly destroyed$ = new Subject<void>();

    constructor(
        readonly snackBar: SnackBarService,
        readonly resourceService: ResourceService,
        readonly dialogRef: MatDialogRef<CreateBinDialog>,
        readonly stateService: StateService,
        readonly cdr: ChangeDetectorRef,
        private readonly authService: AuthService,
        readonly featureService: FeatureFlagService,
        @Inject(MAT_DIALOG_DATA)
        readonly data: {
            parent: ParentResource;
        }
    ) {}

    ngOnInit() {
        if (this.data?.parent) {
            this.isAnotherOwnedFolder = this.data.parent.owner !== this.authService.getUserEmail();
            if (this.data?.parent?.children.some((value) => value.type === ResourceTypes.FOLDER.name)) {
                this.hasFolderInsideError$.next(true);
            }
            this.cdr.detectChanges();
        }
    }

    onInput() {
      const inputValue = this.inputRef.nativeElement.value;
      const { hasNotAllowedCharacters, wordNotAllowed } = preventSpecialChars(inputValue);
          this.hasNotAllowedCharacters$.next(hasNotAllowedCharacters);
          this.wordNotAllowed$.next(wordNotAllowed);
    }

    createClick(input: HTMLInputElement) {
        const name = input.value;
        if (!name?.trim()) {
            return;
        }
        this.processing = true; // Start processing
        this.cdr.detectChanges(); // Ensure UI reflects the processing state

        this.resourceService
            .createResource(
                ResourceTypes.CLIPBIN,
                {
                    name: name,
                    owner: this.authService.getUserEmail(),
                    ownerName: this.authService.getUserName(),
                    parentId: this.data?.parent?.id
                },
                this.data?.parent?.id
            )
            .pipe(take(1)) // Automatically unsubscribe after taking the first value
            .subscribe({
                next: (result) => {
                    if (isErrorResponse(result)) {
                        this.snackBar.error({
                            message: 'Create clip bin failed.',
                            details: result.message,
                            doNotLog: true
                        });
                    } else {
                        this.snackBar.message('Clip bin has been created');
                        this.dialogRef.close(); // Close the dialog on successful creation
                    }
                },
                error: (error) => {
                    console.error('Error creating clip bin:', error);
                    this.snackBar.error({
                        message: 'An unexpected error occurred while creating a clip bin.',
                        doNotLog: true
                    });
                },
                complete: () => {
                    this.processing = false; // Ensure `processing` is reset
                    this.cdr.detectChanges(); // Update UI to reflect completion
                }
            });
    }

    isDisabled() {
        return (
            this.processing ||
            this.isAnotherOwnedFolder ||
            this.hasFolderInsideError$.value ||
            this.hasNotAllowedCharacters$.value ||
            this.wordNotAllowed$.value
        );
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    onEnter(input: HTMLInputElement) {
        if (input.value.trim() && !this.processing) {
            this.createClick(input);
        }
    }
}
