<mat-card appearance="outlined" class="folder_card" [ngClass]="{
    'folder_card--list': viewMode === 'list',
    'folder_card--grid mam-elevation-1': viewMode === 'grid'
  }"
mam-firebase-ga="Open clip folder from its preview"
[mam-firebase-resource]="folder.id"
>
  <div class="card-header-image" (click)="navigateToFolder(folder.id)">
    @if (clips && clips.length > 0) {
    <mam-asset-thumbnail *ngFor="let asset of clips | slice:0:3; trackBy: trackClip" [asset]="asset">
    </mam-asset-thumbnail>
    }
    <div class="folder-owner" *ngIf="showAllFolders">
      @if((folder.ownerName && folder.ownerName.length) || (folder.owner && folder.owner.length)){
        <span class="folder-owner-icon">{{ (folder!.ownerName || folder!.ownerName!.length ? folder.ownerName : folder.owner )![0].toUpperCase() }}</span>
      } @else {
        <mat-icon class="folder-owner-icon" role="img" aria-hidden="true"> person </mat-icon>
      }

      <p class="folder-owner-text" title="{{ folder.createdBy }}">
        {{ folder.ownerName || folder.ownerName?.length ? folder.ownerName : folder.owner }}
      </p>
    </div>
</div>
<div class="card-content">
    <div class="card-content-width">
        <div class="card-content-title" [attr.title]="folder.displayName || folder.name"
             (click)="navigateToFolder(folder.id)"
        >
            <div class="folder-title">
                <img
                    class="folder-title-icon"
                    src="../../images/folder_icon_light.svg"
                    alt="Folder Icon Light"
                />
                <p class="folder-title-text">{{ folder.displayName || folder.name }}</p>
            </div>
        </div>
        <div class="card-footer">
            <!-- Breadcrumb Icon -->
            <mam-breadcrumb (isExpandedChange)="onIsExpandedChange($event)" [resource]="folder"></mam-breadcrumb>
            <!-- Item count -->
            <div class="card-content-subtitle" [ngPlural]="getAssetCount(folder)">
                <ng-template ngPluralCase="=0">No items</ng-template>
                <ng-template ngPluralCase="=1">1 item</ng-template>
                <ng-template ngPluralCase="other">{{ folder.directChildrenCount }} items</ng-template>
                <ng-template ngPluralCase="=-1"></ng-template>
            </div>
        </div>
    </div>
    @if(isAdmin || !showAllFolders){
    <button mat-icon-button class="folder-button main__icon--size" [matMenuTriggerFor]="menu"
      aria-label="Open folder Menu" (click)="$event.stopPropagation()" mam-firebase-ga="Open folder menu"
      [mam-firebase-resource]="folder.id">
        <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="folder-options-menu">
      <button mat-menu-item (click)="openRenameFolder()" mam-firebase-ga="Open clip folder rename dialog"
        [mam-firebase-resource]="folder.id">
            Rename
            </button>
      <button *ngIf="!showAllFolders" mat-menu-item (click)="openMoveFolder()" mam-firebase-ga="Open clip folder move dialog"
        [mam-firebase-resource]="folder.id">
        Move
      </button>
      <button mat-menu-item (click)="openDeleteFolder()" class="delete-button" mam-firebase-ga="Select folder deletion"
        [mam-firebase-resource]="folder.id">
            Delete folder
            </button>
        </mat-menu>
    }
</div>
</mat-card>
