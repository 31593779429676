<!-- Clip Bins Section -->
<section class="clipbins">
  <header [class.mam-border--bottom]="displayMode === 'list'" class="clipbins_header">
    <div class="clipbins_header_owner">
      <h2 class="clipbins_header__title">{{ username }}'s page</h2>

      <div class="clipbins_header_owner__actions">
        <!-- Display mode toggle -->
        <button
          (click)="this.toggleViewMode()"
          [mam-firebase-ga]="'Toggle ' + (isGrid(displayMode) ? 'list view': 'grid view')"
          [matTooltip]="'Switch to ' + (isGrid(displayMode) ? 'list view': 'grid view')"
          class="clipbins_actions__display-mode bins_display-mode"
          color="primary"
          mat-mini-fab
        >
          <mat-icon class="material-icons-outlined">
            {{ isGrid(displayMode) ? 'list' : 'view_module' }}
          </mat-icon>
        </button>

        <!-- new action button -->
        <div class="clipbins_create_job mobile-upper-toolbar">
          <mam-new-action-button
            (clickAction)="executeCreateAction($event)"
            [actions]="createActions"
            [optionSpacing]="false"
            [small]="true"
            [squared]="true"
            origin="clip bin section"
          ></mam-new-action-button>
        </div>
      </div>
    </div>

    <div class="clipbins_header_search">
      <!-- search box -->
      <mat-form-field
        #searchField
        [class.is-focused]="searchInput.focus"
        appearance="outline"
        class="clipbins_search-form"
      >
        <mat-icon class="clipbins_search-form__icon" matPrefix>search</mat-icon>
        <input
          #searchInput
          [formControl]="searchControl"
          [placeholder]="getPlaceholderText()"
          [value]="searchControl.value"
          aria-label="Search inside clip bins section"
          class="clipbins_search-form__input"
          matInput
          type="text"
        />

        <!-- Clear icon -->
        @if (searchInput.value !== '') {
          <button
            mat-icon-button
            matSuffix
            aria-label="Clear"
            matTooltip="Clear"
            mam-firebase-ga="Clear search input"
            (click)="onSearchClear(); $event.stopPropagation()"
            class="clipbins_search-form__clear clear-button"
          >
            <mat-icon>close</mat-icon>
          </button>
        }

        <!-- Search mode dropdown -->
        <ng-container matSuffix>
          @if (searchInput.value !== '') {
            <mat-divider [vertical]="true"></mat-divider>
          }
          <button
            #menuTrigger="matMenuTrigger"
            (click)="$event.stopPropagation()"
            [class.active]="menuTrigger.menuOpen"
            [matMenuTriggerFor]="menu"
            class="clipbins_search__dropdown search_dropdown"
            mam-firebase-ga="Open search mode selector via dropdown"
            mat-button
          >
            <div class="search_dropdown_inner">
              <span>{{ formatBinSectionContent(searchModeSelected) }}</span>
              <mat-icon class="arrow">arrow_drop_down</mat-icon>
            </div>
          </button>

          <!-- Search mode menu -->
          <mat-menu #menu="matMenu"
                    class="clipbins_search__dropdown_options search_dropdown_options">
            <div class="mat-menu-title">RESULT TYPE</div>
            @for (mode of searchModeOptions; track mode) {
              <button
                mat-menu-item
                class="search_dropdown_options__item"
                (click)="handleSearchModeChange(mode)"
                mam-firebase-ga="Select search mode"
                [mam-firebase-resource]="mode"
                [disabled]="searchModeDisabled"
              >
                <mat-icon [style.opacity]="mode === searchModeSelected ? 1 : 0">done</mat-icon>
                <span>{{ formatBinSectionContent(mode) }}</span>
              </button>
            }
          </mat-menu>
        </ng-container>
      </mat-form-field>
    </div>

    <div class="clipbins_header_views">
      <mat-slide-toggle
        (change)="onShowAllChange($event)"
        [checked]="showAllAssets"
        [class.non-clickable]="isShowAllAssetsDisabled"
        class="clipbins_header__toggle"
        color="primary"
        name="bins_toggle"
        title="Show user's or all assets"
      >
        Show all
      </mat-slide-toggle>
    </div>
  </header>

  @if (displayMode === 'grid') {
    <div class="clipbins_actions">
      <div class="clipbins_actions_bulk">
        <!-- Select/De-select all. -->
        @if (shouldShowCheckbox()) {
          <mat-checkbox
            class="bulk-actions__select-all"
            color="primary"
            matTooltipPosition="above"
            mam-firebase-ga="Toggle selection for all segments in search results"
            (change)="toggleSelectAll()"
            [checked]="allChecked"
            [indeterminate]="someChecked"
          >
          </mat-checkbox>
        }
        @if (this.itemMultiSelection.size !== 0) {
          <div class="bulk-actions">
            <!-- <button mat-icon-button><mat-icon class="material-icons-outlined">drive_file_move</mat-icon></button> -->
            <button mat-icon-button (click)="deleteSelection()">
              <mat-icon class="material-icons-outlined">delete</mat-icon>
            </button>
          </div>
        }
      </div>
      <div class="clipbins_actions_pagination">
        @switch (searchModeSelected) {
          @case (SEARCH_MODE.BIN) {
            <mat-paginator
              hidePageSize
              class="main__mat-paginator--border"
              [length]="binsPagination.totalCount"
              [pageSize]="binsPagination.pageSize"
              [pageIndex]="binsPagination.pageIndex"
              [disabled]="resultsLoading"
              (page)="onPageChange($event)"
              aria-label="Select page"
            >
            </mat-paginator>
          }
          @case (SEARCH_MODE.CLIP) {
            <mat-paginator
              hidePageSize
              class="main__mat-paginator--border"
              [length]="clipsPagination.totalCount"
              [pageSize]="clipsPagination.pageSize"
              [pageIndex]="clipsPagination.pageIndex"
              [disabled]="resultsLoading"
              (page)="onPageChange($event)"
              aria-label="Select page"
            >
            </mat-paginator>
          }
          @case (SEARCH_MODE.FOLDER) {
            <mat-paginator
              hidePageSize
              class="main__mat-paginator--border"
              [length]="foldersPagination.totalCount"
              [pageSize]="foldersPagination.pageLimit"
              [pageIndex]="foldersPagination.pageIndex"
              (page)="onPageChange($event)"
              [disabled]="isPaginatorDisabled"
              aria-label="Select page"
            >
            </mat-paginator>
          }
        }
      </div>
    </div>
  }

  <!-- List and Grid -->
  @if (!folderUrlId || (searchText != null && searchText !== '')) {
    @if (resources$ | async; as results) {
      @if (resultsLoading) {
        <!-- Loading -->
        @if (resultsLoading$ | async) {
          <div class="skeletons">
            @for (item of resultsLoading$ | async; track item) {
              <mam-assets-skeleton class="mam-skeleton"
                                   [display]="displayMode"></mam-assets-skeleton>
            }
          </div>
        }
      } @else {
        @if (!results.length && !resultsLoading) {
          <!-- Empty bins -->
          <mam-empty-assets-card
            [assetsType]="formatBinSectionContent(searchModeSelected)"
            [hasSearchText]="!!searchText"
            [helperText]="true"
          >
            <p>
              Use the <span><mat-icon>add</mat-icon> New</span> button in the top left to create one
              and start collecting
              {{ formatBinSectionContent(searchModeSelected) }}.
            </p>
          </mam-empty-assets-card>
        } @else {
          @if (displayMode === 'list') {
            <!-- List view -->
            <div class="list-view-wrapper">
              <mam-cbo-list-display
                [isAdmin]="isAdmin"
                [isShowAll]="showAllAssets"
                [startAfter]="listViewStartAfter || ''"
                [owner]="showAllAssets ? '': userEmail"
                [result]="results"
                [selection]="itemMultiSelection"
              ></mam-cbo-list-display>
            </div>
          } @else {
            <!-- Display results -->
            <div class="clipbins_grid">
              @switch (searchModeSelected) {
                @case (SEARCH_MODE.CLIP) {
                  @for (clip of clipResults; track clip) {
                    <div class="clipbins_grid_card_container">
                      <mam-clip-preview class="clip-preview" [clip]="clip"
                                        [viewMode]="displayMode"></mam-clip-preview>
                    </div>
                  }
                }
                @case (SEARCH_MODE.FOLDER) {
                  @for (folder of folderResults; track folder) {
                    <div class="clipbins_grid_card_container folder_grid_card_container">
                      <mam-clip-bin-folder-preview
                        [isAdmin]="isAdmin"
                        [userEmail]="userEmail"
                        [folder]="folder"
                        [viewMode]="displayMode"
                        [showAllFolders]="showAllAssets"
                        class="clipbins_grid__card folder_grid__card"
                      ></mam-clip-bin-folder-preview>
                    </div>
                  }
                }
                @case (SEARCH_MODE.BIN) {
                  @for (bin of binResults; track bin) {
                    <div class="clipbins_grid_card_container">
                      <div class="clipbins_checkbox" *featureFlagOn="'use-clips-selection'">
                        @if (shouldShowCheckbox()) {
                          <mat-checkbox
                            color="primary"
                            (click)="toggleSelection($event,bin); $event.stopPropagation();"
                            [checked]="itemMultiSelection.has(bin.name)"
                          >
                          </mat-checkbox>
                        }
                      </div>
                      <mam-clip-bin-preview
                        [bin]="bin"
                        [checkboxPresent]="true"
                        [isAdmin]="isAdmin"
                        [showAllBins]="showAllAssets"
                        [binsViewMode]="displayMode"
                        [active]="itemMultiSelection.has(bin.name)"
                        class="clipbins_grid__card"
                      ></mam-clip-bin-preview>
                    </div>
                  }
                }
              }
            </div>
          }
        }
      }
    }
  } @else {
    <mam-folder-content
      #folderContent
      [isAdmin]="isAdmin"
      [userEmail]="userEmail"
      [folderId]="folderUrlId"
      [displayMode]="displayMode"
      [searchTerm]="searchText || ''"
    ></mam-folder-content>
  }
  @if (displayMode === 'grid') {
    <div class="pagination_bottom">
      <div class="clipbins_actions_pagination">
        @switch (searchModeSelected) {
          @case (SEARCH_MODE.BIN) {
            <mat-paginator
              hidePageSize
              class="main__mat-paginator--border"
              [length]="binsPagination.totalCount"
              [pageSize]="binsPagination.pageSize"
              [pageIndex]="binsPagination.pageIndex"
              [disabled]="resultsLoading"
              (page)="onPageChange($event)"
              aria-label="Select page"
            >
            </mat-paginator>
          }
          @case (SEARCH_MODE.CLIP) {
            <mat-paginator
              hidePageSize
              class="main__mat-paginator--border"
              [length]="clipsPagination.totalCount"
              [pageSize]="clipsPagination.pageSize"
              [pageIndex]="clipsPagination.pageIndex"
              [disabled]="resultsLoading"
              (page)="onPageChange($event)"
              aria-label="Select page"
            >
            </mat-paginator>
          }
          @case (SEARCH_MODE.FOLDER) {
            <mat-paginator
              hidePageSize
              class="main__mat-paginator--border"
              [length]="foldersPagination.totalCount"
              [pageSize]="foldersPagination.pageLimit"
              [pageIndex]="foldersPagination.pageIndex"
              (page)="onPageChange($event)"
              [disabled]="isPaginatorDisabled"
              aria-label="Select page"
            >
            </mat-paginator>
          }
        }
      </div>
    </div>
  }
</section>
