import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {DateTime, Duration} from 'luxon';
import {BehaviorSubject, firstValueFrom, from, Observable, of, Subject} from 'rxjs';
import {filter, first, map, mergeMap, reduce, shareReplay, switchMap} from 'rxjs/operators';

import {PermissionEnum} from '../access_management/models/access_management.model';
import {ApiSortByOption} from '../api/ias/model/models';
import {AuthService} from '../auth/auth_service';
import {environment} from '../environments/environment';
import {ErrorResponse, hasAdminRightsMissing, isErrorResponse} from '../error_service/error_response';
import {ErrorService} from '../error_service/error_service';
import {FeatureFlagService} from '../feature_flag/feature_flag_service';
import {FirebaseFirestoreDataService} from '../firebase/firebase_firestore_data_service';
import {Metadata} from '../models';
import {GcmaQueryExpressions} from '../query_expressions/gcma_query_expressions';
import {VcmsQueryExpressions} from '../query_expressions/vcms_query_expressions';
import {AssetApiService, AssetState, LIVE_ASSET_LOCATION_METADATA_FIELDS, MetadataField, NEW_ASSET_COPY_NAME} from '../services/asset_api_service';
import {AssetCopy, AssetService, ListResponse, Original} from '../services/asset_service';
import {DialogService} from '../services/dialog_service';
import {ApiApprovalStateStateEnum, ApiAssetStateEnum} from '../services/ias_types';
import {SearchMode, SearchParams, SearchQueryParams, SearchResponse, SearchSegment, SearchService, SearchType} from '../services/search_service';
import {SnackBarService} from '../services/snackbar_service';
import {HomeView, StateService} from '../services/state_service';
import {DEFAULT_CONCURRENT_REQUEST_NUMBER, UtilsService} from '../services/utils_service';
import {BatchOperationService} from '../shared/batch_operation_service';

import {MetadataService} from './metadata_service';

/**
 * The Name of the schema based on hardcoded ID that is used for live asset cutdown
 * and must exist in all environments.
 */
const CUT_DOWN_SCHEMA_NAME =
    `${environment.mamApi.parent}/metadataSchemas/cutdown`;

/** Predefined GCP folder for the 'cloud ingest' functionality. */
export const CLOUD_INGEST_FOLDER = 'cloud_ingest';
/** Predefined GCP folder for the 'local upload' functionality. */
export const LOCAL_UPLOADS_FOLDER = 'local-uploads';
/** Predefined GCP folder for media transferred from on-prem storages. */
export const MEDIACACHE_FOLDER = 'mediacache';

/** Intent for staged asset approval with optional cut-down instructions. */
export interface ApprovalRequest {
  asset: Original;
}

/** Service that will provide data for live/vod content staging tables. */
@Injectable()
export class StagingService {
  private readonly selectedAssetSetInternal$ =
      new BehaviorSubject<Set<string>>(new Set());

  /** Names of currently selected live/vod assets in content staging. */
  readonly selectedAssetSet$: Observable<Set<string>> =
      this.selectedAssetSetInternal$;

  private readonly activeItemsInternal$ =
      new BehaviorSubject<ActiveItems|undefined>(undefined);

  /**
   * Active live/vod assets or live cut-downs in content staging.
   * Active is the one which details are displayed in the staging side panel.
   */
  readonly activeItems$: Observable<ActiveItems|undefined> =
      this.activeItemsInternal$;

  /** Whether `activeItems$` contain asset(s). */
  readonly hasActiveAssets$: Observable<boolean> =
      this.activeItemsInternal$.pipe(map(active => {
        return Boolean(active?.assets?.length);
      }));

  /** Whether `activeItems$` contain cut-downs(s). */
  readonly hasActiveCutdowns$: Observable<boolean> =
      this.activeItemsInternal$.pipe(map(active => {
        return Boolean(active?.cutdowns?.length);
      }));

  /** A set of active item names. See `activeItems$` for more information. */
  readonly activeItemSet$ = this.watchActiveItemNames();

  /** Currently active content staging view. */
  readonly activeView$ = new BehaviorSubject<StagingView>('none');

  private readonly assetsApprovedInternal$ =
      new BehaviorSubject<Original[]>([]);

  /** Emits newly approved assets. */
  readonly assetsApproved$: Observable<Original[]> =
      this.assetsApprovedInternal$;

  /** Indicates whether the active asset(s)/cut-down(s) are being edited. */
  readonly isEditing$ = this.metadataService.isEditing$;

  private readonly pendingApprovalInternal$ =
      new BehaviorSubject(new Set<string>());

  /** Emits names of assets that are being approved at this moment. */
  readonly pendingApproval$: Observable<Set<string>> =
      this.pendingApprovalInternal$;

  /** Emits current new unsaved cutdown */
  readonly newCutdown$ = new BehaviorSubject<NewCutdown|undefined>(undefined);

  /** Emits any time cutdown is created, updated, deleted or discarded */
  readonly cutdownChanged$ = new Subject<CutdownChanged>();

  readonly isEnabledAccessManagement = this.featureService.featureOn('enable-access-management');

  constructor(
      private readonly vcmsQueries: VcmsQueryExpressions,
      private readonly gcmaQueries: GcmaQueryExpressions,
      private readonly assetApi: AssetApiService,
      private readonly assetService: AssetService,
      private readonly searchService: SearchService,
      private readonly errorService: ErrorService,
      private readonly snackBar: SnackBarService,
      private readonly dialogService: DialogService,
      private readonly metadataService: MetadataService,
      private readonly utils: UtilsService,
      private readonly batchOperationService: BatchOperationService,
      private readonly stateService: StateService,
      private readonly router: Router,
      private readonly featureService: FeatureFlagService,
      private readonly dataService: FirebaseFirestoreDataService,
      private readonly authService: AuthService,
  ) {
    this.hookUpApprovalRequestProcessing();

    // when there are no longer active items, the panel should be closed
    this.activeItemsInternal$.pipe(filter(activeItems => !activeItems))
      .subscribe(() => {
        stateService.togglePersistentPanel$.next(false);
      });
  }

  filterAssetsByPermissions(response: SearchResponse | ErrorResponse): Observable<SearchResponse | ErrorResponse> {
    if (!this.isEnabledAccessManagement || this.authService.isAdmin) {
      return of(response);
    }

    if (isErrorResponse(response)) {
      return of(response);
    }
    const searchResponse = response as SearchResponse;

    const userEmail = this.authService.getUserEmail();
    if ( !userEmail ) {
      return of({} as SearchResponse);
    }

    const { videoSegments: assets } = searchResponse;
    const filteredAssets: SearchSegment[] = [];

    assets.forEach( asset => {
      if (
        // Asset is public (no permissions property)
        !asset.permissions ||
        // Asset is only for admins (it has permissions property but empty)
        (asset.permissions && asset.permissions.length === 0 && this.authService.isAdmin)
      ) {
        filteredAssets.push(asset);
      }
      else {
        // Restricted asset found, check if the user is in the list of GRANTED permissions
        const permissionFound = asset.permissions.find(permission =>
          permission.permission === PermissionEnum.GRANTED && permission.userId === userEmail
        );
        if ( permissionFound ) {
          filteredAssets.push(asset);
        }
      }
    });

    searchResponse.videoSegments = [...filteredAssets];
    return of(searchResponse);
  }

  getVodAssets(request: VodListRequest):
      Observable<ListResponse<Original>|ErrorResponse> {
    const STATE_READY = ApiAssetStateEnum.STATE_READY;
    const STATE_PROCESSING = ApiAssetStateEnum.STATE_PROCESSING;
    const DRAFT = ApiApprovalStateStateEnum.DRAFT;
    const userQuery = request.query;

    const query = this.vcmsQueries.and([
      userQuery ?? '',
      // Draft VoD assets. Live assets cannot be in STATE_PROCESSING.
      // VoD assets resulting from the cutdown are approved so will be filtered out.
      this.vcmsQueries.anyOf('AssetState', [STATE_READY, STATE_PROCESSING]),
      this.vcmsQueries.is('AssetApprovalState', DRAFT),
    ]);

    const searchRequest: SearchParams = {
      pageSize: request.pageSize,
      pageToken: request.pageToken,
      query,
      searchMode: SearchMode.VIDEO,
      searchType: SearchType.VOD,
      facetSelections: [],
      sortOptions: request.sortOptions,
    };

    return this.searchService.searchUnrestricted(searchRequest)
        .pipe(
          switchMap((response) => this.filterAssetsByPermissions(response)),
          map(response => {
          if (isErrorResponse(response)) return response;
          const assets = this.isEnabledAccessManagement
          ? response.videoSegments.map(vs => {
                const {permissions, asset, permissionsDocumentId} = vs;
                return permissions ? {...asset, permissions, permissionsDocumentId} : {...asset};
            })
          : response.videoSegments.map(vs => vs.asset);

          if (this.featureService.featureOn('show-user-information')) {
            this.fillAssetsWithSource(assets);
          }
          return {assets, nextPageToken: response.nextPageToken};
        }));
  }
  getVodErrorAssets(request: VodListRequest):
      Observable<ListResponse<Original>|ErrorResponse> {
    const STATE_ERROR = ApiAssetStateEnum.STATE_ERROR;
    const userQuery = request.query;

    const query = this.gcmaQueries.and([
      // Look for partial match in the gcs url.
      !userQuery ? '' : this.gcmaQueries.or([
        this.gcmaQueries.includes('title', userQuery),
        this.gcmaQueries.includes('gcsObject', userQuery),
      ]),
      // Get VoD assets in error state only.
      this.gcmaQueries.is('state', STATE_ERROR),
    ]);

    return this.assetApi
        .list({
          pageSize: request.pageSize,
          pageToken: request.pageToken,
          filter: query,
        })
        .pipe(
            map(({assets, nextPageToken}) => {
              if (this.featureService.featureOn('show-user-information')) {
                this.fillAssetsWithSource(assets);
              }
              return {assets, nextPageToken};
            }),
            this.errorService.retryShort(),
            this.errorService.catchError(),
        );
  }

  /** Fetches live assets, draft and approved.  */
  getLiveAssets(request: LiveListRequest): Observable<Original[]|null> {
    const STATE_STREAMING_STOPPED = ApiAssetStateEnum.STATE_STREAMING_STOPPED;
    const STATE_ERROR = ApiAssetStateEnum.STATE_STREAMING_ERROR;
    const STATE_PROCESSING = ApiAssetStateEnum.STATE_PROCESSING;

    const query = this.vcmsQueries.and([
      // Query entered by the user.
      request.query ?? '',
      // Get ended live assets and the ones that are processing or errored out.
      this.vcmsQueries.anyOf(
          'AssetState',
          [STATE_STREAMING_STOPPED, STATE_ERROR, STATE_PROCESSING]),
      // Constrain by selected date. This check makes sure that asset originated as live asset.
      this.vcmsQueries.withinDate('EventStartTime', request.date),
    ]);

    const searchRequest: SearchQueryParams = {
      query,
      facetSelections: [],
      searchMode: SearchMode.VIDEO,
      searchType: SearchType.LIVE,
    };

    return this.searchService.searchAllUnrestricted(searchRequest)
        .pipe(
          switchMap( (response) => this.filterAssetsByPermissions(response)),
          map(response => {
            if (isErrorResponse(response)) return null;

            let hasEmptyResponses = false;
            let assets = this.isEnabledAccessManagement
            ? response.videoSegments.map(vs => {
              const {permissions, asset, permissionsDocumentId} = vs;
              return permissions ? {...asset, permissions, permissionsDocumentId} : {...asset};
            }) : response.videoSegments.map(vs => vs.asset);

                assets = assets.filter(asset => {
                  // Filter out empty responses.
                  if (!asset.name) {
                    hasEmptyResponses = true;
                    return false;
                  }

                  // Filter out VoD assets, which could have gotten here due to
                  // the lag in VCMS indexing.
                  return asset.state !== AssetState.VOD;

                });

            if (hasEmptyResponses) {
              // Log presence of empty responses.
              this.errorService.handle(
                  `LiveStaging: received empty search results for ${
                      request.date.toISO()}`);
            }

            if (this.featureService.featureOn('show-user-information')) {
              this.fillAssetsWithSource(assets);
            }

            return assets;
          }),
        );
  }

  select(assets: Original[]) {
    if (this.metadataService.isEditing()) return;
    this.selectedAssetSetInternal$.next(new Set(assets.map(a => a.name)));
  }

  /** Start metadata editing of the eligible assets */
  async edit(assets: Original[]) {
    const eligibleAssets = assets.filter(asset =>
        !asset.approved || this.isSearchResultView());
    if (!eligibleAssets.length) {
      this.snackBar.error('Cannot edit approved assets');
      return;
    }

    const ineligibleAssetCount = assets.length - eligibleAssets.length;
    if (ineligibleAssetCount > 0) {
      const ignoredLabel = ineligibleAssetCount > 1 ?
          `${ineligibleAssetCount} approved assets` :
          '1 approved asset';
      const confirmed =
          await firstValueFrom(this.dialogService.showConfirmation({
            title: 'Edit',
            question: [
              `${ignoredLabel} will be ignored.`,
              `Proceed for remaining ${eligibleAssets.length}?`
            ].join('\n'),
            primaryButtonText: 'Proceed',
          }));

      if (!confirmed) return;
    }

    return this.setActive({assets: eligibleAssets, startEditing: true});
  }

  /**
   * Sets asset(s) as active in the staging table and metadata panel.
   * If there is an edit in progress will show a cancel confirmation message
   * unless `skipConfirmation` is set to true.
   */
  async setActive(active: ActiveItems|undefined, skipConfirmation = false) {
    // Convert empty active items to undefined.
    if (active && !active.assets?.length && !active.cutdowns?.length) {
      active = undefined;
    }

    if (this.setActiveInProgress) return;

    if (!skipConfirmation) {
      // Raise setActiveInProgress only when async operation involved.
      this.setActiveInProgress = true;
      const confirmed =
          await firstValueFrom(this.metadataService.cancelWithConfirmation());
      this.setActiveInProgress = false;
      if (!confirmed) return;
    }

    const currentItems = this.getActive();
    this.activeItemsInternal$.next(active);

    // When active item is changed while new (unsaved) cutdown was active,
    // consider the new cutdown discarded.
    const cutdown =
        currentItems?.cutdowns?.find(c => c.name === NEW_ASSET_COPY_NAME);
    if (currentItems?.cutdownParent && cutdown) {
      this.cutdownChanged$.next({
        name: cutdown.name,
        newValue: null,
        parent: currentItems.cutdownParent
      });
    }

    // Expand a persistent panel when active item changes.
    if (active && !active.skipPanelExpansion) {
      this.stateService.togglePersistentPanel$.next(true);
    }
  }

  /** Returns current active (displayed in right side panel) items. */
  getActive() {
    return this.activeItemsInternal$.value;
  }

  /** Returns the current selected (row's checkbox checked) items. */
  getSelection(): Set<string> {
    return this.selectedAssetSetInternal$.value;
  }

  /** Adds or removes assets from the current asset selection. */
  updateSelection(assetToSelect: Original[], assetsToUnselect: Original[]) {
    if (this.metadataService.isEditing()) return;

    const updated = new Set(this.selectedAssetSetInternal$.value);

    for (const asset of assetToSelect) {
      updated.add(asset.name);
    }

    for (const asset of assetsToUnselect) {
      updated.delete(asset.name);
    }

    this.selectedAssetSetInternal$.next(updated);
  }

  /** Creates a new cutdown and opens metadata editor for it. */
  async createCutdown(asset: Original, startOffset: number, endOffset: number) {
    // Preserve 3 digits after decimal point.
    startOffset = Math.round(startOffset * 1000) / 1000;
    endOffset = Math.round(endOffset * 1000) / 1000;

    const cutdowns = await firstValueFrom(this.assetService.listCopies(asset));
    let suggestedTitle = asset.title;

    if (!isErrorResponse(cutdowns)) {
      // Generate new unique title. E.g. "Parent asset's title (2)";
      let counter = 1;
      while (cutdowns.some(
          c => c.metadata.jsonMetadata[MetadataField.TITLE] ===
              suggestedTitle)) {
        suggestedTitle = `${asset.title} (${counter++})`;
      }
    }

    const cutdown: AssetCopy = {
      name: NEW_ASSET_COPY_NAME,
      isFullCopy: false,
      startOffset,
      endOffset,
      fileName: this.generateCutDownFileName(asset, startOffset, endOffset),
      metadata: new Metadata({
        schema: CUT_DOWN_SCHEMA_NAME,
        jsonMetadata: {[MetadataField.TITLE]: suggestedTitle}
      }),
      state: 'STATE_UNSPECIFIED',
    };
    this.newCutdown$.next({cutdown, parent: asset});

    await this.setActive({
      cutdownParent: asset,
      cutdowns: [cutdown],
      startEditing: true,
    });
  }

  /** Checks if the asset can be approved. */
  canApprove(asset: Original) {
    const pendingApproval = this.pendingApprovalInternal$.value;
    const assetsApproved = this.assetsApprovedInternal$.value;
    // No schema.
    if (!asset.assetMetadata.schema) return false;
    // Asset is in the errored state.
    if (asset.hasError) return false;
    // Already being approved.
    if (pendingApproval.has(asset.name)) return false;

    // Already approved.
    if (asset.approved || assetsApproved.some(a => a.name === asset.name)) {
      return false;
    }

    return true;
  }

  async approve(assets: Original[]) {
    // Do not approve assets while metadata editing is in progress.
    if (this.metadataService.isEditing()) return;

    const approvableAssets = assets.filter(asset => this.canApprove(asset));

    // Show missing schema warnings.
    const someAssetHasNoSchemaOrInErrorState =
        assets.length > approvableAssets.length &&
        assets.some(a => !a.assetMetadata.schema || a.hasError);

    if (!approvableAssets.length) {
      if (someAssetHasNoSchemaOrInErrorState) {
        this.snackBar.error(
            `Assets without a schema or in error state cannot be approved.`);
      } else {
        this.snackBar.error(`Please select not approved assets`);
      }
      return;
    }

    const confirmed = await firstValueFrom(this.dialogService.showConfirmation({
      title: `Approve Asset${approvableAssets.length > 1 ? 's' : ''}`,
      question: this.getConfirmationQuestion(
          approvableAssets, assets.length, someAssetHasNoSchemaOrInErrorState),
      primaryButtonText: 'Approve',
    }));

    if (!confirmed) return;

    const approvalRequests = this.featureService.featureOn('enable-access-management') ? approvableAssets.map(asset => {
      const {permissions, permissionsDocumentId} = asset;
      const result = (permissions && permissionsDocumentId) ? structuredClone({asset: {...asset, permissions, permissionsDocumentId}}) : {asset};
      return result;
    }) : approvableAssets.map(asset => ({asset}));

    this.approvalsRequested$.next(approvalRequests);
  }

  async deleteAssets(assets: Original[]) {
    // Do not delete assets while metadata editing is in progress.
    if (this.metadataService.isEditing()) return;
    await this.batchOperationService.deleteAssetsWithConfirmation(assets);
  }

  async purgeAssets(assets: Original[]) {
    // Do not purge assets while metadata editing is in progress.
    if (this.metadataService.isEditing()) return;
    await this.batchOperationService.purgeAssetsWithConfirmation(assets);
  }

  async addClipsToBins(assets: Original[]) {
    // Do not add clips assets while metadata editing is in progress.
    if (this.metadataService.isEditing()) return;
    await this.batchOperationService.addClipsToBinsWithConfirmation(assets);
  }

  async syncMetadata(assets: Original[]) {
    // Do not sync assets while metadata editing is in progress.
    if (this.metadataService.isEditing()) return;
    await this.batchOperationService.syncMetadata(assets);
  }

  async extendAssetsTtl(assets: Original[]) {
    // Do not extend assets TTL while metadata editing is in progress.
    if (this.metadataService.isEditing()) return;
    await this.batchOperationService.extendTtlWithDatePicker(assets);
  }

  navigateToDetailsView(assetName: string) {
    const stagingView = this.activeView$.value;
    const contextType = stagingView === 'live' ?
        'live-staging' :
        (
          stagingView === 'vod' ?
            'vod-staging' :
            undefined
        );
    this.router.navigate(
        ['/asset', assetName],
        {queryParams: {'type': contextType}, queryParamsHandling: 'merge'});
  }

  /**
   * Drops all service state including active, selected and approving assets.
   */
  resetState() {
    // Discard editing changes for now as we don't block tab transitions.
    this.setActive(undefined, /* skipConfirmation */ true);
    this.select([]);
    this.pendingApprovalInternal$.next(new Set());
    this.assetsApprovedInternal$.next([]);
    this.activeView$.next('none');
  }

  /**
   * Any time an asset is passed to `approvalRequested$`, the approval flow will
   * be triggered for that asset.
   */
  private readonly approvalsRequested$ = new Subject<ApprovalRequest[]>();

  /**
   * Flag indicating that setActive call is already in progress. Is used to
   * prevent calling setActive while there is one in progress.
   *
   * Scenario when this is helpful:
   * - Call setActive
   * - setActive calls metadataService.cancel
   * - metadataService.isEditing$ emits false
   * - Subscribers to isEditing$ may call setActive which will be ignored thanks
   * to this flag.
   */
  private setActiveInProgress = false;

  /**
   * Generate unique name for the cut-down file name base on cut-down offsets
   * and parent live asset's file name extracted from well-known metadata
   * properties like 'HiResFilePath'.
   */
  private generateCutDownFileName(
      parent: Original, startOffset: number, endOffset: number) {
    const start = this.formatCutDownOffset(startOffset);
    const end = this.formatCutDownOffset(endOffset);

    const assetFileName = this.getAssetFilename(parent);
    // Get short file name without extension
    const {name} = this.utils.getNameAndExtension(assetFileName);
    return `${name}_${start}_${end}`.replace(/\./g, '_');
  }

  private getAssetFilename(asset: Original) {
    // Go over file name sources ordered by priority. Default to asset name.
    const assetFileName: string =
        [
          asset.gcsLocationUrl,
          ...LIVE_ASSET_LOCATION_METADATA_FIELDS.map(
              field => asset.assetMetadata.jsonMetadata[field]),
        ].find(fileName => !!fileName) ??
        asset.name;

    return assetFileName;
  }

  private approveInternal(requests: ApprovalRequest[]):
      Observable<Map<string, Original|ErrorResponse>> {
    return from(requests).pipe(
        mergeMap(
            request => {
              const approval$ = request.asset.isLive ?
                  this.approveLiveAsset(request.asset) :
                  this.assetApi.updateApprovalState(request.asset, 'APPROVED');

              return approval$.pipe(
                  this.errorService.catchError(),
                  map(result => ({asset: request.asset.name, result})));
            },
            DEFAULT_CONCURRENT_REQUEST_NUMBER),
        reduce(
            (resultMap, {asset, result}) => resultMap.set(asset, result as Original | ErrorResponse),
            new Map<string, Original|ErrorResponse>()),
    );
  }

  private approveLiveAsset(asset: Original) {
    if (this.featureService.featureOff('use-copy-api-cutdown')) {
      return this.assetApi.updateApprovalState(asset, 'APPROVED');
    }

    return this.createDefaultFullCopy(asset).pipe(
        switchMap(copy => this.assetApi.approveCopy(copy)),
        switchMap(() => this.assetApi.refreshAsset(asset)));
  }

  private createDefaultFullCopy(asset: Original): Observable<AssetCopy> {
    const copy: AssetCopy = {
      name: '',
      fileName: '',
      startOffset: 0,
      endOffset: 0,
      isFullCopy: true,
      metadata: asset.assetMetadata,
      state: 'STATE_UNSPECIFIED'
    };

    return this.assetApi.createCopy(asset, copy);
  }

  private getConfirmationQuestion(
      approvableAssets: Original[], totalAssetCount: number,
      showSchemaWarning: boolean) {
    const schemaWarning = showSchemaWarning ?
        `Selected assets with no schema or in error state will not be approved.\n\n` :
        '';

    // Only one asset was selected and it can be approved.
    if (totalAssetCount === 1) {
      const asset = approvableAssets[0];
      const title = this.assetService.getAssetTitle(asset);
      return `Do you want to approve "${title}"?`;
    }

    if (approvableAssets.length !== totalAssetCount) {
      return `${schemaWarning}Do you want to approve ${
          approvableAssets.length} out of ${totalAssetCount} assets?`;
    }

    return `Do you want to approve ${totalAssetCount} assets?`;
  }

  private hookUpApprovalRequestProcessing() {
    this.approvalsRequested$.subscribe(requests => {
      const currentList = this.pendingApprovalInternal$.value;
      const set = new Set([...requests.map(r => r.asset.name), ...Array.from(currentList)]);
      this.pendingApprovalInternal$.next(set);
    });


    this.approvalsRequested$
        .pipe(
          mergeMap(requests => this.featureService.featureOn('enable-access-management')
          ? this.approveAndPopulatePermission(requests)
          : this.approveInternal(requests)),
        )
        .subscribe(resultMap => {

          const pendingApproval = this.pendingApprovalInternal$.value;
          const results = Array.from(resultMap.values());
          const approvedAssets = results.filter(
              (asset): asset is Original => !isErrorResponse(asset));
          const isLive = approvedAssets[0]?.isLive || false;

          // Remove both succeeded and failed assets from pendingApproval.
              for (const assetName of Array.from(resultMap.keys())) {
            pendingApproval.delete(assetName);
          }
          this.pendingApprovalInternal$.next(pendingApproval);

          const approvedCount = approvedAssets.length;
          if (approvedCount) {
            this.assetsApprovedInternal$.next(approvedAssets);
          }

          if (hasAdminRightsMissing(results)) {
            this.snackBar.error('Approval is reserved for administrators.');
            return;
          }

          // Any error case
          if (approvedCount < resultMap.size) {
            const firstError = results.find(
                (result): result is ErrorResponse => isErrorResponse(result));

            const message = approvedCount === 0 ?
                'Approval failed.' :
                'Failed to approve some assets.';

            this.snackBar.error({
              message,
              details: firstError?.message,
            });
            return;
          }

          // Full success case
          const assetLabel =
              `${approvedCount} asset${approvedCount > 1 ? 's' : ''}`;
          if (!isLive) {
            this.snackBar.message(`Successfully approved ${assetLabel}.`);
            return;
          }

          this.snackBar.message(
              `Successfully approved and scheduled ${assetLabel} for cutdown.`);
        });
  }

  approveAndPopulatePermission(requestsCurrentList:ApprovalRequest[]) {
    return this.approveInternal(requestsCurrentList)
    .pipe(
      map((updatedList) => {
        const permissionMap = new Map<string, Original | ErrorResponse>();
        const hasErrors = Array.from(updatedList).some(c =>  c[1] instanceof ErrorResponse);

        if(hasErrors) return updatedList;

        for (const [key, values] of Array.from(updatedList)) {
          const row = requestsCurrentList.find(a => a.asset?.name === key)?.asset;
          const { permissions, permissionsDocumentId} = row ?? {};
          const updatedValue = { ...values, permissions, permissionsDocumentId };

          permissionMap.set(key, updatedValue);
        }

        return permissionMap;
      })
    );
  }

  private watchActiveItemNames(): Observable<Set<string>> {
    return this.activeItemsInternal$.pipe(
        map(activeItems => {
          if (!activeItems) return new Set<string>();

          if (activeItems.assets) {
            return new Set(activeItems.assets.map(a => a.name));
          }

          return new Set(activeItems.cutdowns.map(cutdown => cutdown.name));
        }),
        shareReplay({bufferSize: 1, refCount: false}));
  }

  /** Returns formatted duration, e.g. `1H25M13S` for 01:25:13 */
  private formatCutDownOffset(offsetInSeconds: number) {
    return Duration.fromObject({seconds: offsetInSeconds})
        .toFormat(`h'H'm'M's'S'`);
  }

  private fillLocalUploadAssetsWithUser(asset: Original) {
    const filename = this.utils.lastPart(asset.gcsLocationUrl);
    this.dataService.retrieveIASEventForLocalUpload(filename)
      .pipe(first())
      .subscribe(
        events => {
          if (events.length) {
            asset.source = events[0].username;
          }
        });
  }

  private fillAssetsWithSource(assets: Original[]) {
    for (const asset of assets) {
      const gcsLocationUrl = asset.gcsLocationUrl;
      // expected format 'gs://<path>'
      if (!gcsLocationUrl.startsWith('gs://')) {
        continue;
      }

      const urlParts = gcsLocationUrl.replace('gs://', '').split('/');
      // expected parts '<bucket>/<root folder>/<remain path>'
      if (urlParts.length < 3) {
        continue;
      }

      const rootFolder = urlParts[1].toLowerCase();
      switch (rootFolder) {
        case CLOUD_INGEST_FOLDER:
          asset.source = 'Cloud Ingest';
          break;
        case LOCAL_UPLOADS_FOLDER:
          asset.source = 'Local Upload';
          this.fillLocalUploadAssetsWithUser(asset);
          break;
        case MEDIACACHE_FOLDER:
          // expected parts '<bucket>/mediacache/<site>/<remain path>'
          if (urlParts.length > 3) {
            asset.source = urlParts[2].toUpperCase();
          }
          break;
        default:
          // unexpected root folder - leave source empty
          break;
      }
    }
  }

  private isSearchResultView(): boolean {
    return this.stateService.currentView$.getValue() === HomeView.SEARCH_RESULTS;
  }
}

/** Parameters for live asset list request. */
export interface LiveListRequest {
  query?: string;
  date: DateTime;
}

/** Parameters for VoD asset list request. */
export interface VodListRequest {
  query?: string;
  pageSize: number;
  pageToken?: string;
  sortOptions?: ApiSortByOption[];
}

interface ActiveItemsBase {
  /** Indicates that active items need to be displayed in edit mode */
  startEditing?: boolean;
  /** If set true will not expand a persistent panel. */
  skipPanelExpansion?: boolean;
}

/** List of active assets. */
export interface ActiveAssets extends ActiveItemsBase {
  assets: Original[];
  cutdowns?: undefined;
  cutdownParent?: undefined;
}

/** List of active cutdowns. Cutdowns must belong to the same parent asset. */
export interface ActiveCutdowns extends ActiveItemsBase {
  assets?: undefined;
  cutdowns: AssetCopy[];
  cutdownParent: Original;
}

/** Can be contained active asset or active cutdowns but not both. */
export type ActiveItems = ActiveAssets|ActiveCutdowns;

/** Payload for newCutdown$ event. */
export interface NewCutdown {
  cutdown: AssetCopy;
  parent: Original;
}

/** Payload for cutdownChanged$ event. */
export interface CutdownChanged {
  name: string;
  newValue: AssetCopy|null;
  parent: Original;
}

/** Types of Staging views. */
export type StagingView = 'none'|'live'|'vod';
