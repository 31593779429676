<table mat-table [dataSource]="dataSource$" multiTemplateDataRows *ngIf="dataSource$ | async as dataSource">
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="name-header-cell p-left-32">Name</th>
        <td
            mat-cell
            *matCellDef="let row"
            [ngClass]="{'p-left-32': isSubRow, 'p-left-28': !isSubRow, 'bg-t':isNextTable}"
        >
            <div [ngStyle]="getStyles()" class="name-container" (click)="executeAction('navigate', row.element)">
                <div class="expand-button-container" *ngIf="!isDisplayingBin">
                    <button
                        mat-icon-button
                        (click)="toggleExpansion(row); $event.stopPropagation()"
                        *ngIf="isResource(row.element) && getAssetCount(row.element) > 0"
                    >
                        <mat-icon *ngIf="(row.expanded$ | async) === false">keyboard_arrow_right</mat-icon>
                        <mat-icon *ngIf="(row.expanded$ | async) === true">keyboard_arrow_down</mat-icon>
                    </button>
                </div>
                <div class="name-content text-ellipsis">
                    @if (isResource(row.element) && row.element.type === 'folder') {
                    <mat-icon class="icon-color">folder</mat-icon>
                    } @if (isResource(row.element) && row.element.type === 'clipbin') {
                    <mat-icon class="p-left-8 icon-color" role="img" aria-hidden="true">subscriptions</mat-icon>
                    }
                    <span class="name-text">{{ row.element.name }}</span>
                </div>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="content">
        <th mat-header-cell *matHeaderCellDef class="content-header-cell">Content</th>
        <td mat-cell *matCellDef="let row" [ngClass]="{'bg-t':isNextTable}">
            <div class="text-ellipsis" [ngPlural]="getAssetCount(row.element)">
                <ng-template ngPluralCase="=0">No items</ng-template>
                <ng-template ngPluralCase="=1">1 item</ng-template>
                <ng-template ngPluralCase="other">{{ getAssetCount(row.element) }} items</ng-template>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="lastModified">
        <th mat-header-cell *matHeaderCellDef class="last-modified-header-cell">Last Modified</th>
        <td mat-cell *matCellDef="let row" [ngClass]="{'bg-t':isNextTable}">
            {{ isResource(row.element) ? (row.element.updatedAt | date : 'mediumDate') : '' }}
        </td>
    </ng-container>

    <ng-container matColumnDef="owner">
        <th mat-header-cell *matHeaderCellDef class="owner-header-cell">Owner</th>
        <td mat-cell *matCellDef="let row" [ngClass]="{'bg-t':isNextTable}">
            <div class="owner-container text-ellipsis">
                @if (isResource(row.element) && ((row.element.owner && row.element.owner.length) || (row.element.owner
                && row.element.owner.length))) {
                <span
                    >{{ row.element!.owner || row.element!.owner!.length ? row.element.owner : row.element.owner
                    }}</span
                >
                } @else {
                <mat-icon role="img" aria-hidden="true" class="person-icon">person</mat-icon>
                }
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="menu" class="text-align-r">
        <th mat-header-cell *matHeaderCellDef class="menu-header-cell"><p class="location">Location</p></th>
        <td mat-cell *matCellDef="let row" class="menu-cell" [ngClass]="{'bg-t':isNextTable}">
            @if (isAdmin || (!isShowAll)) {
            <div class="menu-button-container" [ngClass]="{'justify-flex-end':!isDisplayingBin}">
                <mam-breadcrumb *ngIf="isDisplayingBin" [resource]="row.element"></mam-breadcrumb>
                <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </div>
            }
            <mat-menu #menu="matMenu">
                <button
                    mat-menu-item
                    (click)="executeAction('rename', row.element)"
                    mam-firebase-ga="Open rename dialog"
                    [mam-firebase-resource]="isResource(row.element) ? row.element.id : ''"
                >
                    Rename
                </button>
                <button
                    mat-menu-item
                    (click)="executeAction('move', row.element)"
                    mam-firebase-ga="Open move dialog"
                    *ngIf="!isShowAll"
                    [mam-firebase-resource]="isResource(row.element) ? row.element.id : ''"
                >
                    Move
                </button>
                <button
                    mat-menu-item
                    (click)="executeAction('delete', row.element)"
                    class="delete-menu-item"
                    mam-firebase-ga="Select deletion"
                    [mam-firebase-resource]="isResource(row.element) ? row.element.id : ''"
                >
                    Delete
                </button>
            </mat-menu>
        </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length" [ngClass]="{'bg-t':isNextTable}">
            <div [@detailExpand]="(row.expanded$ | async) ? 'expanded' : 'collapsed'" class="expanded-detail-container">
                <div
                    *ngIf="(row.expanded$ | async) && !expandedFolderDataCache[row.id]"
                    class="loading-animation"
                ></div>
                <mam-cbo-list-display
                    *ngIf="(row.expanded$ | async) && expandedFolderDataCache[row.id]"
                    [result]="expandedFolderDataCache[row.id]"
                    [parentId]="row.id"
                    [owner]="owner"
                    [isSubRow]="true"
                    [startAfter]="startAfter"
                    [isAdmin]="isAdmin"
                    [isShowAll]="isShowAll"
                >
                </mam-cbo-list-display>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef="let column">
            <mat-checkbox
                color="primary"
                (change)="$event? toggleAllSelect($event.checked): null"
                [checked]="selectionService.selectAll$()"
            ></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row" [ngClass]="{'bg-t':isNextTable}">

            <mat-checkbox
                color="primary"
                (change)="$event? toggleSelect(row): null"
                [checked]="selectionService.isSelected(row.element) || selectionService.selectAll$()"
            ></mat-checkbox>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" [hidden]="isSubRow || isNextTable"></tr>
    <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="element-row"
        [class.expanded-row]="row.expanded$ | async"
    ></tr>
    <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        [hidden]="(row.expanded$ | async) === false"
        class="detail-row"
    ></tr>
</table>

<table *ngIf="showLoadMoreButton" mat-table [dataSource]="[1]">
    <ng-container matColumnDef="loadMore">
        <td mat-cell *matCellDef="let row" [ngClass]="{'bg-t':isNextTable}">
            @if (resourceContent) {
            <mam-cbo-list-display
                [result]="resourceContent"
                [isSubRow]="isSubRow"
                [owner]="owner"
                [startAfter]="startAfter"
                [offset]="offset"
                [isNextTable]="true"
                [isAdmin]="isAdmin"
                [isShowAll]="isShowAll"
            >
            </mam-cbo-list-display>
            } @else {
            <div class="load-more-container">
                <button mat-raised-button class="load-more-button" (click)="onLoadMore()">Load More</button>
            </div>
            }
        </td>
    </ng-container>
    <tr mat-row *matRowDef="let row; columns: ['loadMore']"></tr>
</table>
