<ng-container
  *ngIf="{
  displayMode: (vodSearchService.displayMode$ | async)!,
  results: results$ | async,
  searchMode: searchMode$ | async
} as tplState"
>

  <ng-container
    *ngIf="
      tplState.results &&
      tplState.searchMode &&
      getSelectionInfo(tplState.results.segments, vodSearchService.selectedSegmentNames) as selectionInfo"
  >
    <!-- List view header -->
    <div *ngIf="isList(tplState.displayMode)" class="search-bar-container">
      <div class="video-actions">
        <!-- Select/De-select all. -->
        <mat-checkbox
          color="primary"
          (change)="selectionInfo.areAll
                            ? select([])
                            : select(selectionInfo.selectableItems)"
          [checked]="selectionInfo.areAll"
          [indeterminate]="selectionInfo.indeterminate"
          [aria-label]="tableUtils.allCheckboxLabel(selectionInfo)"
          [matTooltip]="tableUtils.allCheckboxLabel(selectionInfo)"
          matTooltipPosition="above"
          mam-firebase-ga="Toggle selection for all segments in search results"
        >
        </mat-checkbox>

        <!-- Actions for selected assets. -->
        <ng-container *ngIf="selectionInfo.areAny">
          <!-- Add clips -->
          <button
            mat-icon-button
            (click)="addClipsToBins(selectionInfo.selectedItems)"
            class="add-selected-to-bins main__icon--size"
            aria-label="Add selected assets to clip bins"
            matTooltip="Add selected assets to clip bins"
            matTooltipPosition="above"
            mam-firebase-ga="Add selected assets to clip bins from search results"
          >
            <mat-icon class="material-icons-outlined">add_box </mat-icon>
          </button>

          <!-- Edit assets metadata -->
          <button
            mat-icon-button
            (click)="edit(selectionInfo.selectedItems)"
            class="edit-selected main__icon--size"
            aria-label="Edit selected assets"
            matTooltip="Edit selected assets"
            matTooltipPosition="above"
            mam-firebase-ga="Bulk assets edit in search results"
          >
            <mat-icon class="material-icons-outlined">edit</mat-icon>
          </button>

          <!-- Export to Folder -->
          <button
            mat-icon-button
            class="main__icon--size"
            (click)="exportOriginalAssets(selectionInfo.selectedItems)"
            aria-label="Export selected assets"
            matTooltip="Export selected assets"
            matTooltipPosition="above"
            mam-firebase-ga="Export selected full assets in search results"
          >
            <mat-icon>arrow_outward</mat-icon>
          </button>

          <!-- TTL Extension -->
          <button
            mat-icon-button
            (click)="extendAssetsTtl(selectionInfo.selectedItems)"
            class="extend-ttl-selected main__icon--size"
            aria-label="Extend TTL on selected assets"
            matTooltip="Extend TTL on selected assets"
            matTooltipPosition="above"
            mam-firebase-ga="Extend TTL on selected assets in search results"
          >
            <mat-icon class="material-icons-outlined">more_time</mat-icon>
          </button>

          <div class="bulk-actions-access" *ngIf="isEnabledAccessManagement && authService.isAdmin">
            <button
            mam-firebase-ga="Add users for restriction" id="add-users" (click)="addUsersOnSelected(); $event.stopPropagation()" matTooltip="Add users" mat-icon-button>
                <mat-icon>person_add</mat-icon>
            </button>
            <button
            mam-firebase-ga="Make public" id="make-public" (click)="makePublicSelected(); $event.stopPropagation()" matTooltip="Make public" mat-icon-button>
                <mat-icon>public</mat-icon>
            </button>
          </div>

          <!-- Purge -->
          <button
            mat-icon-button
            (click)="purgeAssets(selectionInfo.selectedItems)"
            class="purge-selected main__icon--size"
            aria-label="Delete on-prem files"
            matTooltip="Delete on-prem files"
            matTooltipPosition="above"
            mam-firebase-ga="Purge search results selection"
          >
            <mat-icon class="material-icons-outlined">folder_delete</mat-icon>
          </button>

          <!-- Delete -->
          <button
            mat-icon-button
            (click)="deleteAssets(selectionInfo.selectedItems)"
            class="delete-selected main__icon--size"
            aria-label="Delete selected assets"
            matTooltip="Delete selected assets"
            matTooltipPosition="above"
            mam-firebase-ga="Delete selected assets from search results"
          >
            <mat-icon class="material-icons-outlined">delete</mat-icon>
          </button>
        </ng-container>
      </div>

      <!-- Paginator for List view -->
      <ng-container *ngTemplateOutlet="paginator; context: {showPageSelector: true}"> </ng-container>
    </div>

    <div class="search-list-view__table--scroll" #searchResultsEl>
      @if (tableData.length) {

        @if (showListView) {
          <!-- ListViews table - feature flag ON -->
          <mam-ui-table
            [cols]="cols"
            [data]="tableData"
            (rowClick)="routerTo($event, tableData)"
            [selectedRows]="vodSearchService.selectedSegmentNames"
            (sortClick)="tableData = onSort($event, tableData)"
            [options]="{
                tableInfo: {
                  name: 'searchListViewTable',
                  id: tableId
                  },
                multi: true,
                mainColIdx: 1,
              }"
            tableType="full-video"
          >
            <!-- Header -->
            <ng-template #headerTpl let-col>

              <ng-container *ngIf="col.headerTpl === 'storageTpl'">
                <mam-multiselect-table-header
                  headerName="Storage"
                  [options]="storageOptions"
                  (toggle)="onTypeFilterChanged($event)"
                >
                </mam-multiselect-table-header>
              </ng-container>
            </ng-template>

            <!-- Cells -->
            <ng-template #cellTpl let-row let-col="col">
              <!-- Checkbox -->
              <ng-container *ngIf="col.headerTpl === 'checkBoxTpl'">
                <mat-checkbox
                  *ngIf="canBeSelected(row)"
                  color="primary"
                  (click)="toggleSelection(row, tplState.results.segments, $event.shiftKey); $event.stopPropagation()"
                  [checked]="isSelected(row)"
                  [aria-label]="tableUtils.checkboxLabel(row, vodSearchService.selectedSegmentNames)"
                >
                </mat-checkbox>
              </ng-container>

              <!-- Title / File Name -->
              <ng-container *ngIf="col.cellTpl === 'nameTpl'">
                <div class="icon-with-text">
                  <mam-asset-info-icon-button
                    [asset]="row.asset"
                    icon="insert_drive_file"
                    [showIconForExternalUsers]="true"
                  >
                  </mam-asset-info-icon-button>
                  <span matTooltip="{{row.asset?.title}}" matTooltipClass="mobile-tooltip" matTooltipPosition="below">
                    {{row.asset?.title}}
                  </span>
                </div>
              </ng-container>

              <!-- Duration -->
              <ng-container *ngIf="col.cellTpl === 'durationTpl'"> {{row.asset.duration | durationOrTime}} </ng-container>

              <!-- Content Type Column -->
              <ng-container *ngIf="col.cellTpl === 'contentTypeTpl'"> {{getFormattedContentType(row.asset)}} </ng-container>

              <!-- Event Time Column -->
              <ng-container *ngIf="col.cellTpl === 'eventTimeTpl'">
                {{row.asset.eventTime | tzdate:'MMM d, y, HH:mm'}}
              </ng-container>

              <!-- Last Modified Column -->
              <ng-container *ngIf="col.cellTpl === 'lastModifiedTpl'">
                {{row.asset.updateTime | tzdate:'MMM d, y, HH:mm'}}
              </ng-container>

              <!-- Permission Column -->
              <ng-container *ngIf="col.cellTpl === 'permissionTpl'">
                <div class="restriction-cell-container">
                  @if (row.permissionsDocumentId) {
                     <mam-access-restriction-icon size="small"></mam-access-restriction-icon>
                     <span class="text-ellipsis mat-mdc-menu-item-text"> Restrict </span>
                   } @else {
                     <mat-icon>public</mat-icon><span class="text-ellipsis"> Public</span>
                   }
                 </div>
              </ng-container>

              <!-- Location/Storage Column -->
              <ng-container *ngIf="col.cellTpl === 'storageTpl'">
                <div class="icon-with-text">

                <mam-asset-location-status
                  *ngIf="tplState.searchMode === SearchMode.VIDEO &&
                 !row.asset.isDeleted"
                  [asset]="row.asset"
                  (assetStorageRef)="storageInfoList($event)"
                  [instantLoad]="true"
                  style="margin-right: 0px"
                >
                </mam-asset-location-status>
                  <span>
                    {{ storageList.get(row.asset.name)?.storageName || 'Loading' }}
                  </span>
                </div>
              </ng-container>

              <!-- Action Column -->
              <ng-container *ngIf="col.cellTpl === 'actionTpl'">
                <mam-asset-extra-actions
                  *ngIf="!row.asset.isDeleted"
                  [asset]="row.asset"
                  [paddedSegment]="row"
                  [assetPermissions]="row.permissions"
                  [documentId]="row.permissionsDocumentId ?? ''"
                  [enableFullMenu]="tplState.searchMode === SearchMode.VIDEO"
                  (updateRestriction)="updateAssetRestriction(row, $event)"
                >
                </mam-asset-extra-actions>
              </ng-container>

            </ng-template>

          </mam-ui-table>

        }
        @else {
          <table
            class="search-result__table--spacing non-expandable-table legacy-table"
            mat-table
            [dataSource]="tplState.results.segments"
            [trackBy]="trackSegment"
          >

            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef></th>
              <!-- Expand checkbox clickable area by also listening to td.click -->
              <td
                  class="search-result__table--spacing-first-column"
                  mat-cell
                  *matCellDef="let segment"
                  (click)="$event.stopPropagation();toggleSelection(segment, tplState.results.segments, $event.shiftKey)">
                <mat-checkbox
                    color="primary"
                    [checked]="isSelected(segment)"
                    [aria-label]="tableUtils.checkboxLabel(segment, vodSearchService.selectedSegmentNames)">
                </mat-checkbox>
              </td>
            </ng-container>

            <!-- Title Column -->
            <ng-container matColumnDef="title">
              <th
                  mat-header-cell
                  *matHeaderCellDef>
                Title
              </th>
              <td
                  mat-cell
                  *matCellDef="let segment"
                  [title]="segment.asset.title"
                  (click)="toggleSelectionOnShift(segment, tplState.results.segments, $event)"
                  class="main-column">
                <div class="icon-with-text">
                  <mam-asset-info-icon-button
                      [asset]="segment.asset"
                      icon="insert_drive_file"
                      [showIconForExternalUsers]="true">
                  </mam-asset-info-icon-button>
                  <span matTooltip="{{segment.asset.title}}"
                        matTooltipClass="mobile-tooltip"
                        matTooltipPosition="below">
                        {{segment.asset.title}}
                  </span>
                </div>
              </td>
            </ng-container>

            <!-- Duration Column -->
            <ng-container matColumnDef="duration">
              <th
                  mat-header-cell
                  *matHeaderCellDef>
                Duration
              </th>
              <td
                  mat-cell
                  *matCellDef="let segment">
                {{segment.asset.duration | durationOrTime}}
              </td>
            </ng-container>

            <!-- Content Type Column -->
            <ng-container matColumnDef="content-type">
              <th
                  mat-header-cell
                  *matHeaderCellDef>
                Content Type
              </th>
              <td
                  mat-cell
                  *matCellDef="let segment">
                {{getFormattedContentType(segment.asset)}}
              </td>
            </ng-container>

            <!-- Event Time Column -->
            <ng-container matColumnDef="event-time">
              <th
                  mat-header-cell
                  *matHeaderCellDef>
                Event Time
              </th>
              <!-- e.g. Monday, June 15, 2015 at 21:03:01 GMT+01:00  -->
              <td mat-cell *matCellDef="let segment"
                  [title]="(segment.asset.eventTime | tzdate:'EEEE, MMMM d, y, HH:mm:ss zzzz') || 'No date available'">
                <!-- e.g. Oct 25, 2021, 14:11-->
                {{segment.asset.eventTime | tzdate:'MMM d, y, HH:mm'}}
              </td>
            </ng-container>

            <!-- Last Modified Column -->
            <ng-container matColumnDef="last-modified">
              <th
                  mat-header-cell
                  *matHeaderCellDef>
                Last Modified
              </th>
              <!-- e.g. Monday, June 15, 2015 at 21:03:01 GMT+01:00  -->
              <td mat-cell *matCellDef="let segment"
                  [title]="(segment.asset.updateTime | tzdate:'EEEE, MMMM d, y, HH:mm:ss zzzz') || 'No date available'">
                <!-- e.g. Oct 25, 2021, 14:11-->
                {{segment.asset.updateTime | tzdate:'MMM d, y, HH:mm'}}
              </td>
            </ng-container>

            <!-- Permission Column -->
            <ng-container *featureFlagOn="'enable-access-management'">
              <ng-container matColumnDef="permission">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                >
                  Permission
                </th>
                <td mat-cell *matCellDef="let segment">
                  <div class="restriction-cell-container">
                    @if (segment.permissionsDocumentId) {
                       <mam-access-restriction-icon size="small"></mam-access-restriction-icon>
                       <span class="mat-mdc-menu-item-text"> Restrict </span>
                     } @else {
                       <mat-icon>public</mat-icon><span> Public</span>
                     }
                   </div>
                </td>
              </ng-container>
            </ng-container>

            <!-- Location Column -->
            <ng-container matColumnDef="location">
              <th
                mat-header-cell
                *matHeaderCellDef>
                  Storage
              </th>
              <td mat-cell *matCellDef="let segment">
                <mam-asset-location-status
                    *ngIf="tplState.searchMode === SearchMode.VIDEO &&
                           !segment.asset.isDeleted"
                    [asset]="segment.asset">
                </mam-asset-location-status>
              </td>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let segment">
                <mam-asset-extra-actions
                    *ngIf="!segment.asset.isDeleted"
                    [asset]="segment.asset"
                    [paddedSegment]="segment"
                    [assetPermissions]="segment.permissions"
                    [documentId]="segment.permissionsDocumentId ?? ''"
                    (updateRestriction)="updateAssetRestriction(segment, $event)"
                    [enableFullMenu]="tplState.searchMode === SearchMode.VIDEO">
                </mam-asset-extra-actions>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

            <tr mat-row
                *matRowDef="let segment; let index = index; columns: displayedColumns;"
                [class.deleted]="segment.asset.isDeleted"
                [routerLink]="['/asset', segment.asset.name]"
                [queryParams]="{'type': 'search', 'initialIndex': vodSearchService.getAbsoluteIndex(index)}"
                queryParamsHandling="merge"
                mam-firebase-ga="Open VoD asset from search result list"
                [mam-firebase-resource]="segment.name"
                [class.selected]="isSelected(segment)">
            </tr>
          </table>
        }
      }
      @else {
        <div class="empty-message">
          No assets available
        </div>
      }
    </div>

        <!-- Paginator Template -->
        <ng-template #paginator let-showPageSelector="showPageSelector">
            <mat-paginator
                class="main__mat-paginator--border"
                [ngClass]="['mat-paginator', (selectionInfo.indeterminate || selectionInfo.areAll) ? 'hide-paginator' : '']"
                [length]="vodSearchService.pagination.totalCount"
                [pageSize]="vodSearchService.pagination.pageSize"
                [pageIndex]="vodSearchService.pagination.pageIndex"
                (page)="onPageChange($event)"
                [pageSizeOptions]="PAGE_SIZE_OPTIONS"
                [hidePageSize]="!showPageSelector"
            ></mat-paginator>
        </ng-template>
    </ng-container>
</ng-container>
