<h1 mat-dialog-title>Move</h1>
<div mat-dialog-content>
  <div class="move-folder__wrapper">
    <h2 class="sub-title">
      <span class="sub-title__text">Current Location:</span>
      <span class="move-folder__folder-icon"><img src="../../images/folder_icon_light.svg" alt="Folder icon"></span>
      <ng-container *ngFor="let folder of data.breadcrumb">
        <mat-icon class="move-folder__separator">chevron_right</mat-icon>
        <span class="move-folder__folder-name">{{folder.name}}</span>
      </ng-container>
      <mat-icon class="move-folder__separator">chevron_right</mat-icon>
      <span class="move-folder__folder-name">{{data.name}}</span>
    </h2>
    <!-- <span class="error" *ngIf="isMaximumLevelError$ | async">You can't move this folder into the selected folder due to maximum depth level.</span> -->
    <div class="move-folder__selected-nested-folder" *ngIf="selectedNestedFolder != null">
      <div class="move-folder__selected-nested-folder__content">
        <div class="icon" (click)="backClicked()">
          <mat-icon>arrow_back</mat-icon>
        </div>
        <div class="folder">
          <span>{{selectedFolder?.name}}</span>
        </div>
      </div>
    </div>
    <hr />
    <div
      [ngClass]="{'move-folder__content--not-nested': !isNestedFolder, 'move-folder__content--nested': isNestedFolder}"
      class="move-folder__content">
      <ng-container *ngIf="isLoading === false; else loadingContent;">
        <ul>
          <ng-container *ngIf="!isNestedFolder">
            <ng-container  *ngTemplateOutlet="renderSelectHome"></ng-container>
          </ng-container>
          <li
            *ngFor="let folder of resources$ | async" data-id={{folder.id}}
            (click)="checkDisabledFolder(folder) ? null : selectFolder(folder)"
            class="move-folder_list"
            [ngClass]="{
              'move-folder__content__selected-folder': (folder.id === data.id) && (selectedFolder$.value && selectedFolder$.value.id === folder.id),
              'move-folder__content__disabled-folder': checkDisabledFolder(folder)
              }">
            <span class="move-folder__folder-icon">
              <img *ngIf="folder.type === 'folder'" src="../../images/folder_icon_light.svg" alt="Folder icon">
              <mat-icon *ngIf="folder.type !== 'folder'" role="img" aria-hidden="true"> subscriptions </mat-icon>
            </span>
            <mat-icon class="move-folder__separator">chevron_right</mat-icon>
            <span class="move-folder__folder-name">{{folder.name}}</span>
            <mat-icon class="move-folder__separator" *ngIf="folder.directChildrenCount! > 0">chevron_right</mat-icon>
          </li>
        </ul>
      </ng-container>
    </div>
    <hr />
    <div class="move-folder__selected-folder">
      <span class="error" *ngIf="isMaximumLevelError$ | async">You can't move this folder into the selected folder due
        to maximum depth level.</span>

      <span class="error" *ngIf="hasClipBinInside$ | async">You can't move this folder into the selected folder because
        it has one or more clip bins.</span>

      <div class="move-folder__selected-folder__content"
        *ngIf="!isMaximumLevelError$.value && !hasClipBinInside$.value && selectedFolder != null">
        <span class="move-folder__selected-folder__text">Selected Location:</span>
        <div class="move-folder__selected-folder__path">
          <span class="move-folder__folder-icon move-folder__move-home">
            <img src="../../images/folder_icon_light.svg" alt="Folder icon">
          </span>
          <ng-container *ngIf="!isNestedFolder || selectedNestedFolder == null; else renderBreadcrumb">
            <mat-icon class="move-folder__separator">chevron_right</mat-icon>
            <span class="move-folder__folder-name">{{selectedFolder.name}}</span>
          </ng-container>
        </div>
      </div>

    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close mam-firebase-ga="Cancel folder move" [mam-firebase-resource]="data.name">
    Cancel
  </button>
  <button mat-button class="primary" color="primary" #primary mam-firebase-ga="Confirm folder move" [mam-firebase-resource]="data.name"
    (click)="moveFolderToSelected()" [disabled]="moveButtonDisableCondition()">
    Move
  </button>
</div>

<ng-template #loadingContent>
  <div class="move-folder__loading-content">
    <ul>
      <li *ngFor="let _ of [].constructor(9)" class="loader">
        <span class="move-folder__folder-icon">
          <img src="../../images/folder_icon_light.svg" alt="Folder icon">
        </span>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #renderBreadcrumb>
  <!-- start of path -->
  <span *ngFor="let folder of selectedNestedFolder?.breadcrumb" class="move-folder__path">
      <mat-icon class="move-folder__separator">chevron_right</mat-icon>
      <span
        class="move-folder__folder-name text-ellipsis"
        [matTooltip]="folder.name"
        matTooltipPosition="below"
      >
        {{folder.name}}
      </span>
  </span>
  <!-- last part of path -->
  <span *ngIf="selectedFolder" class="move-folder__path">
      <mat-icon class="move-folder__separator">chevron_right</mat-icon>
      <span
        class="move-folder__folder-name text-ellipsis"
        [matTooltip]="selectedFolder.name"
        matTooltipPosition="below"
      >
        {{selectedFolder.name}}
      </span>
  </span>
</ng-template>

<ng-template #renderSelectHome>
  <li data-id="0" (click)="selectHome()"
    [ngClass]="{'move-folder__content__selected-folder': selectedFolder$.value && selectedFolder$.value.id === '0'}">
    <span class="move-folder__folder-icon">
      <img src="../../images/folder_icon_light.svg" alt="Folder icon">
    </span>
    <span class="move-folder__folder-name move-folder__move-home">Home</span>
  </li>
</ng-template>
