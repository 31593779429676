import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, ReplaySubject, switchMap, takeUntil } from 'rxjs';

import { StateService } from '../../services/state_service';
import { DisplayMode } from '../../services/vod_search_service';
import { ResourceTypes } from '../clip-bin-section/service/resource-types';
import {
  PaginationInfo,
  Resource,
  ResourceContent,
  ResourceService
} from '../clip-bin-section/service/resource.service';

export interface ContentEvent {
    moved: boolean;
    deleted: boolean;
    id: string;
    name: string;
    title: string;
    retitled: string;
}

@Component({
    selector: 'mam-folder-content',
    templateUrl: './folder-content.component.html',
    styleUrls: ['./folder-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderContentComponent implements OnInit, OnDestroy {
    @Input() isAdmin: boolean = false;
    @Input() userEmail: string = '';

    @Input() folderId!: string;
    @Input() showAllFolders: boolean = false;
    @Input() searchTerm: string = '';
    resourceContent: ResourceContent = {} as ResourceContent;
    hasContent = false;
    resultsLoading = true;
    skeletonItems = Array.from({ length: 6 });
    contentRef$ = new BehaviorSubject<Resource[]>([]);
    private readonly destroyed$ = new ReplaySubject<void>(1);

    constructor(
        private cdr: ChangeDetectorRef,
        private router: Router,
        private route: ActivatedRoute,
        private resourceService: ResourceService,
        private stateService: StateService
    ) {}

    private _displayMode: DisplayMode = DisplayMode.GRID;

    get displayMode() {
        return this._displayMode;
    }

    @Input() set displayMode(mode: DisplayMode) {
        this._displayMode = mode;
        this.cdr.detectChanges();
    }

    get content() {
        return this.contentRef$.getValue();
    }

    ngOnInit(): void {
        this.resultsLoading = true;
        this.cdr.markForCheck();

        combineLatest([this.route.params, this.route.queryParams])
            .pipe(takeUntil(this.destroyed$))
            .subscribe(([params, queryParams]) => {
                this.folderId = params['folderId'];
                this._displayMode = queryParams['viewMode'] || DisplayMode.GRID;
                this.resultsLoading = true;
                this.cdr.markForCheck();
                this.getFolderContent();
            });
    }

    getFolderContent(searchTerm: string = '') {
        const paginationOptions: PaginationInfo = { limit: 12, offset: 0 };
        this.resultsLoading = true;
        if (searchTerm && searchTerm.trim().length > 0) {
            //TODO: tackle searching inside of the current folder:
            paginationOptions.searchTerm = searchTerm;
        } else {
            this.resourceService
                .getResourceChildren(ResourceTypes.FOLDER, this.folderId, paginationOptions)
                .pipe(
                    switchMap(() => this.resourceService.currentContext$),
                    takeUntil(this.destroyed$)
                )
                .subscribe((resourceContent: ResourceContent) => {
                    this.resourceContent = resourceContent;
                    this.hasContent = resourceContent?.parent?.directChildrenCount > 0;
                    this.stateService.currentSelectedResource$.next(resourceContent);
                    this.contentRef$.next(resourceContent.parent.children);
                    this.resultsLoading = false;
                    this.cdr.markForCheck();
                });
        }
    }

    onFolderCrumbClick(folderId?: string) {
        const params = {
            queryParamsHandling: 'merge' as const,
            queryParams: {}
        };

        if (this.displayMode === 'list') {
            params.queryParams = { viewMode: DisplayMode.LIST };
        }
        this.router.navigate(folderId ? [`/folders/${folderId}`] : ['/'], params);
        this.cdr.detectChanges();
    }

    trackBy(index: number, item: Resource) {
        return item.id;
    }

    isClipBin(folder: Resource) {
        return folder.type !== 'folder';
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    public reloadFolderContent(searchTerm: string = ''): void {
        this.resultsLoading = true;
        this.getFolderContent(searchTerm);
    }
}
