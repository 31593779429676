<h2 mat-dialog-title>Delete Folder</h2>
<div mat-dialog-content>

  @if (hasChildrenError$ | async) {
    <div  class="mam-dialog_wrapper">
      <p class="mam-dialog_content__text">The folder cannot be deleted because it contains items inside.</p>
      <p class="mam-dialog_content__text">Please remove the content and try again.</p>
    </div>
  }

  @else {
    <div  class="mam-dialog_wrapper">
      <p class="mam-dialog_content__text">Are you sure you want to delete this folder?</p>
    </div>
  }

</div>

<div mat-dialog-actions>
  <button
    mat-button
    mat-dialog-close
    mam-firebase-ga="Cancel folder deletion"
    [mam-firebase-resource]="data.displayName"
  >
    Cancel
  </button>
  <button
    mat-button
    color="primary"
    [mat-dialog-close]="true"
    (keyup.enter)="dialogRef.close(true)"
    mam-firebase-ga="Confirm folder deletion"
    [mam-firebase-resource]="data.displayName"
    [disabled]="isDeleteDisabled()"
  >
    Delete
  </button>
</div>
