<h1 mat-dialog-title>Move</h1>
<div mat-dialog-content>
    <div class="move-folder__wrapper">
        <h2 class="sub-title">
            <span class="sub-title__text">Current Location:</span>
            <span class="move-folder__folder-icon">
              <img src="../../images/folder_icon_light.svg" alt="Folder icon" />
            </span>
            <ng-container *ngFor="let folder of resource?.breadcrumb">
              <mat-icon class="move-folder__separator">chevron_right</mat-icon>
              <span class="move-folder__folder-name">{{folder.name}}</span>
            </ng-container>
            <mat-icon class="move-folder__separator">chevron_right</mat-icon>
            <span class="move-folder__folder-name" >{{resource?.name}}</span>
        </h2>
        <div class="move-folder__selected-nested-folder" *ngIf="selectedNestedFolder != null">
            <div class="move-folder__selected-nested-folder__content">
                <div class="icon" (click)="backClicked()">
                    <mat-icon>arrow_back</mat-icon>
                </div>
                <div class="folder">
                    <span>{{selectedFolder?.name}}</span>
                </div>
            </div>
        </div>
        <hr />
        <div
            [ngClass]="{'move-folder__content--not-nested': !isNestedFolder, 'move-folder__content--nested': isNestedFolder}"
            class="move-folder__content"
        >
            <ng-container *ngIf="isLoading === false; else loadingContent;">
                <ul>
                    <ng-container *ngIf="!isNestedFolder">
                      <ng-container  *ngTemplateOutlet="renderSelectHome"></ng-container>
                    </ng-container>
                    <li class="move-folder_list"
                        *ngFor="let folder of resources$ | async"
                        data-id="{{folder.id}}"
                        (click)="selectFolder(folder)"
                        [ngClass]="{'move-folder__content__selected-folder': selectedFolder$.value && selectedFolder$.value.id === folder.id}"
                    >
                        <span class="move-folder__folder-icon">
                          <img src="../../images/folder_icon_light.svg" alt="Folder icon" />
                        </span>
                        <mat-icon class="move-folder__separator">chevron_right</mat-icon>
                        <span class="move-folder__folder-name">{{folder.name}}</span>
                        <mat-icon class="move-folder__separator" *ngIf="folder.directChildrenCount! > 0">chevron_right</mat-icon>
                    </li>
                </ul>
            </ng-container>
        </div>
        <hr />
        <div class="move-folder__selected-folder">
            <span class="error" *ngIf="hasFolderInside$ | async"
                >You can't move this clip bin into the selected folder because it already has one or more
                subfolders.</span
            >
            <div
                class="move-folder__selected-folder__content"
                *ngIf="!hasFolderInside$.value && selectedFolder != null"
            >
                <span class="move-folder__selected-folder__text">Selected Location:</span>
                <div class="move-folder__selected-folder__path">
                  <span class="move-folder__folder-icon">
                    <img src="../../images/folder_icon_light.svg" alt="Folder icon" />
                  </span>
                  <ng-container *ngIf="!isNestedFolder || selectedNestedFolder == null; else renderBreadcrumb">
                      <mat-icon class="move-folder__separator">chevron_right</mat-icon>
                      <span class="move-folder__folder-name">{{selectedFolder.name}}</span>
                  </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close mam-firebase-ga="Cancel folder move" [mam-firebase-resource]="resource?.name">
        Cancel
    </button>
    <button
        mat-button
        class="primary"
        color="primary"
        #primary
        mam-firebase-ga="Confirm folder move"
        [mam-firebase-resource]="resource?.name"
        (click)="moveFolderToSelected()"
        [disabled]="moveButtonDisableCondition()"
    >
        Move
    </button>
</div>

<ng-template #loadingContent>
    <div class="move-folder__loading-content">
        <ul>
            <li *ngFor="let _ of [].constructor(9)" class="loader">
                <span class="move-folder__folder-icon">
                    <img src="../../images/folder_icon_light.svg" alt="Folder icon" />
                </span>
            </li>
        </ul>
    </div>
</ng-template>

<ng-template #renderBreadcrumb>
  <!-- start of path -->
  <span *ngFor="let folder of selectedNestedFolder?.breadcrumb" class="move-folder__path">
      <mat-icon class="move-folder__separator">chevron_right</mat-icon>
      <span
        class="move-folder__folder-name text-ellipsis"
        [matTooltip]="folder.name"
        matTooltipPosition="below"
      >
        {{folder.name}}
      </span>
  </span>
  <!-- last part of path -->
  <span *ngIf="selectedFolder" class="move-folder__path">
      <mat-icon class="move-folder__separator">chevron_right</mat-icon>
      <span
        class="move-folder__folder-name text-ellipsis"
        [matTooltip]="selectedFolder.name"
        matTooltipPosition="below"
      >
        {{selectedFolder.name}}
      </span>
  </span>
</ng-template>

<ng-template #renderSelectHome>
  <li data-id="0" (click)="selectHome()"
    [ngClass]="{
      'move-folder__content__selected-folder': selectedFolder$.value && selectedFolder$.value.id === '0',
      'move-folder__content__home-item': true
      }">
    <span class="move-folder__folder-icon">
      <mat-icon role="img" aria-hidden="true"> subscriptions </mat-icon>
    </span>
    <span class="move-folder__folder-name move-folder__separator move-folder__v-align">Home</span>
  </li>
</ng-template>
