import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  standalone: true,
  selector: 'mam-admin-metrics-chart',
  templateUrl: './admin_metrics_chart.ng.html',
  styleUrls: ['./admin_metrics_chart.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [BaseChartDirective],
})
export class AdminMetricsChart implements OnInit {

  @Input() data: {y: string, x: number}[] = [];
  barChartData = { datasets: [{ data: [ {} ] }] };

  barChartOptions: ChartOptions = {
    indexAxis: 'y',
    responsive: true,
    scales: {
      y: {
        ticks: {
          color: 'white',
        },
        grid: {
          color: 'rgb(48 49 51)',
        },
      },
      x: {
        ticks: {
          color: 'white',
        },
        grid: {
          color: 'rgb(48 49 51)',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      }
    }
  };

  @ViewChild(BaseChartDirective) private chart?: BaseChartDirective;

  constructor(
    private readonly cdr: ChangeDetectorRef,
  ){}

  ngOnInit(): void {
    this.barChartData.datasets[0].data = this.data;
    this.chart?.chart?.update();
    this.cdr.markForCheck();
  }
}
