import {Injectable} from '@angular/core';
import { Sort } from '@angular/material/sort';
import {merge, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, shareReplay, throttleTime} from 'rxjs/operators';

import { ApiSortByOption } from '../api/ias/model/api-sort-by-option';
import { assertTruthy, assumeExhaustiveAllowing } from '../asserts/asserts';
import {FeatureFlagService} from '../feature_flag/feature_flag_service';
import { VcmsField } from '../query_expressions/vcms_query_expressions';
import { MultiSelectOption, MultiSelectOptions } from '../transfer_monitor/multiselect_table_header';

import {ResizeObserverService} from './resize_observer_service';


/**
 * Provides helper methods for mam tables, like the ones dealing with row
 * selections.
 */
@Injectable({providedIn: 'root'})
export class TableUtils {
  constructor(
      private readonly resizeObserver: ResizeObserverService,
      private readonly featureService: FeatureFlagService,
  ) {}
  /** Provides information about currently selected assets. */
  getSelectionInfo<T extends ItemWithName>(
      items: T[], selectedNames: Set<string>,
      canBeSelected: (item: T) => boolean = () => true): SelectionInfo<T> {
    items = items.filter(v => canBeSelected(v));
    const selected = items.filter(a => selectedNames.has(a.name));

    return {
      selectedItems: selected,
      selectableItems: items,
      areAll: selected.length === items.length && items.length > 0,
      areAny: selected.length > 0,
      indeterminate: selected.length > 0 && selected.length < items.length,
    };
  }

  /** The label for the checkbox on the passed asset. */
  checkboxLabel(
      item: ItemWithName, selectedNames: Set<string>, itemLabel = 'asset') {
    const isSelected = this.isSelected(item, selectedNames);
    return `${isSelected ? 'Deselect' : 'Select'} ${itemLabel}`;
  }

  /** The label for the checkbox on the passed asset. */
  allCheckboxLabel(selection: SelectionInfo<ItemWithName>) {
    return `${selection.areAll ? 'Deselect' : 'Select'} all`;
  }

  /**
   * Track by helper for ngFor directive over api-related objects (e.g `Asset`).
   */
  trackByName(index: number, item: ItemWithName) {
    return item.name;
  }

  isSelected(item: ItemWithName, selectedNames: Set<string>) {
    return selectedNames.has(item.name);
  }

  /**
   * Calculates what items should be selected / unselected based on the user
   * input.
   */
  processMultiSelect<T extends ItemWithName>(config: MultiSelectConfig<T>):
      MultiSelectResult<T> {
    const {items, selectedNames, shiftPressed, target} = config;
    const canBeSelected = config.canBeSelected ?? (() => true);
    let anchorName = config.anchorName ?? '';

    // Ignore click / shift+click on non-selectable item.
    if (!canBeSelected(target)) {
      return {anchorName, itemsToSelect: [], itemsToUnSelect: []};
    }

    const isTargetSelected = selectedNames.has(target.name);

    // When shift is not pressed, just toggle the target item.
    if (!shiftPressed) {
      return {
        // Always move the anchor to the target element.
        anchorName: target.name,
        itemsToSelect: isTargetSelected ? [] : [target],
        itemsToUnSelect: isTargetSelected ? [target] : [],
      };
    }

    let targetIndex = items.findIndex(a => a.name === target.name);
    let anchorIndex = items.findIndex(a => a.name === anchorName);

    // Ignore anchor item if it cannot be selected.
    if (anchorIndex >= 0 && !canBeSelected(items[anchorIndex])) {
      anchorIndex = -1;
    }

    // If no anchor provided or anchor item is no longer selectable find new
    // anchor item.
    if (anchorIndex < 0) {
      // Find closest preceding item with selection mode matching target's.
      let i = targetIndex;
      while (--i >= 0) {
        if (selectedNames.has(items[i].name) !== isTargetSelected) {
          anchorIndex = i;
          break;
        }
      }
      // Take first item if the are no selected items above target.
      if (anchorIndex < 0) {
        anchorIndex = 0;
      }
      anchorName = items[anchorIndex].name;
    }

    if (this.featureService.featureOn('calculate-anchor-for-shift-selection')) {
      [anchorIndex, targetIndex] =
          this.calculateAnchorTargetIndexes(items, selectedNames, targetIndex, canBeSelected);
    }

    const startIndex = Math.min(targetIndex, anchorIndex);
    const endIndex = Math.max(targetIndex, anchorIndex);
    const itemRange =
        items.slice(startIndex, endIndex + 1).filter(v => canBeSelected(v));

    // Based on the target's selection mode treat itemRange as items to select
    // or items to un-select
    const [itemsToSelect, itemsToUnSelect] =
        isTargetSelected ? [[], itemRange] : [itemRange, []];

    // Always move the anchor to the target element.
    anchorName = target.name;

    return {itemsToSelect, itemsToUnSelect, anchorName};
  }

  private calculateAnchorTargetIndexes<T extends ItemWithName>(
      items: T[],
      selectedNames: Set<string>,
      targetIndex: number,
      canBeSelected: (item: T) => boolean,
  ): number[] {
    const isTargetSelected = selectedNames.has(items[targetIndex].name);
    if (!isTargetSelected) {
      for (let prev = targetIndex, next = targetIndex; prev >= 0 || next < items.length; prev--, next++) {
        if (prev >= 0 && selectedNames.has(items[prev].name) && canBeSelected(items[prev])) {
          return [prev, targetIndex];
        }
        if (next < items.length && selectedNames.has(items[next].name) && canBeSelected(items[next])) {
          return [next, targetIndex];
        }
      }
      return [targetIndex, targetIndex];
    }

    for (let next = targetIndex; next < items.length - 1; next++) {
      if (!selectedNames.has(items[next + 1].name) && canBeSelected(items[next + 1])) {
        return [next + 1, targetIndex + 1];
      }
    }
    return [items.length, targetIndex + 1];
  }

  /**
   * Based on provided element and breakpoint array emits a single breakpoint
   * with the biggest `minWidth` that is less or equal than the element's
   * current width.
   */
  observeWidth<Name extends string, Column extends string>(
      element: HTMLElement, breakpoints: Array<TableBreakpoint<Name, Column>>,
      disableDebounce = false): Observable<{name: Name; columns?: Column[]}> {
    const defaultBreakpoint = breakpoints.find(b => b.minWidth === 0);
    assertTruthy(
        defaultBreakpoint, 'Provide default breakpoint with minWidth === 0');

    // Sort from higher minWidth to lower min width.
    const sortedBreakpoints =
        breakpoints.slice().sort((b1, b2) => b2.minWidth - b1.minWidth);

    // Compute what columns should be shown when each breakpoint is
    // activated. Each breakpoint includes columns from previous
    // breakpoints.
    const columnMap = new Map<Name, Column[]>();
    let columns: Column[] = [];
    for (let i = sortedBreakpoints.length - 1; i >= 0; i--) {
      columns = columns.concat(sortedBreakpoints[i].add ?? []);
      columnMap.set(sortedBreakpoints[i].name, columns);
    }

    const observe$ = this.resizeObserver.observe(element).pipe(
        map(({width: tableWidth}) => {
          for (const breakpoint of sortedBreakpoints) {
            if (tableWidth >= breakpoint.minWidth) {
              return {
                name: breakpoint.name,
                columns: columnMap.get(breakpoint.name)
              };
            }
          }

          // This is unreachable, present for type consistency.
          return defaultBreakpoint;
        }),
        shareReplay({bufferSize: 1, refCount: true}));

    const distinct = () =>
        distinctUntilChanged<{name: Name}>((b1, b2) => b1.name === b2.name);

    if (disableDebounce) return observe$.pipe(distinct());

    // Use a combination of debounce and throttle to let this observable emit
    // immediately once every second and otherwise emit with 200ms debounce.
    // Immediate emission is useful to process single events like side panel
    // expand/collapse. Debounce is useful when resizing the side panel manually
    // to avoid emissions before resizing is done. During manual resize
    // immediate emission has a high chance to be consumed by "distinct"
    // constraint.
    return merge(
               observe$.pipe(debounceTime(200)),
               observe$.pipe(throttleTime(1000, undefined, {leading: true})),
               )
        .pipe(distinct());
  }

  /**
   * Sort Columns by field (client side data only)
   *
   * @param rows // table data
   * @param key // field key (usually the same as the column name)
   * @param directionChanged // Sort direction changed
   */
  sortByField<T>(rows: unknown[] = [], key: string, directionChanged: boolean) {
    const value = directionChanged ? 1 : -1;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return [...rows].sort((a: any, b: any) => a[key] > b[key] ? value : -value) as T[];
  }

  buildVoDSortOptions<T>(activeSort: Sort): ApiSortByOption[] | undefined {
    let field: VcmsField;
    switch (activeSort.active) {
      case 'date':
        field = 'AssetCreateTime';
        break;
      case 'title':
        field = 'title';
        break;
      default:
        assumeExhaustiveAllowing<T | string>(
          activeSort.active);
        return undefined;
    }

    return [{
      fieldKey: field,
      sortDecreasing: activeSort.direction === 'desc',
    }];
  }

  onMultiSelectOptionUpdate<T>( options: MultiSelectOptions<T>,selectedOption : MultiSelectOption['value'] = undefined) {
    return options.map(opt => {
      opt.selected = opt.value === selectedOption;
      return opt;
    });
  }
}

/** Describes properties of the table width breakpoint */
export interface TableBreakpoint<Name = string, Column = string> {
  /** Breakpoint name. */
  name: Name;
  /** Minimal table width at which the breakpoint is considered active. */
  minWidth: number;
  /** Additional columns that should appear when breakpoint is active. */
  add?: Column[];
}

/** Represents a single item in array serving as table data source. */
export interface ItemWithName {
  name: string;
}

/** Provides details about current table selection */
export interface SelectionInfo<T extends ItemWithName> {
  /** Indicates whether all items are selected. */
  areAll: boolean;
  /** Indicates whether any items are selected. */
  areAny: boolean;
  /** Indicates whether some but not all items are selected. */
  indeterminate: boolean;
  /** Contains selected items. */
  selectedItems: T[];
  /** All items that can be selected (including already selected ones). */
  selectableItems: T[];
}

interface MultiSelectConfig<T extends ItemWithName> {
  /** All items on the page */
  items: T[];
  /** Item that was clicked by the user */
  target: T;
  /** Names of selected items. */
  selectedNames: Set<string>;
  /** Indicates whether shift key is pressed */
  shiftPressed: boolean;
  /**
   * Item to form selection to from the target. Usually the most recently
   * selected item without using shift key.
   */
  anchorName?: string;
  /** Function that tells whether the current item can be selected. */
  canBeSelected?: (item: T) => boolean;
}

interface MultiSelectResult<T extends ItemWithName> {
  /** Items that need to be selected. */
  itemsToSelect: T[];
  /** Items that need to be un-selected. */
  itemsToUnSelect: T[];
  /** New base item for the following multi-select operations. */
  anchorName: string;
}
