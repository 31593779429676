import {ChangeDetectionStrategy, Component, Input, OnDestroy} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {BehaviorSubject, EMPTY, from, NEVER, Observable, of, ReplaySubject} from 'rxjs';
import {concatMap, expand, filter, finalize, map, mergeMap, reduce, switchMap, takeUntil, tap, toArray, withLatestFrom} from 'rxjs/operators';

import {checkExhaustive} from 'asserts/asserts';

import {AccessManagementService} from '../access_management/services/access_management.service';
import {AuthService} from '../auth/auth_service';
import {ErrorResponse, isErrorResponse, mapOnError} from '../error_service/error_response';
import {ErrorService} from '../error_service/error_service';
import {StatusCode} from '../error_service/status_code';
import {FeatureFlagService} from '../feature_flag/feature_flag_service';
import {AnalyticsEventType, FirebaseAnalyticsService} from '../firebase/firebase_analytics_service';
import {FirebaseFirestoreDataService} from '../firebase/firebase_firestore_data_service';
import {FirestoreIASEventHelper} from '../firebase/firebase_firestore_ias_event_helper';
import {PluginService} from '../plugin/plugin_service';
import {HostOtherAction} from '../plugin/plugin_types';
import {AssetService, Clip} from '../services/asset_service';
import {Bin} from '../services/bin.service';
import {DialogService} from '../services/dialog_service';
import {FileState, ImportInput, MediaCacheService} from '../services/media_cache_service';
import {Path} from '../services/media_cache_state_service';
import {SNACKBAR_LONG_DURATION, SnackBarService} from '../services/snackbar_service';
import {DEFAULT_CONCURRENT_REQUEST_NUMBER, UtilsService} from '../services/utils_service';
import {ExportAssetDialog, ExportAssetDialogInputData, ExportAssetDialogOutputData} from '../shared/export_asset_dialog';
import {ExportCompReelDialog} from '../shared/export_comp_reel_dialog';
import {GetLinkForBinDialog} from '../shared/get_link_for_bin_dialog';

import {MoveClipDialog, MoveClipDialogInputData} from './move_clip_dialog';

/**
 * Batch actions and their label name to be displayed as
 * "{{LABEL}} in progress..."
 */
enum BulkActionType {
    SHARE = 'Share Clip bin',
    COMP_REEL = 'Comp-reel export',
    COPY = 'Batch copy',
    MOVE = 'Batch move',
    DOWNLOAD_ALL = 'Batch download',
    EXPORT = 'Batch export',
    IMPORT_ALL = 'Batch import'
}

interface BulkAction {
    type: BulkActionType;
    getLabel: () => string;
    icon: string;
    tooltip: string;
    hidden?: boolean;
    className?: string;
    helperText?: string;
}

/** Combines a clip and its MediaCache file state. */
interface ClipAndState {
    clip: Clip;
    fileState: FileState | ErrorResponse;
}

/** Page size for the searchClips recursive calls. */
export const LIST_ALL_CLIPS_PAGE_SIZE = 50;

/**
 * Is at least this number of clips are to be downloaded or imported, the
 * user will be asked to confirm if that is their intention.
 */
const CLIPS_COUNT_REQUIRE_CONFIRMATION = 10;

/**
 * Upper limit of how many clips a bulk action can process. If there are more
 * clips than that, the rest will be ignored and require manual action.
 */
export const MAX_CLIPS_COUNT_FOR_BULK = 400;

/**
 * Timeout for the `CHECK_ASSETS` request to the plugin. `CHECK_ASSETS`
 * performance depends on the number of clips present in the premiere bin.
 */
const CHECK_ASSETS_TIMEOUT_MS = 5 * 60_000;

/**
 * Clips bulk action selector and trigger.
 */
@Component({
    selector: 'mam-bulk-clips-actions',
    templateUrl: './bulk_clips_actions.ng.html',
    styleUrls: ['./bulk_clips_actions.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkClipsActions implements OnDestroy {
    @Input() clipbin!: Bin;

    /** Whether there are any clips with audio track changes. */
    @Input() hasAudioChanges: boolean = false;

    /**
     * Clips currently loaded in the ClipBinSelection host component, which may
     * not be the complete list of all clips of the current clipbin.
     */
    @Input() visibleClips: Clip[] = [];

    /** List of clip names selected in the host ClipBinSelection component. */
    @Input() multiSelection = new Set<string>();

    @Input() restrictedClipsCount: number = 0;
    @Input() allClipsCount: number = 0;

    /** List of all enabled bulk-actions. */
    protected readonly bulkActions: BulkAction[] = this.getBulkActions();

    /** Disables action selector while one is in progress. */
    protected readonly actionInProgress$ = new BehaviorSubject<BulkActionType | undefined>(undefined);

    constructor(
        private readonly featureFlag: FeatureFlagService,
        private readonly dialog: MatDialog,
        private readonly authService: AuthService,
        private readonly mediaCache: MediaCacheService,
        private readonly snackBar: SnackBarService,
        private readonly dialogService: DialogService,
        private readonly assetService: AssetService,
        private readonly analyticsService: FirebaseAnalyticsService,
        private readonly pluginService: PluginService,
        private readonly utils: UtilsService,
        private readonly errorService: ErrorService,
        private readonly dataService: FirebaseFirestoreDataService,
        private readonly iasEventHelper: FirestoreIASEventHelper,
        private readonly accessManagementService: AccessManagementService
    ) {}

    getTooltip(action: BulkAction) {
        const disabledMessage = this.getDisabledMessage(action);
        if (disabledMessage) return disabledMessage;

        return action.tooltip;
    }

    execute(action: BulkAction) {
        switch (action.type) {
            case BulkActionType.COMP_REEL:
                this.openExportCompReelDialog();
                break;

            case BulkActionType.SHARE:
                this.openShareClipBinDialog();
                break;

            case BulkActionType.COPY:
                this.openClipMoveDialog(BulkActionType.COPY);
                break;

            case BulkActionType.MOVE:
                this.openClipMoveDialog(BulkActionType.MOVE);
                break;

            case BulkActionType.DOWNLOAD_ALL:
                this.downloadClips();
                break;

            case BulkActionType.EXPORT:
                this.openExportDialog();
                break;

            case BulkActionType.IMPORT_ALL:
                this.importClips();
                break;

            default:
                checkExhaustive(action.type);
        }
    }

    /**
     * Returns an explanation message if the action is not available, or
     * `undefined` if it is available.
     */
    getDisabledMessage(action: BulkAction): string | undefined {
        if (!this.clipbin) return 'No clipbin selected';
        if (this.clipbin.assetCount === '0') return 'The clipbin has no clips';

        if (action.type === BulkActionType.COMP_REEL) {
            if (this.multiSelection.size) {
                return 'Move or copy these clips to a new clip bin first, then create a Comp Reel from it.';
            }

            if (this.clipbin.assetCount === '1') {
                return 'A comp-reel requires at least 2 clips.';
            }
            // TODO: applying getLiveClipCount when the search query for
            // gcsObject=null is done.
            if (this.visibleClips.some((clip) => clip.isLive)) {
                return 'Live clips are not supported.';
            }

            if(!this.hasBulkAccess()) {
              return 'Action disabled due to restricted content.';
            }
        }

        if (action.type === BulkActionType.IMPORT_ALL) {
          if(!this.pluginService.isVersionAtLeast('3.3.1')) {
            return 'Only available starting from IAS Premiere Pro extension v3.3.1';
          }

          if(!this.hasBulkAccess()) {
            return 'Action disabled due to restricted content.';
          }
        }

        if (action.type === BulkActionType.SHARE) {
          if(this.clipbin.assetCount === '0' || !this.clipbin.assetCount) {
            return 'No clips to share';
          }

          if(this.visibleClips.some(c => c.permissions) || this.restrictedClipsCount > 0) {
            return "Action disabled due to restricted content.";
          }
        }

        if(action.type === BulkActionType.DOWNLOAD_ALL && !this.hasBulkAccess()) {
            return 'Action disabled due to restricted content.';
        }

        if(action.type === BulkActionType.EXPORT && !this.hasBulkAccess()) {
            return 'Action disabled due to restricted content.';
        }


        return undefined;
    }

    ngOnDestroy() {
        // Unsubscribes all pending subscriptions.
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    private readonly destroyed$ = new ReplaySubject<void>(1);

    private openShareClipBinDialog() {
        this.dialog.open(
            GetLinkForBinDialog,
            GetLinkForBinDialog.getDialogOptions({
                bin: { ...this.clipbin, clips: this.visibleClips },
                additionalProperties: {}
            })
        );
    }

    private openExportCompReelDialog() {
        this.dialog.open(ExportCompReelDialog, {
            ...ExportCompReelDialog.DIALOG_OPTIONS,
            data: {
                binName: this.clipbin.name,
                binTitle: this.clipbin.title
            }
        });
    }

    private openClipMoveDialog(actionType: BulkActionType) {
        const action = actionType === BulkActionType.COPY ? 'copy' : 'move';
        this.logBulkAction(BulkActionType.EXPORT);

        // Disable other bulk actions while this one is running.
        this.actionInProgress$.next(actionType);

        this.getClips()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((clips) => {
                this.dialog
                    .open<MoveClipDialog, MoveClipDialogInputData>(
                        MoveClipDialog,
                        MoveClipDialog.getDialogOptions({ clips, action })
                    )
                    .afterClosed()
                    .subscribe((onDone$) => {
                        (onDone$ || of(undefined)).subscribe(() => {
                            this.actionInProgress$.next(undefined);
                        });
                    });
            });
    }

    private openExportDialog() {
        this.logBulkAction(BulkActionType.EXPORT);

        // Disable other bulk actions while this one is running.
        this.actionInProgress$.next(BulkActionType.EXPORT);

        this.getClips()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((clips) => {
                this.dialog
                    .open<ExportAssetDialog, ExportAssetDialogInputData, ExportAssetDialogOutputData>(
                        ExportAssetDialog,
                        ExportAssetDialog.getDialogOptions({ assets: clips })
                    )
                    .afterClosed()
                    .subscribe((onExportDone$) => {
                        (onExportDone$ || of(undefined)).subscribe(() => {
                            this.actionInProgress$.next(undefined);
                        });
                    });
            });
    }

    /**
     * Initiates the download of the selected (or all) clips that can be
     * downloaded to the current site. If there are more than 10, the user is
     * asked to confirm the action.
     */
    private downloadClips() {
        if (!this.clipbin) return;

        this.logBulkAction(BulkActionType.DOWNLOAD_ALL);

        // Disable other bulk actions while this one is running.
        this.actionInProgress$.next(BulkActionType.DOWNLOAD_ALL);

        // Start from all clips of the current clipbin.
        const allClipsAndStates$ = this.getClipsAndStates();

        // Keep only VoD clips with a "Cloud Only" state.
        const downloadableClips$: Observable<Clip[]> = allClipsAndStates$.pipe(
            map((clipAndStates) => {
                return clipAndStates
                    .filter(({ clip, fileState }) => {
                        if (isErrorResponse(fileState)) return false;
                        if (clip.isLive) return false;
                        return fileState === FileState.FILE_CLOUD_ONLY;
                    })
                    .map(({ clip }) => clip);
            })
        );

        // If there are many clips, ask the user to confirm this action, otherwise
        // just do it.
        const downloadableClipsConfirmed$: Observable<Clip[]> = downloadableClips$.pipe(
            withLatestFrom(this.mediaCache.state.filePath$),
            switchMap(([clips, path]) => {
                if (clips.length < CLIPS_COUNT_REQUIRE_CONFIRMATION) {
                    return of(clips);
                }

                const siteName = path.siteId.toUpperCase();
                let question = `Download ${clips.length} clips to ${siteName}?`;

                if (clips.length === MAX_CLIPS_COUNT_FOR_BULK) {
                    question += '\nThis is the maximum number of clips supported by this operation.';
                }

                return this.dialogService
                    .showConfirmation({
                        title: 'Download Clips',
                        question,
                        primaryButtonText: 'Proceed'
                    })
                    .pipe(
                        switchMap((confirmed) => {
                            // If the action is cancelled, abort the flow here. We do
                            // not return `EMPTY` as that would trigger the initial
                            // `reduce` iteration from the step below and eventually
                            // print "No clip is ready for download".
                            if (!confirmed) {
                                this.actionInProgress$.next(undefined);
                                return NEVER;
                            }

                            return of(clips);
                        })
                    );
            })
        );

        // Make the individual download calls and show success/error status.
        downloadableClipsConfirmed$
            .pipe(
                // From one emission of an array to one emission per item.
                concatMap((clips) => clips),
                withLatestFrom(this.mediaCache.state.filePath$),
                // Trigger 'downloadAsset' for each clip.
                mergeMap(([clip, path]) => {
                    if (isErrorResponse(clip)) return of(clip);
                    const asset$ = this.mediaCache.downloadAsset(clip, path.siteId, path.folderId);

                    if (this.featureFlag.featureOn('store-user-information')) {
                        asset$.subscribe((response) => {
                            this.storeIASEVent(clip.name, path);
                            return response;
                        });
                    }

                    return asset$;
                }, DEFAULT_CONCURRENT_REQUEST_NUMBER),
                // Aggregate responses to a summary count.
                reduce(
                    (acc, downloadResponse) => {
                        let { successCount, errorCount } = acc;
                        // Conflict are considered successful download starts as they
                        // can be caused by importing multiple long clips from the
                        // same assets, in which case due to optimizations, the parent
                        // asset will be imported and return conflicts from other
                        // download requests.
                        if (isErrorResponse(downloadResponse) && downloadResponse.status !== StatusCode.CONFLICT) {
                            errorCount++;
                        } else {
                            successCount++;
                        }
                        return { successCount, errorCount };
                    },
                    { successCount: 0, errorCount: 0 }
                ),
                takeUntil(this.destroyed$),
                finalize(() => {
                    this.actionInProgress$.next(undefined);
                })
            )
            .subscribe(({ errorCount, successCount }) => {
                this.analyticsService.logEvent('Bulk Clips Download', {
                    eventType: AnalyticsEventType.LOG,
                    number1: successCount,
                    number2: errorCount
                });

                if (!errorCount && !successCount) {
                    this.snackBar.message(`No clip is ready for download.`);
                    return;
                }

                if (!successCount) {
                    // e.g. "Failed to download 2 clips."
                    this.snackBar.error(
                        `Failed to download ${errorCount} ${this.utils.pluralize(errorCount, 'clip', 'clips')}.`
                    );
                } else if (errorCount) {
                    // e.g. "Started download of 3 clips. 4 others failed."
                    this.snackBar.error(
                        `Started download of ${successCount} ${this.utils.pluralize(
                            successCount,
                            'clip',
                            'clips'
                        )}. ${errorCount} ${this.utils.pluralize(errorCount, 'other', 'others')} failed.`,
                        undefined,
                        SNACKBAR_LONG_DURATION
                    );
                } else {
                    // e.g. "Started download of 5 clips."
                    this.snackBar.message(
                        `Started download of ${successCount} ${this.utils.pluralize(successCount, 'clip', 'clips')}`
                    );
                }

                // Trigger a manual refresh of all visible status icons so that we can
                // see new ongoing downloads as spinners.
                this.mediaCache.state.manualUpdate$.next(undefined);
            });
    }

    /** Imports to Premiere the selected (or all) clips that are importable. */
    private importClips() {
        if (!this.clipbin) return;

        this.logBulkAction(BulkActionType.IMPORT_ALL);

        // Disable other bulk actions while this one is running.
        this.actionInProgress$.next(BulkActionType.IMPORT_ALL);

        // Start from all clips of the current clipbin.
        const allClipsAndStates$ = this.getClipsAndStates();

        // Keep only those that are importable (have an import type).
        const importableClips$: Observable<ClipAndState[]> = allClipsAndStates$.pipe(
            // From an array of clips to one emission per clip.
            concatMap((clipsAndStates) => clipsAndStates),
            // Determine if a clip is importable by whether `getImportType`
            // returns anything.
            map(({ clip, fileState }) => {
                const importType = this.mediaCache.getImportType(clip, fileState);
                return { clip, fileState, importType };
            }),
            // Filter out non-importable clips.
            filter(({ importType }) => importType != null),
            // Combine all clips emissions back into a single array.
            toArray()
        );

        // Keep only those that are not already in Premiere.
        const clipsNotInPremiere$: Observable<ClipAndState[]> = importableClips$.pipe(
            switchMap((clipsAndStates) => {
                // Map each input to an input for the Premiere payload.
                const payload = clipsAndStates.map((clipAndState) => {
                    return {
                        binTitle: this.clipbin.title,
                        assetUrl: this.mediaCache.getAssetUrl(clipAndState.clip)
                    };
                });
                const timestamp = Date.now();
                return this.pluginService
                    .request(
                        {
                            iasType: HostOtherAction.CHECK_ASSETS,
                            payload
                        },
                        CHECK_ASSETS_TIMEOUT_MS
                    )
                    .pipe(
                        tap((pluginMessage) => {
                            const error = isErrorResponse(pluginMessage) ? pluginMessage : undefined;

                            this.analyticsService.logEvent('Bulk import: Check Assets', {
                                eventType: AnalyticsEventType.PLUGIN_ACTION,
                                duration: Date.now() - timestamp,
                                resource: this.clipbin.name,
                                number1: payload.length,
                                boolean1: !error,
                                string1: error?.message
                            });
                        }),
                        map((pluginMessage) => {
                            if (isErrorResponse(pluginMessage)) {
                                return [];
                            }

                            const clipExists = pluginMessage.payload;
                            // Filter out clips that are already in Premiere.
                            return clipsAndStates.filter((clipAndState, index) => {
                                return !clipExists[index];
                            });
                        })
                    );
            })
        );

        // If there are many clips left, ask the user to confirm this action,
        // otherwise just do it.
        const clipsConfirmed$: Observable<ClipAndState[]> = clipsNotInPremiere$.pipe(
            switchMap((clipsAndStates) => {
                if (clipsAndStates.length < CLIPS_COUNT_REQUIRE_CONFIRMATION) {
                    return of(clipsAndStates);
                }

                const question = `Import ${clipsAndStates.length} ${this.utils.pluralize(
                    clipsAndStates.length,
                    'clip',
                    'clips'
                )} to Premiere Pro?`;

                return this.dialogService
                    .showConfirmation({
                        title: 'Import Clips',
                        question,
                        primaryButtonText: 'Proceed'
                    })
                    .pipe(
                        switchMap((confirmed) => {
                            // If the action is cancelled, abort the flow here. We do
                            // not return `EMPTY` as that would trigger the initial
                            // `reduce` iteration from the step below and eventually
                            // print "No clip is ready to be imported".
                            if (!confirmed) {
                                this.actionInProgress$.next(undefined);
                                return NEVER;
                            }

                            return of(clipsAndStates);
                        })
                    );
            })
        );

        // Collect all information required per clip for it to be imported.
        const importInputs$: Observable<ImportInput[]> = clipsConfirmed$.pipe(
            // From an array of clips to one emission per clip.
            concatMap((clipsAndStates) => clipsAndStates),
            // Fetch and add its `localPath` to each clip.
            mergeMap(({ clip, fileState }) => {
                return this.mediaCache.locateOnPrem(clip, fileState).pipe(
                    map((localPath) => {
                        return {
                            asset: clip,
                            binTitle: this.clipbin.title,
                            fileState,
                            localPath
                        };
                    })
                );
            }, DEFAULT_CONCURRENT_REQUEST_NUMBER),
            // From one emission per input to an array of inputs.
            toArray()
        );

        // Execute the bulk import and display summary response.
        importInputs$
            .pipe(
                // Execute a one-time bulk import.
                switchMap((importInputs) => {
                    if (importInputs.length === 0) {
                        return of(null);
                    }
                    const timestamp = Date.now();
                    return this.mediaCache.importAssets(importInputs).pipe(
                        tap((response) => {
                            const error = isErrorResponse(response) ? response : undefined;
                            this.analyticsService.logEvent('Bulk import: Import Assets', {
                                eventType: AnalyticsEventType.PLUGIN_ACTION,
                                duration: Date.now() - timestamp,
                                resource: this.clipbin.name,
                                number1: importInputs.length,
                                boolean1: !error,
                                object: error ? undefined : JSON.stringify(response)
                            });
                        })
                    );
                }),
                takeUntil(this.destroyed$),
                finalize(() => {
                    this.actionInProgress$.next(undefined);
                })
            )
            .subscribe((totals) => {
                // Trigger a manual refresh of all visible status icons so that we
                // can see new imported clips check icons.
                this.mediaCache.state.manualUpdate$.next(undefined);

                if (isErrorResponse(totals)) {
                    this.snackBar.error({
                        message: `Failed to process bulk import.`,
                        details: totals.message
                    });
                    return;
                }

                if (!totals || (!totals.errorCount && !totals.successCount)) {
                    this.snackBar.message(`No clip is ready to be imported.`);
                    return;
                }

                let message = '';
                if (totals.successCount) {
                    // e.g. "Successfully imported 2 clips."
                    message += `Successfully imported ${totals.successCount} ${this.utils.pluralize(
                        totals.successCount,
                        'clip',
                        'clips'
                    )}. `;
                }
                if (totals.errorCount) {
                    // e.g. "3 clips failed to be imported."
                    message += `${totals.errorCount} ${this.utils.pluralize(
                        totals.errorCount,
                        'clip',
                        'clips'
                    )} failed to be imported. `;
                }
                if (totals.duplicateCount) {
                    // e.g. "4 clips were already present."
                    message += `${totals.duplicateCount} ${this.utils.pluralize(
                        totals.duplicateCount,
                        'clip was',
                        'clips were'
                    )} already present.`;
                }

                if (totals.errorCount) {
                    this.snackBar.error(message);
                } else {
                    this.snackBar.message(message);
                }
            });
    }

    /**
     * Emits selected clips. If no selection is made, emits all clips
     * of the clipbin (up to a maximum).
     * Also, check if there is restriction, if yes, will be remove from list
     */
    private getClips(): Observable<Clip[]> {
        const result$ = !this.multiSelection.size ? this.getAllClips() : this.getSelectedClips(this.multiSelection);

        return result$.pipe(
          toArray(),
          concatMap((clips) => this.accessManagementService.filterRestrictionOnAssetsClips(clips))
        );
    }

    /**
     * Emits selected clips one by one. If no selection is made, emits all clips
     * of the clipbin (up to a maximum).
     * Check if the clip is restrict, if is restrict will be return undefined
     */
    private getClip(): Observable<Clip | undefined> {
        const result$ = !this.multiSelection.size ? this.getAllClips() : this.getSelectedClips(this.multiSelection);

        return result$.pipe(
          concatMap((clip) => this.accessManagementService.filterRestrictionOnAssetClip(clip)),
        );
    }

    /**
     * Fetches the selected (or all up to the configured maximum) clips of the
     * given clipbin along with their file states. Emits all clips along with
     * their file state.
     */
    private getClipsAndStates(): Observable<ClipAndState[]> {
        const clips$ = this.getClip();

        return clips$.pipe(
            concatMap((clip)=> clip ? of(clip) : EMPTY),
            withLatestFrom(this.mediaCache.state.filePath$),
            // Add its file state to each clip.
            mergeMap(([clip, path]) => {
                return this.mediaCache
                    .getFileAndState(path.siteId, path.folderId, clip)
                    .pipe(map((fileState) => ({ clip, fileState: fileState.state })));
            }, DEFAULT_CONCURRENT_REQUEST_NUMBER),
            // Combines individual emissions into one emission of an array.
            toArray()
        );
    }

    /** Fetches all clips of the clipbin (up to a max).  */
    private getAllClips(): Observable<Clip> {
        let count = 0;
        return this.assetService.searchClips(this.clipbin.name, undefined, '', LIST_ALL_CLIPS_PAGE_SIZE).pipe(
            // Expand all pages of clips.
            expand((response) => {
                if (isErrorResponse(response)) return EMPTY;
                if (!response?.nextPageToken) return EMPTY;
                // Stop loading more clips if we reach the upper limit.
                if (count >= MAX_CLIPS_COUNT_FOR_BULK) return EMPTY;
                // Trim pageSize to not load more than our limit.
                const pageSize = Math.min(LIST_ALL_CLIPS_PAGE_SIZE, MAX_CLIPS_COUNT_FOR_BULK - count);
                // Make the next page API call.
                return this.assetService.searchClips(this.clipbin.name, undefined, response.nextPageToken, pageSize);
            }),
            // Extract clips from each page response.
            concatMap((response) => {
                if (isErrorResponse(response)) return [];
                // Local side-effect for failsafe limit.
                count += response.assets.length;
                return response.assets;
            })
        );
    }

    /**
     * Converts selection clip names to Clip resources. If they are all found in
     * the visible list of clips (i.e. the visible list of clips was not reset
     * between the selection and the bulk action), use these directly, otherwise
     * fetch them from the backend.
     */
    private getSelectedClips(selection: Set<string>): Observable<Clip> {
        const selectedVisibleClips = this.visibleClips.filter((clip) => selection.has(clip.name));

        // All selected clips are still visible in the list.
        if (selectedVisibleClips.length === this.multiSelection.size) {
            return from(this.visibleClips.filter((clip) => this.multiSelection.has(clip.name)));
        }

        // The list visible of clips doesn't contain all selected clip names. This
        // should never happen and will log an error for regression check.
        this.errorService.handle(
            `Only ${selectedVisibleClips.length} clips were visible from a selection of ${this.multiSelection.size}`
        );
        return from(selection).pipe(
            mergeMap((clipName) => this.assetService.getClip(clipName), DEFAULT_CONCURRENT_REQUEST_NUMBER),
            mapOnError(() => EMPTY)
        );
    }

    private getBulkActions(): BulkAction[] {
        const bulkActions: BulkAction[] = [
            {
                type: BulkActionType.SHARE,
                getLabel: () => 'Share Clip bin',
                icon: 'share',
                tooltip: 'Share the current clip bin.',
                className: 'share',
                helperText: 'Custom audio track'
            },
            {
                type: BulkActionType.COMP_REEL,
                getLabel: () => 'Export Comp-Reel',
                icon: 'start',
                tooltip: 'Initiates a comp-reel export of all clips combined.',
                className: 'compreel'
            },
            {
                type: BulkActionType.COPY,
                getLabel: () => this.formatAction('Copy'),
                icon: 'file_copy',
                tooltip: 'Copy clips to other clip bins.',
                className: 'copy'
            },
            {
                type: BulkActionType.MOVE,
                getLabel: () => this.formatAction('Move'),
                icon: 'drive_file_move',
                tooltip: 'Move clips to another clip bin.',
                className: 'move'
            }
        ];

        if (this.featureFlag.featureOn('use-bulk-clips-download')) {
            bulkActions.push({
                type: BulkActionType.DOWNLOAD_ALL,
                getLabel: () => this.formatAction('Download', 'to On-Prem'),
                icon: 'cloud_download',
                tooltip: 'Downloads clips that are not already on-prem to the premises.',
                className: 'download'
            });
        }

        if (this.featureFlag.featureOn('use-bulk-clips-export')) {
            bulkActions.push({
                type: BulkActionType.EXPORT,
                getLabel: () => this.formatAction('Export'),
                icon: 'arrow_outward',
                tooltip: 'Exports clips to an export folder.',
                className: 'export'
            });
        }

        if (this.featureFlag.featureOn('use-bulk-clips-import')) {
            bulkActions.push({
                type: BulkActionType.IMPORT_ALL,
                getLabel: () => this.formatAction('Import', 'to Premiere Pro'),
                icon: 'save_alt',
                tooltip: 'Imports clips that are currently on-premises to Premiere Pro.',
                hidden: !this.authService.isPlugin(),
                className: 'import'
            });
        }

        return bulkActions;
    }

    /**
     * Given a prefix such as "Import" and a suffix such as "to Premiere Pro",
     * returns the sentence "Import All CLips to Premiere Pro" when no custom
     * selection is made, or "Import `N` Clips to Premiere Pro" otherwise.
     */
    private formatAction(prefix: string, suffix = '') {
        const count = this.multiSelection.size || 'All';
        const clips = this.utils.pluralize(this.multiSelection.size, 'Clip', 'Clips');
        return `${prefix} ${count} ${clips} ${suffix}`;
    }

    private logBulkAction(bulkActionType: BulkActionType) {
        this.analyticsService.logEvent(bulkActionType, {
            eventType: AnalyticsEventType.LOG,
            number1: this.multiSelection.size,
            string1: this.clipbin.assetCount,
            boolean1: this.multiSelection.size > 0,
            resource: this.clipbin.name
        });
    }

    private storeIASEVent(clipTitle: string, path: Path) {
        this.assetService.getClip(clipTitle).subscribe((clipValue) => {
            if (!clipValue) return;
            const clip = clipValue as Clip;
            const assetTitle = clip.title;
            const iasEvent = this.iasEventHelper.formatTransferIASEvent(clip, assetTitle, path);
            this.dataService.createIASEvent(iasEvent);
        });
    }

  /**
   * Checks if the current user has access to bulk actions.
   * Access is granted if the user is an admin, there are no restricted clips,
   * or the user has permission to access all visible restricted clips.
   *
   * @private
   * @returns  True if the user has bulk access, false otherwise.
   */
      private hasBulkAccess(): boolean {
        // Check if the user is an administrator.  Admins always have bulk access. OR access management is off
        if (this.authService.isAdmin || this.featureFlag.featureOff('enable-access-management')) {
          return true;
        }

        const userLogged = this.authService.getUserEmail();

        // If there are no restricted clips, bulk access is granted.
        if (this.restrictedClipsCount === 0) {
          return true;
        }

        // Check if all visible clips that have permissions associated with them are accessible to the user.
          const canAccessRestrictionClip = this.visibleClips
            .filter(f => f.permissions && 'permissions' in f) // Filter out clips without permissions
            .every(clip => clip.permissions?.some(permission => permission.userId === userLogged)); // Check if every clip has at least one permission that matches the current user

        // If all visible clips are also the number of all clips and the user has access to all restricted clips, bulk access is granted.
        if (this.visibleClips.length === this.allClipsCount && canAccessRestrictionClip) {
          return true;
        }

        // If none of the above conditions are met, the user does not have bulk access.
        return false;
      }
}
