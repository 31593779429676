/* eslint-disable unicorn/prefer-ternary */
import { BreakpointObserver } from '@angular/cdk/layout';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute, Router, UrlSegment, UrlTree } from '@angular/router';
import {
    BehaviorSubject,
    combineLatest,
    debounceTime,
    EMPTY,
    map,
    merge,
    Observable,
    ReplaySubject,
    startWith,
    Subject,
    take,
    takeUntil
} from 'rxjs';

import { AuthService } from '../../auth/auth_service';
import { FeatureFlagService } from '../../feature_flag/feature_flag_service';
import { Asset, Clip } from '../../services/asset_service';
import { Bin, BinSectionContent, BinSectionContentType, BinService } from '../../services/bin.service';
import { ClipbinsOwner } from '../../services/bin_api.service';
import { ClipApiService } from '../../services/clip_api_service';
import { MediaCacheService } from '../../services/media_cache_service';
import { Pagination, PaginationService } from '../../services/pagination_service';
import { PaginatorIntl, UNKNOWN_LENGTH } from '../../services/paginator-intl';
import { SearchType } from '../../services/search_api.service';
import { SearchInputService } from '../../services/search_input_service';
import { DisplayMode } from '../../services/vod_search_service';
import { ClipbinFolderCreationDialog } from '../../shared/clipbin_folder_creation_dialog/clipbin_folder_creation_dialog';
import { CreateBinDialog } from '../../shared/create_bin_dialog';
import { DeleteMultipleBinDialog } from '../../shared/delete_multiple_bin_dialog/delete_multiple_bin_dialog';
import { ScrubbingService } from '../../shared/scrubbing_service';
import { actionOptionErrorMessage } from '../../utils/cbo-actions.utils';
import { FolderContentComponent } from '../folder-content/folder-content.component';
import { BINS_PAGINATION_BREAKPOINTS, Breakpoint, DisplayModePagination } from '../landing-helpers.utils';

import {
    OrganizerEnum,
    OrganizersActionOptions,
    OrganizerTypes,
    ResourceType,
    ResourceTypes
} from './service/resource-types';
import {
    IResourceService,
    PaginationInfo,
    Resource,
    ResourceResult,
    ResourceService,
    SearchOptions
} from './service/resource.service';

const DEFAULT_BINS_PAGE_SIZE = 24;
const SEARCH_DEBOUNCE = 500;
const FIXED_ROW_NUMBER= 4;

export interface FoldersApiResult {
    folders: Resource[];
    paginationData: PaginationApiResult;
}

export interface PaginationApiResult {
    pageSize: number;
    totalItems: number;
    totalPages: number;
}

type pageResult = {
    assets?: Asset[];
    bins?: Bin[];
    nextPageToken?: string;
};

// You can define them in a separate file or in the same component:

export const CUSTOM_BREAKPOINTS = {
  XS_MAX: '(max-width: 360px)',
  SM_MIN: '(min-width: 400px) and (max-width: 719px)',
  MD_MIN: '(min-width: 720px) and (max-width: 959px)',
  LG_MIN: '(min-width: 960px) and (max-width: 1199px)',
  XL_MIN: '(min-width: 1200px) and (max-width: 1599px)',
  XXL_MIN: '(min-width: 1600px)'
};


/**
 * Component for displaying and managing clip bins and folders.
 * Provides search, filtering, pagination, and display mode options.
 */
@Component({
    selector: 'mam-clip-bin-section',
    templateUrl: './clip-bin-section.component.html',
    styleUrl: './clip-bin-section.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: MatPaginatorIntl, useClass: PaginatorIntl }]
})
export class ClipBinSection implements AfterViewInit, OnDestroy {
    @ViewChild('searchInput') searchInput?: ElementRef<HTMLInputElement>;
    @ViewChild('searchField') searchField?: MatFormField;
    @ViewChild('scrollableView') scrollableView!: ElementRef<HTMLElement>;
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild('folderContent', { static: false }) folderContentComponent?: FolderContentComponent;

    /* Pagination */
    /** Pagination object for bins. */
    binsPagination: Pagination<pageResult>;
    /** Pagination object for clips. */
    clipsPagination: Pagination<pageResult>;
    /** Pagination object for folders. */
    foldersPagination = {
        pageLimit: 6,
        nextCursor: null as Resource | null,
        lastCursor: null as Resource | null,
        pageIndex: 0,
        totalCount: 0
    };

    pageIndex$ = new BehaviorSubject(0);

    /* Loading */
    /** Indicates if results are loading. */
    resultsLoading = true;
    /** Observable for loading state based on screen breakpoint. */
    resultsLoading$: Observable<number[]> = this.breakpointObserver.observe(['(max-width: 1300px)']).pipe(
        take(1),
        map((result) =>
            result.matches ? Array.from({ length: this.retrievePaginationLimit() }) : Array.from({ length: FIXED_ROW_NUMBER * 5 })
        )
    );

    /* Controls lock */
    controlsLocked$ = new BehaviorSubject<boolean>(false);
    searchControl = new FormControl<string | null>('');
    resources$ = new BehaviorSubject<Bin[] | Clip[] | Resource[]>([]);
    /** searchMode array of available choices. */
    searchModeOptions = this.featureService.featureOn('enable-clip-search')
        ? [BinSectionContent.FOLDER, BinSectionContent.BIN, BinSectionContent.CLIP]
        : [BinSectionContent.FOLDER, BinSectionContent.BIN];
    searchModeSelected: BinSectionContentType = BinSectionContent.FOLDER;
    searchModeSelected$: Subject<BinSectionContentType> = new Subject<BinSectionContentType>();
    searchText: string | null = null;
    displayMode = DisplayMode.GRID;
    SEARCH_MODE = BinSectionContent;
    searchModeDisabled = false;
    // Display booleans
    /** Show all assets (including those from other users). */
    showAllAssets: boolean = false;
    /** Indicates if the "Show All Assets" toggle is disabled. */
    isShowAllAssetsDisabled: boolean = false;
    /** Indicates if the paginator is disabled. */
    isPaginatorDisabled: boolean = false;

    startAfter$: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
    listViewStartAfter: string | undefined = '';
    /** Observable for the current pagination breakpoints. */
    currentPagination$: BehaviorSubject<DisplayModePagination[]> = new BehaviorSubject<DisplayModePagination[]>(
        BINS_PAGINATION_BREAKPOINTS.get(Breakpoint.LARGE) || []
    );
    folderUrlId: string | null = null;
    /** Account data */
    username: string = '';
    // persist clipbin multiselection
    itemMultiSelection = new Set<string>();
    lastSelectedIndex: number = 0;
    /* Add new action props */
    createActions: Map<OrganizerTypes, OrganizersActionOptions> = new Map([
        [
            OrganizerTypes.FOLDER,
            {
                name: OrganizerEnum.FOLDER,
                type: OrganizerTypes.FOLDER,
                allow: true,
                errorMsg: '',
                order: 0
            }
        ],
        [
            OrganizerTypes.CLIP_BIN,
            {
                name: OrganizerEnum.CLIP_BIN,
                type: OrganizerTypes.CLIP_BIN,
                allow: true,
                errorMsg: '',
                order: 1
            }
        ]
    ]);
    isRootPath: boolean = true;
    isMyFolder: boolean = false;
    currentFolderLevel: number = 0;
    currentOwner: ClipbinsOwner = ClipbinsOwner.USER;

    protected isAdmin: boolean = false;
    protected userEmail: string = '';
    /* SearchResults */
    private searchChanged$: Observable<string | null>;
    private searchSubject = new Subject<string | null>();
    private readonly destroyed$ = new ReplaySubject<void>(1);

    constructor(
        private readonly binService: BinService,
        private readonly clipService: ClipApiService,
        private readonly cdr: ChangeDetectorRef,
        readonly searchInputService: SearchInputService,
        private authService: AuthService,
        readonly mediaCache: MediaCacheService,
        readonly scrubbingService: ScrubbingService,
        private readonly paginationService: PaginationService,
        private readonly breakpointObserver: BreakpointObserver,
        private readonly dialog: MatDialog,
        private readonly route: ActivatedRoute,
        private readonly featureService: FeatureFlagService,
        private readonly resourceService: ResourceService,
        private readonly router: Router
    ) {
        /**
         * Initialize component variables and set up subscriptions.
         */
        this.searchInputService.searchType$.next(SearchType.VOD);
        this.searchChanged$ = this.getSearchChanged();
        this.binsPagination = this.paginationService.getEmptyPagination(DEFAULT_BINS_PAGE_SIZE);
        this.clipsPagination = this.paginationService.getEmptyPagination(DEFAULT_BINS_PAGE_SIZE);
        this.startAfter = undefined;
        // this.foldersPagination = this.paginationService.getEmptyPagination(DEFAULT_BINS_PAGE_SIZE);
        this.binService.displayMode$.next(DisplayMode.GRID);

        this.binService.displayMode$.subscribe((mode) => {
            this.displayMode = mode ?? DisplayMode.GRID;
        });

        // Get user's name
        const accountUserName = this.authService.getUserName();
        this.username = accountUserName.split(' ')[0] || 'User';

        this.isAdmin = this.authService.isAdmin;
        this.userEmail = this.authService.getUserEmail();
    }

    get startAfter(): string | undefined {
        return this.startAfter$.value || undefined;
    }

    // Pagination
    set startAfter(val: string | undefined) {
        this.startAfter$.next(val);
    }

    get binResults() {
        return this.resources$.value as Bin[];
    }

    get clipResults() {
        return this.resources$.value as Clip[];
    }

    get folderResults() {
        return this.resources$.value as Resource[];
    }

    get allChecked() {
        return this.resources$.value.length > 0 && this.itemMultiSelection.size === this.resources$.value.length;
    }

    get someChecked() {
        return (
            this.resources$.value.length > 0 &&
            this.itemMultiSelection.size > 0 &&
            this.itemMultiSelection.size < this.resources$.value.length
        );
    }

    /**
     * Fetches paginated resources (folders, bins, or clips) based on the
     * provided parameters.
     *
     * @param resourceType The type of resource to fetch.
     * @param owner The owner of the resources.
     * @param searchTerm The search term to filter resources.
     * @param pageIndex The page index to fetch.
     * @param pageSize The number of items per page.
     * @param startAfter The ID of the item to start after (for pagination).
     */
    getPaginatedResources(
        resourceType: ResourceType,
        owner: string,
        searchTerm: string,
        pageIndex?: number,
        pageSize?: number,
        startAfter?: string
    ) {
        this.controlsLocked$.next(true);
        this.resultsLoading = true;
        const pSize = pageSize || DEFAULT_BINS_PAGE_SIZE;
        const pIndex = pageIndex || 0;

        const paginatedValue: PaginationInfo = {
            limit: pSize,
            offset: pIndex
        };

        if (startAfter) paginatedValue.startAfter = startAfter;

        const searchOptions: SearchOptions = {
            searchTerm: searchTerm,
            owner: owner === 'current_user' ? this.authService.getUserEmail() : '',
            type: resourceType.name
        };

        const { isNestedFolder, parentId } = this.extractUrlDetails();
        if (!isNestedFolder && !searchTerm) searchOptions['level'] = 0;

        if (isNestedFolder && parentId && !this.hasASearchTerm() && this.folderContentComponent) {
            this.folderContentComponent.reloadFolderContent(this.searchText || '');
            this.resultsLoading = false;
            this.controlsLocked$.next(false);
            this.cdr.markForCheck();
        } else {
            this.resourceService
                .getResource(resourceType, paginatedValue, searchOptions)
                .pipe(takeUntil(this.destroyed$))
                .subscribe((res: ResourceResult | unknown) => {

                  const resourceResult = res as ResourceResult;
                  this.listViewStartAfter = (resourceResult.clipBins
                   ? resourceResult.paginationData.startAfter
                   : (resourceResult.paginationData.totalItems > this.resourceService.BASE_LIMIT ? '.' : ''));
                  this.resultsLoading = false;
                  this.controlsLocked$.next(false);
                });
        }
    }

    ngAfterViewInit(): void {
      // Resets pagination and scrolls top when search value changes or clip bins get updated
      this.listenForSearchAndPageChanges();
      this.registerKeydownListeners();
      this.startAfter$
        .pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe((startAfter) => {
          if (!this.paginator) return;
          if (this.searchModeSelected === 'bins') {
            if (startAfter) {
              this.paginator["_nextButtonsDisabled"] = () => false;
              this.paginator.hasNextPage = () => true;
            } else {
              this.paginator["_nextButtonsDisabled"] = () => true;
              this.paginator.hasNextPage = () => false;
            }
          }
          this.cdr.detectChanges();
      });

      this.breakpointObserver
        .observe([
          CUSTOM_BREAKPOINTS.XS_MAX,
          CUSTOM_BREAKPOINTS.MD_MIN,
          CUSTOM_BREAKPOINTS.LG_MIN,
          CUSTOM_BREAKPOINTS.XL_MIN,
          CUSTOM_BREAKPOINTS.XXL_MIN,
        ])
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
            const pageLimit = this.retrievePaginationLimit();
            this.binsPagination.pageSize = pageLimit;
            this.clipsPagination.pageSize = pageLimit;
            this.foldersPagination.pageLimit = pageLimit;

            this.pageIndex$.next(0); // Reset pagination when changing screen size
            this.getPaginatedResources(
                this.searchModeSelected === BinSectionContent.FOLDER ? ResourceTypes.FOLDER : ResourceTypes.CLIPBIN,
                this.currentOwner === ClipbinsOwner.USER ? 'current_user' : 'all',
                this.searchText || '',
                0, // Reset to first page
                pageLimit
            );

            this.cdr.detectChanges();
        });

      this.updateResults();
      this.listenForNavigationChanges();

      this.resourceService.currentResources$.pipe(takeUntil(this.destroyed$)).subscribe((resources) => {
          this.resources$.next(resources as unknown as Resource[]);
          this.validateActionOptions();
          this.resourceService.isLoading$.next(false);
      });

      this.resourceService.isLoading$.pipe(takeUntil(this.destroyed$)).subscribe((isLoading) => {
        this.resultsLoading = isLoading;
        this.cdr.detectChanges();
      });

      this.resourceService.paginationInfo$.pipe(takeUntil(this.destroyed$)).subscribe((pagination) => {
          if (pagination.paginationResult) {
              if (this.searchModeSelected === 'folders') {
                  this.foldersPagination.pageLimit = pagination.paginationResult.pageSize;
                  this.foldersPagination.totalCount = pagination.paginationResult.totalItems;
                  this.foldersPagination.pageIndex = pagination.offset;
              } else {
                  this.binsPagination.pageSize = pagination.paginationResult.pageSize;
                  this.binsPagination.pageIndex = pagination.offset;
                  this.binsPagination.totalCount = UNKNOWN_LENGTH;
              }
          }

      });

      this.controlsLocked$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
          this.isShowAllAssetsDisabled = value;
          this.isPaginatorDisabled = value;
      });

        this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
            this.displayMode = params['viewMode'] === DisplayMode.LIST ? DisplayMode.LIST : DisplayMode.GRID;
            this.binService.displayMode$.next(this.displayMode);
            this.cdr.markForCheck();
        });

        this.resourceService.get<IResourceService>().subscribe((res: IResourceService) => {
            this.resourceService.selectedSearchMode$.next(
                res?.searchMode === 'bins' ? BinSectionContent.BIN : BinSectionContent.FOLDER
            );
        });
    }

    listenForNavigationChanges() {
        combineLatest([this.route.url, this.route.queryParams])
            .pipe(takeUntil(this.destroyed$))
            .subscribe(([url]) => {
                this.resultsLoading = true;
                if (!url.length) {
                    this.isRootPath = true;
                    this.resourceService.resetContext();
                }
                if (url[0]?.path === 'folders') {
                    this.folderUrlId = url[1]?.path;
                    this.searchModeSelected = BinSectionContent.FOLDER;
                }
                this.resultsLoading = false;
                this.setIsShowAllDisabled();
            });
    }

    listenForSearchAndPageChanges() {
        merge(this.searchChanged$, this.resourceService.currentOwner)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.resetPagination();
                this.cdr.markForCheck();
            });
    }

    /**
     * Search clips
     */
    searchClips(searchTerm: string | null) {
        if (!searchTerm) return EMPTY;

        return this.clipService.searchClipsByTitle(
            searchTerm /* query: string */,
            this.retrievePaginationLimit() /* pageSize:number*/,
            this.clipsPagination.nextPageToken /* pageToken:string */
        );
    }

    /**
     * Loads the initial bins list for the current owner and updates the results based on changes.
     */
    updateResults() {
        combineLatest([
            this.searchChanged$,
            this.resourceService.currentOwner,
            this.pageIndex$,
            this.resourceService.selectedSearchMode$,
            this.binService.displayMode$
        ])
            .pipe(
                debounceTime(SEARCH_DEBOUNCE), //TODO: fix this!
                takeUntil(this.destroyed$)
            )
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .subscribe(([term, owner, pageIndex, searchModeSelected, displayMode]) => {
                const searchTerm = term || '';
                const searchOptions = this.resourceService.searchOptions$.value;

                let newPageIndex = searchTerm !== searchOptions.searchTerm || displayMode === DisplayMode.LIST ? 0 : pageIndex;

                //TODO: If we are inside a folder, let folder–content handle the search update.
                if (this.folderUrlId && !this.hasASearchTerm()) {
                    return;
                }

                if (term !== searchOptions.searchTerm) {
                    this.startAfter = undefined;
                }

                if (displayMode === DisplayMode.LIST) {
                    this.startAfter = undefined;
                }

                if (searchModeSelected !== this.searchModeSelected) {
                    this.searchModeSelected = searchModeSelected ?? BinSectionContent.FOLDER;
                    this.startAfter = undefined;
                    this.resourceService.set({ searchMode: searchModeSelected });
                    newPageIndex = 0;
                }

                this.showAllAssets = owner !== 'current_user';

                switch (this.searchModeSelected) {
                    case BinSectionContent.CLIP:
                        this.clipsPagination.pageIndex = newPageIndex;
                        this.binsPagination.pageIndex = 0;
                        this.foldersPagination.pageIndex = 0;
                        break;
                    case BinSectionContent.BIN:
                        this.binsPagination.pageIndex = newPageIndex;
                        this.clipsPagination.pageIndex = 0;
                        this.foldersPagination.pageIndex = 0;
                        break;
                    case BinSectionContent.FOLDER:
                        this.foldersPagination.lastCursor = { ...this.foldersPagination.nextCursor } as Resource;
                        this.foldersPagination.nextCursor = this.folderResults[this.folderResults.length - 1];
                        this.foldersPagination.pageIndex = newPageIndex;
                        this.clipsPagination.pageIndex = 0;
                        this.binsPagination.pageIndex = 0;
                        break;
                }

                // Validate New action options
                this.validateActionOptions();

                this.cdr.markForCheck();

                this.getPaginatedResources(
                    searchModeSelected === BinSectionContent.FOLDER ? ResourceTypes.FOLDER : ResourceTypes.CLIPBIN,
                    owner,
                    searchTerm,
                    newPageIndex,
                    this.retrievePaginationLimit(),
                    this.startAfter
                );

                this.cdr.detectChanges();
            });
    }

    /**
     * Retrieves the pagination limit based on the breakpoint pagination options
     * and active display mode.
     */
    retrievePaginationLimit(): number {
      if (this.breakpointObserver.isMatched(CUSTOM_BREAKPOINTS.XXL_MIN)) {
        return 6 * FIXED_ROW_NUMBER;
      } else if (this.breakpointObserver.isMatched(CUSTOM_BREAKPOINTS.XL_MIN)) {
        return 5 * FIXED_ROW_NUMBER;
      } else if (this.breakpointObserver.isMatched(CUSTOM_BREAKPOINTS.LG_MIN)) {
        return 4 * FIXED_ROW_NUMBER;
      } else if (this.breakpointObserver.isMatched(CUSTOM_BREAKPOINTS.MD_MIN)) {
        return 3 * FIXED_ROW_NUMBER;
      } else if (this.breakpointObserver.isMatched(CUSTOM_BREAKPOINTS.SM_MIN)) {
        return 2 * FIXED_ROW_NUMBER;
      } else {
        return 1 * FIXED_ROW_NUMBER;
      }
    }



    getPlaceholderText() {
        if (!this.searchModeSelected) return '';
        switch (this.searchModeSelected) {
            case BinSectionContent.FOLDER:
                return 'Search folders';
            case BinSectionContent.BIN:
                return 'Search clip bins';
            case BinSectionContent.CLIP:
                return 'Search clips';
        }
    }

    formatBinSectionContent(mode: BinSectionContentType) {
        switch (mode) {
            case BinSectionContent.FOLDER:
                return 'Folders';
            case BinSectionContent.BIN:
                return 'Clip bins';
            case BinSectionContent.CLIP:
                return 'Clips';
        }
    }

    handleSearchModeChange(mode: BinSectionContentType) {
        this.resultsLoading = true;
        this.cdr.detectChanges();
        const { isNestedFolder } = this.extractUrlDetails();
        //TODO: Fix logic if we ever have more than 2 modes
        if (isNestedFolder && mode === BinSectionContent.BIN) {
            this.resourceService.set({ searchMode: mode });
            this.router.navigate(['/']);
        }
        if (this.searchModeSelected !== mode) {
            this.pageIndex$.next(0);
        }
        if (mode !== BinSectionContent.BIN && this.showAllAssets) {
            this.toggleShowAllAssets(false);
        }

        this.setIsShowAllDisabled();
        this.itemMultiSelection.clear();
        this.resourceService.selectedSearchMode$.next(mode);
    }

    shouldShowCheckbox() {
        return false;
        //This was removed for demo purposes.
    }

    setIsShowAllDisabled() {
        this.isShowAllAssetsDisabled = !!this.folderUrlId || this.searchModeSelected === BinSectionContent.CLIP;
    }

    /** Toggle the visualization of the user's clip bins and all clip bins */
    onShowAllChange(event: MatSlideToggleChange) {
        this.toggleShowAllAssets(event.checked);
        this.itemMultiSelection.clear();
    }

    /* Handles the toggling of clip bins visualization with or without direct 'show all' event */
    toggleShowAllAssets(toggle: boolean | null = null): void {
        toggle == null ? (this.showAllAssets = !this.showAllAssets) : (this.showAllAssets = !!toggle);
        this.currentOwner = this.showAllAssets ? ClipbinsOwner.ALL : ClipbinsOwner.USER;
        this.resourceService.currentOwner.next(this.showAllAssets ? ClipbinsOwner.ALL : ClipbinsOwner.USER);
        this.startAfter = undefined;
        this.pageIndex$.next(0);
    }

    /** Toggles between grid and list view */
    toggleViewMode() {
        const nextMode = this.isGrid(this.displayMode) ? DisplayMode.LIST : DisplayMode.GRID;

        setTimeout(() => {
            this.resultsLoading = true;
            this.cdr.detectChanges();
        }, 1);

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { viewMode: nextMode },
            queryParamsHandling: 'merge'
        });

        this.displayMode = nextMode;
        this.binService.displayMode$.next(nextMode);

        // Reset pagination to adjust to new view mode
        this.pageIndex$.next(0);
        this.binsPagination.pageSize = this.retrievePaginationLimit();
        this.clipsPagination.pageSize = this.retrievePaginationLimit();
    }

    isGrid(displayMode: DisplayMode) {
        return displayMode === DisplayMode.GRID;
    }

    onSearchClear() {
        this.resultsLoading = true;
        this.searchText = '';
        this.searchControl.setValue(this.searchText);
        this.searchSubject.next(this.searchText);
        this.cdr.markForCheck();
    }

    onPageChange(event: PageEvent) {
        this.resultsLoading = true;
        this.cdr.markForCheck();

        const urlTree = this.router.parseUrl(this.router.url);
        const isNestedFolder = urlTree.root.children['primary']?.segments[0]?.path === 'folders';

        const res = isNestedFolder
            ? this.resourceService.currentContext$.value.parent.children
            : this.resourceService.currentResources$.value;

        if (event.pageIndex !== 0) {
            if (event.previousPageIndex != null && event.pageIndex > event.previousPageIndex) {
                this.startAfter =
                    this.searchModeSelected === 'folders'
                        ? (res[res.length - 1].id as string)
                        : this.resourceService.paginationInfo$.value?.startAfter || '';
            } else {
                const lastResource =
                    this.searchModeSelected === 'folders'
                        ? this.resourceService.getLastResourceByPage(event.pageIndex - 1)
                        : this.resourceService.getLastBinResourceByPage(event.pageIndex - 1);
                if (lastResource) this.startAfter = lastResource.resourceId as string;
            }
        } else {
            this.startAfter = undefined;
        }

        this.pageIndex$.next(event.pageIndex);

        this.itemMultiSelection.clear();
    }

    /**
     * Validates the available actions for creating resources (folders, clip bins)
     * based on the current resource context (ownership, nesting level, and content).
     * Ensures folder creation respects a maximum nesting level of 2.
     * Disables actions when browsing another user's folder or exceeding nesting limits.
     */
    validateActionOptions(): void {
        this.resourceService.currentContext$.pipe(takeUntil(this.destroyed$)).subscribe((context) => {
            const parent = context?.parent;

            this.isRootPath = !this.extractUrlDetails().isNestedFolder;
            this.isMyFolder = parent?.owner === this.authService.getUserEmail();
            this.currentFolderLevel = parent?.level as number;

            // If no parent is found the level is root and all actions are allowed
            if (
                !parent ||
                this.isRootPath ||
                (!context?.parent.children.length && this.isMyFolder && this.currentFolderLevel < 2)
            ) {
                this.createActions.forEach((v, k, map) => map.set(k, { ...v, allow: true }));
                return;
            }

            // If not my folder all actions are disabled
            if (!this.isMyFolder) {
                this.createActions.forEach((v, k, map) => map.set(k, { ...v, allow: false }));
                return;
            }

            // Validate if action is allowed or not and if there are any error message
            this.createActions.forEach((v, k, map) => {
                if (['localupload', 'cloudingest'].includes(k)) return;
                const isCurrentFolderPath = context.parent?.children.some(
                    (value) => value.type === OrganizerTypes.FOLDER
                );

                const allowValue = Boolean(this.currentFolderLevel < 2 && isCurrentFolderPath);
                const errorMsg = actionOptionErrorMessage(isCurrentFolderPath, v.type, this.currentFolderLevel);
                map.set(k, {
                    ...v,
                    allow: k === OrganizerTypes.FOLDER ? allowValue : !isCurrentFolderPath,
                    errorMsg
                });
            });

            this.cdr.markForCheck();
        });
    }

    /**
     * Toggles manual selection of a clip bin for bulk operation. If the "shift" key
     * is pressed, all clips between the last selected one and the current clicked
     * one will be toggled.
     */
    toggleSelection(event: MouseEvent, bin: Bin) {
        const currentIndex = (this.resources$.value as Bin[]).findIndex((b) => b.name === bin.name);

        if (event.shiftKey && this.lastSelectedIndex !== null) {
            const start = Math.min(this.lastSelectedIndex, currentIndex);
            const end = Math.max(this.lastSelectedIndex, currentIndex);
            for (let i = start; i <= end; i++) {
                this.itemMultiSelection.add((this.resources$.value[i] as Bin).name);
            }
        } else {
            if (this.itemMultiSelection.has(bin.name)) {
                this.itemMultiSelection.delete(bin.name);
            } else {
                this.itemMultiSelection.add(bin.name);
            }
            this.lastSelectedIndex = currentIndex;
        }
        this.cdr.markForCheck();
    }

    toggleSelectAll() {
        if (this.allChecked && this.itemMultiSelection.size > 0) {
            this.itemMultiSelection.clear();
        } else {
            if (this.searchModeSelected == BinSectionContent.BIN) {
                this.resources$.value.map((bin) => this.itemMultiSelection.add((bin as Bin).name));
            }
        }
        this.cdr.markForCheck();
    }

    deleteSelection() {
        this.dialog.open(DeleteMultipleBinDialog, {
            ...DeleteMultipleBinDialog.dialogOptions,
            data: {
                bins: this.itemMultiSelection,
                onConfirm: () => {
                    this.pageIndex$.next(0);
                },
                onOpen: () => {
                    this.cdr.detectChanges();
                    this.itemMultiSelection = new Set();
                },
                onCancel: () => {
                    this.cdr.detectChanges();
                }
            }
        });
    }

    executeCreateAction(job: OrganizerTypes) {
        switch (job) {
            case OrganizerTypes.CLIP_BIN:
                this.dialog
                    .open(CreateBinDialog, {
                        ...CreateBinDialog.dialogOptions,
                        data: { parent: this.resourceService.currentContext$.value?.parent }
                    })
                    .afterClosed()
                    .pipe(takeUntil(this.destroyed$))
                    .subscribe((result) => {
                        if (result) {
                            this.searchModeSelected$.next(BinSectionContent.BIN);
                            this.pageIndex$.next(0);
                        }
                    });
                break;
            case OrganizerTypes.FOLDER:
                this.dialog
                    .open(ClipbinFolderCreationDialog)
                    .afterClosed()
                    .pipe(takeUntil(this.destroyed$))
                    .subscribe((result) => {
                        if (result) {
                            this.searchModeSelected$.next(BinSectionContent.FOLDER);
                            this.pageIndex$.next(0);
                        }
                    });
                break;
        }
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }

    private hasASearchTerm(): boolean {
        return !!this.searchText && this.searchText.trim() !== '';
    }

    private extractUrlDetails() {
        const urlTree: UrlTree = this.router.parseUrl(this.router.url);
        const urlPathSegments: UrlSegment[] = urlTree.root.children['primary']?.segments;
        const isNestedFolder: boolean = urlPathSegments?.[0]?.path === 'folders';
        const parentId = urlPathSegments?.[1]?.path;
        return { isNestedFolder, parentId };
    }

    private resetPagination() {
        if (this.searchText) {
            this.resources$.next([]);
            this.pageIndex$.next(0);
        }

        const pageLimit = this.retrievePaginationLimit();
        this.binsPagination = this.paginationService.getEmptyPagination(pageLimit);
        this.clipsPagination = this.paginationService.getEmptyPagination(pageLimit);
        this.foldersPagination = {
            pageLimit,
            nextCursor: null,
            lastCursor: null,
            pageIndex: 0,
            totalCount: 0
        };
    }

    private getSearchChanged() {
        return this.searchSubject.pipe(
            map((value) => {
                if (!value || !value.length) {
                    this.searchText = null;
                    return null;
                }
                this.searchText = value;
                this.searchInputService.partialQuery$.next(value);
                this.itemMultiSelection.clear();
                return value;
            }),
            debounceTime(SEARCH_DEBOUNCE),
            startWith('')
        );
    }

    private registerKeydownListeners() {
        const onKeydown = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                this.resultsLoading = true;
                this.searchSubject.next(this.searchControl.value);
                this.onEnterPressed();
            } else {
                e.stopImmediatePropagation();
            }
        };

        if (!this.searchInput) return;
        this.searchInput.nativeElement.addEventListener('keydown', onKeydown, { capture: true });
        this.destroyed$.subscribe(() => {
            this.searchInput?.nativeElement.removeEventListener('keydown', onKeydown);
        });
    }

    private onEnterPressed() {
        this.searchInput?.nativeElement.blur();
        this.searchField?._textField.nativeElement.blur();
    }
}
