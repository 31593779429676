<h2 mat-dialog-title>{{data.title}}</h2>
<div mat-dialog-content class="dialog-question-content">
  <p class="dialog-question" [class.no-margin--top]="data.noQuestionSpacing">{{data.question}}</p>
  <p *ngIf="data.extraChoice" class="dialog-question">
    <mat-checkbox
        color="primary"
        (change)="data.extraChoice[1] = !data.extraChoice[1]"
        [checked]="data.extraChoice[1]"
        [mam-firebase-ga]="'Toggle extra choice for ' + data.title">
      {{data.extraChoice[0]}}
    </mat-checkbox>
  </p>
</div>
<div mat-dialog-actions>
  <button
      *ngIf="!data.hideCancelButton"
      mat-button
      [mat-dialog-close]="false"
      [mam-firebase-ga]="'Canceled '  + data.title"
      [mam-firebase-resource]="data.firebaseResource">
    Cancel
  </button>
  <button
      mat-button
      cdkFocusInitial
      [mat-dialog-close]="getOutput()"
      (keyup.enter)="dialogRef.close(getOutput())"
      class="primary"
      color="primary"
      [mam-firebase-ga]="'Confirmed ' + data.title"
      [mam-firebase-resource]="data.firebaseResource">
    {{data.primaryButtonText || 'Confirm'}}
  </button>
</div>
