/**
 * Service responsible for managing the selection state of items
 * within the list and grid views. This service was created
 * to decouple selection logic from the `ResourceService`, which
 * handles other resource-related operations.
 */

import { Injectable, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Clip } from '../../../services/asset_service';
import { Bin } from '../../../services/bin.service';

import { Resource } from './resource.service';

type ResultItem = Resource | Bin | Clip;

interface ExpandedRow {
  id: string;
  element: ResultItem;
  detailRow: boolean;
  expanded$: BehaviorSubject<boolean>;
  isLoading$: BehaviorSubject<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class SelectionService {
  get selection$() {
    return this.selection.asReadonly();
  }

  get selectAll$() {
    return this.selectAll.asReadonly();
  }

  /**
   * This is basically what you need in order to get the current array of selected items
   * so you could just `return Array.from(this.selectionService.currentSelection)` in your component and perform actions with the selected items
   * or even set a rule to not perform an action if the array has (length > 1) etc...
   */
  get currentSelection(): Set<ResultItem> {
    return this.selection();
  }

  isSelected(item: ResultItem): boolean {
    return this.selection().has(item);
  }

  private registeredRows = new Set<ResultItem>();

  /**
   * Registers an item for selection management. This is essential for the "select all"
   * functionality to work correctly. Items must be registered before they can be
   * selected or included in the "select all" operation.
   *
   * @param row The item to register.
   */
  registerRow(row: ResultItem) {
    this.registeredRows.add(row);
  }

  /**
   * Unregisters a set of items from selection management. This is called when items
   * are removed from the view (e.g., when a user filters the list or navigates away
   * from the view). Unregistering prevents memory leaks and ensures that the selection
   * state is consistent with the displayed items.
   *
   * @param table An array of `ExpandedRow` objects representing the rows to unregister.
   */
  unregisterRow(table: ExpandedRow[]) {
    table.forEach((row: ExpandedRow) => {
      if (this.registeredRows.has(row.element)) {
        this.registeredRows.delete(row.element);
      }
    });
  }

  /**
   * Sets the "select all" state. This operation efficiently updates the selection
   * based on the `registeredRows`. If `checked` is true, all registered items are
   * selected. If `checked` is false, the selection is cleared.
   *
   * @param checked True to select all items, false to deselect all items.
   */
  setSelectAll(checked: boolean) {
    this.selectAll.set(checked);
    this.selection.set(checked ? new Set(this.registeredRows) : new Set());
  }

  /**
   * Toggles the selection state of a single item. This method updates the `selection`
   * signal and also updates the `selectAll` signal to maintain consistency. After
   * toggling an item, it checks if *all* registered items are now selected and updates
   * the `selectAll` state accordingly.
   *
   * @param item The item to toggle.
   */
  toggleSelect(item: ResultItem) {
    this.selection.update((currentSelection) => {
      const newSelection = new Set(currentSelection);
      newSelection.has(item) ? newSelection.delete(item) : newSelection.add(item);
      return newSelection;
    });

    // Crucial: After toggling, we must check if *all* registered items are selected.
    this.selectAll.set(this.isAllSelected(Array.from(this.registeredRows)));
  }

  /**
   * Checks if all registered items are currently selected. This is used internally
   * by `toggleSelect` and `setSelectAll` to maintain the correct state of the
   * `selectAll` signal.
   *
   * @param items An array of registered items.
   * @returns True if all items are selected, false otherwise.
   */
  private isAllSelected(items: ResultItem[]): boolean {
    return items.length > 0 && items.every((item) => this.selection().has(item));
  }

  private selection = signal(new Set<ResultItem>());
  private selectAll = signal(false);
}
