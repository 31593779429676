<ng-container *ngIf="localUploads$ | async as localUploads">

@if (showListView) {
<!-- ListViews table - feature flag ON -->
<mam-ui-table id="LocalUploads"
  class="local-uploads_list"
  [cols]="cols"
  [data]="localUploads"
  [options]="{
    tableInfo: {
      id: tableId,
      name: 'localUploads'
    },
    multi: true,
    mainColIdx: 1
  }"
  [activeSort]="activeSort"
  (sortClick)="onSort($event, localUploads)"
  emptyMessage="No Uploads found"
>
  <ng-template #cellTpl let-row let-col="col">
    <!-- Icon -->
    <ng-container *ngIf="col.cellTpl === 'iconTpl'">
      <div class="table-icon">
        <ng-container [ngSwitch]="row.status$ | async">
          <ng-container *ngSwitchCase="localUploadStatus.ERROR">
            <mat-icon
                class="error"
                matTooltip="{{ row.errorMessage }}"
                matTooltipClass="close-from-top">error</mat-icon>
          </ng-container>
          <ng-container *ngSwitchCase="localUploadStatus.UPLOADING">
            <mat-spinner diameter="20" matTooltip="Uploading"></mat-spinner>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <mat-icon>insert_drive_file</mat-icon>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>

    <!-- Name -->
    <ng-container *ngIf="col.cellTpl === 'titleTpl'">
      <div class="icon-with-text">
        <span matTooltipClass="mobile-tooltip" matTooltip="{{row.fileName}}" matTooltipPosition="below">
          {{row.fileName}}
        </span>
      </div>
    </ng-container>

    <!-- Uploaded by -->
    <ng-container *ngIf="col.cellTpl === 'userTpl'">
      <span>{{ row.user$ | async }}</span>
    </ng-container>

    <!-- File Size -->
    <ng-container *ngIf="col.cellTpl === 'sizeTpl'">
      <span>{{ row.fileSize | bytes }}</span>
    </ng-container>

    <!-- Start time -->
    <ng-container *ngIf="col.cellTpl === 'startTpl'">
      {{row.startTime | date: "MMM d, y, h:mm a"}}
    </ng-container>

    <!-- Duration -->
    <ng-container *ngIf="col.cellTpl === 'durationTpl'">
        {{ displayDuration ? ((row.endTime | durationOrTime) || '--') : '--'}}
    </ng-container>

    <!-- Status -->
    <ng-container *ngIf="col.cellTpl === 'statusTpl'">
        <ng-container
            *ngIf="{status: row.status$ | async, progress: row.progress$ | async} as curState"
        >
          <ng-container [ngSwitch]="curState.status">
            <ng-container *ngSwitchCase="localUploadStatus.COMPLETED">
              <div class="icon-with-text status-column-icon">
              <mat-icon
                class="icon-completed"
                matTooltip="Completed"
                matTooltipPosition="below">
                check_circle
              </mat-icon>
              <span>Completed</span>
            </div>
            </ng-container>

            <ng-container *ngSwitchCase="localUploadStatus.PAUSED">
              <!-- (click)="uploadFileInput.click()"-->
              <button
                  mat-flat-button
                  matTooltip="{{curState.progress}}% has been uploaded. Please select the same file to resume."
                  [ngClass]="'asset-table-button'">
                Resume
              </button>
            </ng-container>

            <ng-container *ngSwitchCase="localUploadStatus.ERROR">
                <!-- (click)="uploadFileInput.click()"-->
              <button
                  mat-flat-button
                  matTooltip="{{row.errorMessage}}. Please select the same file to retry."
                  [ngClass]="'asset-table-button'">
                Retry
              </button>
            </ng-container>

            <ng-container *ngSwitchCase="localUploadStatus.UPLOADING">
              <div class="status-progress" [title]="curState.progress + '%'">
                <div class="fill" [style.width.%]="curState.progress"></div>
              </div>
              <span>{{curState.progress}}%</span>
            </ng-container>

          </ng-container>
        </ng-container>

      </ng-container>

    <!-- Action -->
    <ng-container *ngIf="col.cellTpl === 'actionTpl'">
        <button
            [matTooltip]="
            (row.status$ | async) === localUploadStatus.UPLOADING ? 'Cancel' : 'Dismiss'
          "
            mat-icon-button
            (click)="$event.stopPropagation(); onCancelClicked(row)">
          <mat-icon class="gmat-icon">close</mat-icon>
        </button>
    </ng-container>
  </ng-template>
</mam-ui-table>

}
@else {
  <!-- Standard table - feature flag OFF -->
  <table mat-table class="legacy-table" [trackBy]="trackByUpload" [dataSource]="localUploads">
    <!-- File name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>File name</th>
      <td mat-cell *matCellDef="let upload" class="main-column">
        <div class="icon-with-text">
          <ng-container [ngSwitch]="upload.status$ | async">
            <ng-container *ngSwitchCase="localUploadStatus.ERROR">
              <mat-icon
                  class="error"
                  matTooltip="{{ upload.errorMessage }}"
                  matTooltipClass="close-from-top">error</mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="localUploadStatus.UPLOADING">
              <mat-spinner diameter="20" matTooltip="Uploading"></mat-spinner>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <mat-icon>insert_drive_file</mat-icon>
            </ng-container>
          </ng-container>
          <span>{{upload.fileName}}</span>
        </div>
      </td>
    </ng-container>

    <!-- File size Column -->
    <ng-container matColumnDef="size">
      <th mat-header-cell *matHeaderCellDef>Size</th>
      <td
          mat-cell
          *matCellDef="let upload"
          [title]="(upload.fileSize | number) + ' bytes'">
        {{upload.fileSize | bytes}}
      </td>
    </ng-container>

    <ng-container matColumnDef="user">
      <th
        mat-header-cell
        *matHeaderCellDef
        [hidden]="!showLocalUploadsSource">
        <mam-sort-col-btn
          [rows]="localUploads"
          [key]="'user'"
          [activeKey]="activeSort.active"
          colName="Uploaded by"
          (mamSortByField)="onSortByField($event)">
          Uploaded by
        </mam-sort-col-btn>
      </th>
      <td
        mat-cell
        *matCellDef="let upload"
        [title]="upload.user"
        [hidden]="!showLocalUploadsSource">
        {{upload.user$ | async}}
      </td>
    </ng-container>

    <!-- Started Time Column -->
    <ng-container matColumnDef="startTime">
      <th mat-header-cell *matHeaderCellDef>Start Time</th>
      <td mat-cell *matCellDef="let upload"
          [title]="upload.startTime | date: 'long'">
        {{upload.startTime | date: "MMM d, y, h:mm a"}}
      </td>
    </ng-container>

    <!-- Duration Column -->
    <ng-container matColumnDef="duration">
      <th mat-header-cell *matHeaderCellDef>Duration</th>
      <td mat-cell *matCellDef="let upload"
          [title]="upload.endTime | durationOrTime">
        {{ upload.endTime | durationOrTime }}
      </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let upload">
          <ng-container
              *ngIf="{status: upload.status$ | async, progress: upload.progress$ | async} as curState">
            <ng-container [ngSwitch]="curState.status">
              <ng-container *ngSwitchCase="localUploadStatus.COMPLETED">
                <div class="icon-with-text status-column-icon">
                <mat-icon
                  class="icon-completed"
                  matTooltip="Completed"
                  matTooltipPosition="below">
                  check_circle
                </mat-icon>
                <span>Completed</span>
              </div>
              </ng-container>
              <ng-container *ngSwitchCase="localUploadStatus.PAUSED">
                <button
                    mat-flat-button
                    (click)="uploadFileInput.click()"
                    matTooltip="{{curState.progress}}% has been uploaded. Please select the same file to resume."
                    [ngClass]="'asset-table-button'">
                  Resume
                </button>
              </ng-container>
              <ng-container *ngSwitchCase="localUploadStatus.ERROR">
                <button
                    mat-flat-button
                    (click)="uploadFileInput.click()"
                    matTooltip="{{upload.errorMessage}}. Please select the same file to retry."
                    [ngClass]="'asset-table-button'">
                  Retry
                </button>
              </ng-container>
              <ng-container *ngSwitchCase="localUploadStatus.UPLOADING">
                <div class="status-progress" [title]="curState.progress + '%'">
                  <div class="fill" [style.width.%]="curState.progress"></div>
                </div>
                <span>{{curState.progress}}%</span>
              </ng-container>
            </ng-container>
          </ng-container>
          <input
              #uploadFileInput
              accept="video/*,.mxf,.xml"
              hidden
              type="file"
              (change)="retryOrResumeOneFile($event, upload)" />
      </td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let upload">
        <button
            [matTooltip]="
            (upload.status$ | async) === localUploadStatus.UPLOADING ? 'Cancel' : 'Dismiss'
          "
            mat-icon-button
            (click)="$event.stopPropagation(); onCancelClicked(upload)">
          <mat-icon class="gmat-icon">close</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
  </table>

  <div *ngIf="!localUploads?.length" class="empty-message">
    No local uploads found
  </div>
}


</ng-container>
