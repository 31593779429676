import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, of, Subject } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { ApiClip } from '../../../api/ias/model/api-clip';
import { AuthService } from '../../../auth/auth_service';
import { environment } from '../../../environments/environment';
import { BinSectionContent, BinSectionContentType } from '../../../services/bin.service';
import { StateService } from '../../../services/state_service';
import { StorageLocalService } from '../../../services/storage/storage_local.service';

import { ResourceType, ResourceTypeApiName, ResourceTypeName, ResourceTypes } from './resource-types';

export interface PaginationInfo {
    limit: number;
    offset: number;
    nextToken?: string;
    startAfter?: string;
    searchTerm?: string;
    paginationResult?: PaginationResult;
    totalPages?:number;
}

export interface PaginationResult {
    pageSize: number;
    totalItems: number;
    totalPages: number;
    startAfter?: string;
    pageIndex?: number;
}

export interface Resource {
    id?: string;
    name: string;
    displayName: string;
    type?: ResourceTypeApiName;
    url?: string;
    createdAt: string;
    updatedAt?: string;
    parentId?: string;
    createdBy?: string;
    level?: number;
    items?: number;
    breadcrumbPath?: string;
    breadcrumb?: Resource[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    iasData?: any; // This is a generic type, hence we use any
    directChildrenCount?: number;
    owner?: string;
    ownerName?: string;
    parent?: Resource;
    subTreeDepth: number;
    iasId?: string;
    deletedAt?: string;
    displayMode?:string;
}

export interface ResourceResult {
    folders: Resource[];
    clipBins: Resource[];
    paginationData: PaginationResult;
}

export interface DeletedResourceResult {
    nodes: Resource[];
    paginationData: PaginationResult;
}

export interface ResourceCreationData {
    name: string;
    parentId?: string;
    ownerName?: string;
    owner?: string;
}

export interface ResourceDeletedResult {
    isSoftDeleted: boolean;
    deletedAt?: string;
}

export interface SearchOptions {
    searchTerm: string;
    owner?: string;
    parent?: string;
    type?: ResourceTypeName;
    level?: number;
}

export interface LastResourcePerPage {
    pageIndex: number;
    resourceId: string;
}

export interface ParentResource {
    breadcrumb: Resource[];
    children: Resource[];
    createdAt: string;
    deletedAt: string | null;
    deletedBy: string | null;
    deletedByName: string | null;
    directChildrenCount: number;
    iasData: string | null;
    iasId: string | null;
    id: string;
    level?: number;
    name: string;
    nameTsvector: string;
    owner: string;
    ownerName: string | null;
    subTreeDepth: number;
    type: ResourceTypeName;
    updatedAt: string;
}

export interface ResourceContent {
    paginationData?: PaginationInfo;
    parent: ParentResource;
}

export interface MoveFolderDestination {
    newParentId: string;
}

export interface IResourceService {
  searchMode:  string;
}

@Injectable({
    providedIn: 'root'
})
export class ResourceService extends StorageLocalService<IResourceService> {
    readonly MAX_FOLDER_DEPTH = 2;
    readonly BASE_LIMIT = 24;
    // private readonly BASE_URL = `http://localhost:3001/api/v1`;
    // BehaviorSubjects to persist the current resource page and pagination info
    currentResources$ = new BehaviorSubject<Resource[]>([]);
    currentContext$ = new BehaviorSubject<ResourceContent>({} as ResourceContent);
    paginationInfo$ = new BehaviorSubject<PaginationInfo>({ limit: this.BASE_LIMIT, offset: 0 });
    pageChanged$ = new Subject();
    searchOptions$ = new BehaviorSubject<SearchOptions>({ searchTerm: '', owner: '' });
    selectedSearchMode$ = new Subject<BinSectionContentType | undefined>();
    lastResourcePerPageArray: LastResourcePerPage[] = [];
    lastMoveResourcePerPageArray: LastResourcePerPage[] = [];
    lastBinResourcePerPageArray: LastResourcePerPage[] = [];
    isLoading$ = new BehaviorSubject<boolean>(false);
    private readonly BASE_URL = environment.resourcesApi;
    override key = 'RESOURCE_SERVICE';


    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
        readonly stateService: StateService
    ) {
      super();
    }

    get currentOwner() {
      return this.stateService.clipbinsOwner$;
    }

    /** Health Check */
    healthCheckResourcesAPI() {
        return this.httpClient.get(`${this.BASE_URL}/health`, { headers: this.getAuthHeaders() });
    }

    getResourceById(type: ResourceType, resourceId: string) {
        // Check if the resource type is 'clip-bin' and URL encode the resource ID
        const encodedResource = encodeURIComponent(resourceId);
        return this.httpClient.get<Resource>(`${this.BASE_URL}/${type.name}s/${encodedResource}`, {
            headers: this.getAuthHeaders()
        });
    }

    getResourceDeletedInformationById(type: ResourceType, resourceId: string) {
      // Check if the resource type 'clip-bin' and URL encode the resource ID
      const encodedResource = encodeURIComponent(resourceId);
      return this.httpClient.get<ResourceDeletedResult>(`${this.BASE_URL}/${type.name}s/${encodedResource}/soft-delete-status`, {
        headers: this.getAuthHeaders()
      });
    }

    getResource(
      type: ResourceType,
      pagination?: PaginationInfo,
      searchOptions?: SearchOptions,
      updateResource: boolean = true,
      isMoveDialog: boolean = false,
      loadMore:boolean = false,
    ) {
      let params = new HttpParams();
      if (searchOptions) params = this.buildSearchParams(searchOptions);
      if (pagination) params = this.buildPaginationParams(pagination, params);
      return this.httpClient
        .get<ResourceResult>(`${this.BASE_URL}/${type.name}s`, { headers: this.getAuthHeaders(), params })
        .pipe(
          catchError(err => {
            console.error(err);
            return of({ [type.resourcesKey]: [] } as unknown as ResourceResult);
          }),
          tap(resources => {
            this.handleResources(type, resources, pagination, updateResource, isMoveDialog, loadMore);
            if (searchOptions && updateResource) this.searchOptions$.next(searchOptions);
          })
        );
    }


    addOrUpdateLastResourcePerPage(pageIndex: number, resourceId: string, isMoveDialog: boolean) {
        const getResourceOnPageIndex = !isMoveDialog
            ? this.lastResourcePerPageArray.findIndex((item) => item.pageIndex === pageIndex)
            : this.lastMoveResourcePerPageArray.findIndex((item) => item.pageIndex === pageIndex);

        if (getResourceOnPageIndex === -1) {
            if (!isMoveDialog) {
                this.lastResourcePerPageArray.push({ pageIndex: pageIndex, resourceId: resourceId });
            } else {
                this.lastMoveResourcePerPageArray.push({ pageIndex: pageIndex, resourceId: resourceId });
            }
        } else {
            if (!isMoveDialog) {
                this.lastResourcePerPageArray[getResourceOnPageIndex] = {
                    pageIndex: pageIndex,
                    resourceId: resourceId
                };
            } else {
                this.lastMoveResourcePerPageArray[getResourceOnPageIndex] = {
                    pageIndex: pageIndex,
                    resourceId: resourceId
                };
            }
        }
    }

    addOrUpdateLastBinResourcePerPage(pageIndex: number, resourceId: string) {
        const getResourceOnPageIndex = this.lastBinResourcePerPageArray.findIndex(
            (item) => item.pageIndex === pageIndex
        );

        if (getResourceOnPageIndex === -1) {
            this.lastBinResourcePerPageArray.push({ pageIndex: pageIndex, resourceId: resourceId });
        } else {
            this.lastBinResourcePerPageArray[getResourceOnPageIndex] = {
                pageIndex: pageIndex,
                resourceId: resourceId
            };
        }
    }

    getLastBinResourceByPage(pageIndex: number) {
        const getResourceOnPageIndex = this.lastBinResourcePerPageArray.findIndex(
            (item) => item.pageIndex === pageIndex
        );
        return getResourceOnPageIndex > -1 ? this.lastBinResourcePerPageArray[getResourceOnPageIndex] : null;
    }

    getLastResourceByPage(pageIndex: number) {
        const getResourceOnPageIndex = this.lastResourcePerPageArray.findIndex((item) => item.pageIndex === pageIndex);
        return getResourceOnPageIndex > -1 ? this.lastResourcePerPageArray[getResourceOnPageIndex] : null;
    }

    /**
     * Retrieves the children of a specific resource based on the provided type and parent ID.
     * This method supports pagination and can optionally update the current resource context.
     *
     * @param type - The type of the resource for which children are being retrieved.
     * @param parentId - The ID of the parent resource.
     * @param [pagination] - Optional pagination information to control the retrieval of data.
     * @param [updateResource] - Indicates whether to update the current resource context with the retrieved data.
     * @param [isMoveDialog] - Specifies whether the request is part of a move dialog operation.
     * @returns An observable emitting the resource content, including its children and pagination data if provided.
     */
    getResourceChildren(
        type: ResourceType,
        parentId: string,
        pagination?: PaginationInfo,
        updateResource: boolean = true,
        isMoveDialog: boolean = false
    ) {
        let params = new HttpParams();
        if (pagination) {
            params = this.buildPaginationParams(pagination);
        }
        return this.httpClient
            .get<ResourceContent>(`${this.BASE_URL}/${type.name}s/${parentId}/children`, {
                headers: this.getAuthHeaders(),
                params
            })
            .pipe(
                tap((resourceContent) => {
                    if (updateResource) this.currentContext$.next(resourceContent);
                    if (pagination && resourceContent.paginationData) {
                        pagination.paginationResult = resourceContent.paginationData as unknown as PaginationResult;
                        if (updateResource) this.paginationInfo$.next(pagination);
                        const children = this.currentContext$.value.parent?.children;
                        if (children && children.length > 0) {
                            if (pagination.nextToken)
                                this.addOrUpdateLastBinResourcePerPage(
                                    resourceContent.paginationData.offset,
                                    pagination.nextToken
                                );
                            else
                                this.addOrUpdateLastResourcePerPage(
                                    resourceContent.paginationData.offset,
                                    children[children.length - 1]?.id as string,
                                    isMoveDialog
                                );
                        }
                    }
                })
            );
    }

    createResource(type: ResourceType, creationData: ResourceCreationData, context?: string) {
      const effectiveType = creationData.parentId && type === ResourceTypes.CLIPBIN ? ResourceTypes.FOLDER : type;
      this.selectedSearchMode$.next(
        effectiveType === ResourceTypes.FOLDER ? BinSectionContent.FOLDER : BinSectionContent.BIN
      );
      return this.httpClient
        .post<Resource>(`${this.BASE_URL}/${type.name}s`, creationData, { headers: this.getAuthHeaders() })
        .pipe(
          switchMap(() => {
            return context
                ? (firstValueFrom(
                      this.getResourceChildren(effectiveType, context, this.paginationInfo$.value)
                  ) as Promise<ResourceContent>)
                  //TODO: Review the logic for fetching resources on delete to stay on the same page
                : (firstValueFrom(this.getResource(effectiveType, {offset: 0, limit: this.paginationInfo$.value.limit, startAfter: undefined}, this.searchOptions$.value)) as unknown as Promise<
                      Resource[]
                  >);
        })
        );
    }

    updateResource(type: ResourceType, resourceId: string, updateData: Partial<Resource>, context?: string) {
        return this.httpClient
            .put<Resource>(`${this.BASE_URL}/${type.name}s/${resourceId}`, updateData, {
                headers: this.getAuthHeaders()
            })
            .pipe(
                switchMap(() => {
                    const pagination = this.paginationInfo$.value;
                    const searchOptions = this.searchOptions$.value;
                    return context
                        ? (this.getResourceChildren(type, context, pagination).toPromise() as Promise<ResourceContent>)
                        : (this.getResource(type, pagination, searchOptions).toPromise() as unknown as Promise<Resource[]>);
                })
            );
    }

    moveResource(type: ResourceType, resourceId: string, newParentId: string) {
        const encodedResource = encodeURIComponent(resourceId);
        return this.httpClient
            .put<Resource>(
                `${this.BASE_URL}/${type.name}s/${encodedResource}/move`,
                { newParentId: newParentId !== '0' ? newParentId : '' },
                { headers: this.getAuthHeaders() }
            )
            .pipe(
                switchMap(() => {
                    return newParentId === '0' ?
                      this.getResourceById(type, resourceId)
                      .toPromise() :
                      this.getResourceChildren(
                          ResourceTypes.FOLDER,
                          newParentId
                      ).toPromise() as Promise<ResourceContent>;
                })
            );
    }

    deleteResource(contextType: ResourceType, deletingType: ResourceType, resourceId: string, context?: string) {
        if (deletingType === ResourceTypes.CLIPBIN) {
            resourceId = encodeURIComponent(resourceId);
        }
        return this.httpClient
            .delete(`${this.BASE_URL}/${deletingType.name}s/${resourceId}`, { headers: this.getAuthHeaders() })
            .pipe(
                switchMap(() => {
                    return context
                        ? (firstValueFrom(
                              this.getResourceChildren(contextType, context, this.paginationInfo$.value)
                          ) as Promise<ResourceContent>)
                          //TODO: Review the logic for fetching resources on delete to stay on the same page
                        : (firstValueFrom(this.getResource(contextType, {offset: 0, limit: this.paginationInfo$.value.limit, startAfter: undefined}, this.searchOptions$.value)) as unknown as Promise<
                              Resource[]
                          >);
                })
            );
    }

    undeleteResource(type: ResourceType, resourceId: string) {
        if (type === ResourceTypes.CLIPBIN) {
            resourceId = encodeURIComponent(resourceId);
        }
        return this.httpClient.patch(
            `${this.BASE_URL}/${type.name}s/${resourceId}/undelete`,
            {},
            { headers: this.getAuthHeaders() }
        );
    }

    getDeletedResources(pagination?: PaginationInfo, searchOptions?: SearchOptions, updateResources: boolean = false): Observable<DeletedResourceResult> {
        let params = new HttpParams();
        if (searchOptions) {
            params = new HttpParams();
            if (searchOptions.searchTerm) params = params.set('searchTerm', searchOptions.searchTerm);
            if (searchOptions.owner && searchOptions.owner !== 'all_users' && searchOptions.owner !== '')
                params = params.set('owner', searchOptions.owner);
            if (searchOptions.parent) params = params.set('parent', searchOptions.parent);
            if (searchOptions.type)
                params = params.set('type', searchOptions.type === `clip-bin` ? 'clipbin' : searchOptions.type);
            if (searchOptions.level !== undefined) {
                params = params.set('level', searchOptions.level);
            }
        }

        if (pagination) {
            params = this.buildPaginationParams(pagination, params);
        }

        return this.httpClient
            .get<DeletedResourceResult>(`${this.BASE_URL}/deleted/`, {
                headers: this.getAuthHeaders(),
                params
            })
            .pipe(
                tap((resourceResult) => {
                    if (pagination && resourceResult.paginationData) {
                        pagination.paginationResult = resourceResult.paginationData;
                        this.paginationInfo$.next(pagination);
                    }
                    if (updateResources) {
                      this.currentResources$.next(resourceResult.nodes);
                    }
                })
            );
    }

    getThumbnailsAssets(id: string, maxItems: number): Observable<ApiClip[]> {
        return this.httpClient.get<ApiClip[]>(`${this.BASE_URL}/thumbnails/${id}?maxItems=${maxItems}`, {
            headers: this.getAuthHeaders()
        });
    }

    resetContext() {
        this.currentContext$.next({} as ResourceContent);
    }

    renameClipBin(iasId: string, newName: string){
      return this.httpClient.put<Resource[]>(`${this.BASE_URL}/${ResourceTypes.CLIPBIN.name}s/rename/${iasId}`,
      { name: newName },
      { headers: this.getAuthHeaders() });
    }

    updateLocalResource(iasId: string, newName: string) {
      const items = this.currentResources$.value;
      const idx = items.findIndex(it => it.iasId === iasId);
      items[idx].name = newName;
      items[idx].displayName = newName;
      items[idx].iasData['label']['displayName'] = newName;

      this.currentResources$.next(items);
    }

    private getAuthHeaders(): HttpHeaders {
        const accessToken = this.authService.getAccessToken();
        return new HttpHeaders({
            Authorization: `Bearer ${accessToken}`
        });
    }

    /** Helper method to build pagination params */
    private buildPaginationParams(pagination: PaginationInfo, params: HttpParams = new HttpParams()): HttpParams {
        if (pagination.limit) params = params.set('pageSize', pagination.limit.toString());
        if (pagination.offset) params = params.set('offset', pagination.offset.toString());
        if (pagination.nextToken) params = params.set('nextToken', pagination.nextToken);
        if (pagination.startAfter) params = params.set('startAfter', pagination.startAfter);

        return params;
    }

    private buildSearchParams(searchOptions: SearchOptions, params: HttpParams = new HttpParams()): HttpParams {
        if (searchOptions.searchTerm) params = params.set('searchTerm', searchOptions.searchTerm);
        if (searchOptions.owner && searchOptions.owner !== 'all_users' && searchOptions.owner !== '')
            params = params.set('owner', searchOptions.owner);
        if (searchOptions.parent) params = params.set('parent', searchOptions.parent);
        if (searchOptions.type)
            params = params.set('type', searchOptions.type === `clip-bin` ? 'clipbin' : searchOptions.type);
        if (searchOptions.level !== undefined) {
            params = params.set('level', searchOptions.level);
        }

        return params;
    }

    private handleResources(
      type: ResourceType,
      resources: ResourceResult,
      pagination?: PaginationInfo,
      updateResource: boolean = true,
      isMoveDialog: boolean = false,
      loadMore:boolean = false
    ) {
      const currentResources = resources[type.resourcesKey as 'folders' | 'clipBins'] || [];

      if (!currentResources.length) {
        if (updateResource) {
          this.paginationInfo$.next({
            paginationResult: { pageSize: 0, totalItems: 0, totalPages: 0 },
            limit: 0,
            offset: 0
          });
        }
        this.currentResources$.next([]);
        return;
      }

      if (pagination) {
        pagination.paginationResult = resources.paginationData as PaginationResult;
        pagination.startAfter = pagination.paginationResult?.startAfter;
        if (updateResource) this.paginationInfo$.next(pagination);
        if (type.name === 'clip-bin' && pagination.paginationResult?.startAfter) {
          this.addOrUpdateLastBinResourcePerPage(pagination.offset, pagination.paginationResult.startAfter);
        } else {
          const lastResource = currentResources[currentResources.length - 1];
          if (lastResource) this.addOrUpdateLastResourcePerPage(pagination.offset, lastResource.id as string, isMoveDialog);
        }
      }

      if (!loadMore) this.currentResources$.next(currentResources);
    }

}
