
<div class="actions-search-bar-container">
  <div class="search-bar-container">
    <mat-form-field
        appearance="outline"
        class="search-bar">
      <mat-icon matPrefix>search</mat-icon>
      <input
          type="text"
          matInput
          (keydown)="handleKeyPress($event)"
          [readOnly]="nlqDataLoading"
          [formControl]="nlqSearchControl"
          placeholder="Ask Gemini"
          aria-label="Search query"
          autocomplete="off">
      <button
          *ngIf="nlqSearchControl.value"
          class="nlq-clear-button"
          [disabled]="nlqDataLoading"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          mam-firebase-ga="Clear in admin metrics nlq"
          (click)="nlqClear()">
        <mat-icon>close</mat-icon>
      </button>
      <button
          class="nlq-search-button"
          [disabled]="nlqDataLoading || !nlqSearchControl.value"
          matSuffix
          mat-icon-button
          aria-label="Search"
          mam-firebase-ga="Search in admin metrics nlq"
          (click)="nlqSearch()">
        <mat-icon>send</mat-icon>
      </button>
    </mat-form-field>

      <button
          class="nlq-reset-button"
          [disabled]="nlqDataLoading || (nlqChartData === undefined && nlqTextData === undefined)"
          matSuffix
          mat-button
          aria-label="Reset"
          mam-firebase-ga="Reset in admin metrics nlq"
          (click)="nlqReset()">
        CLEAR
      </button>

  </div>
</div>

<ng-container *ngIf="nlqDataLoading || nlqChartData !== undefined || nlqTextData !== undefined">
  <h2 class="metrics_major_title">Gemini Results</h2>
  <div class="nlq-content">
    <ng-container *ngIf="nlqDataLoading">
      <div class="nlq-loading-logo">
        <img alt="NLQ data loading" src="images/empty_contents_logo.svg" />
      </div>
      <div class="nlq-loading-message">
        <span>Creating custom graphic...</span>
      </div>
    </ng-container>
    <div *ngIf="nlqChartData !== undefined" class="nlq-chart-data">
      <mam-admin-metrics-chart #metricsChart [data]="nlqChartData"></mam-admin-metrics-chart>
    </div>
    <div *ngIf="nlqTextData !== undefined" class="nlq-text-data">
      <span>{{nlqTextData}}</span>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!nlqDataLoading && nlqChartData === undefined && nlqTextData === undefined">
<div class="popular_header">
  <h2 class="metrics_major_title">Popular</h2>
  <span
      *ngIf="popularLastUpdate"
      class="popular_last_update">
      Last update: {{ popularLastUpdate }}
  </span>
</div>
<section *ngFor="let assetKey of iterableAssetKeys">
<ng-container *ngIf="{
    metric: assetsMetrics[assetKey],
    pagination: paginations[assetKey],
    pageSize: paginations[assetKey]?.pageSize || 0,
    pageIndex: paginations[assetKey]?.pageIndex || 0,
    title: TITLES[assetKey],
} as state">
  <div class="dashboard-list-header">
    <h4 class="metrics-title">
      {{state.title}}
    </h4>

    <div class="paginator" *ngIf="state.metric?.length as length">
      <mat-paginator
          class="main__mat-paginator--border"
          hidePageSize
          [length]="length"
          [pageSize]="state.pageSize"
          [pageIndex]="state.pageIndex"
          (page)="onPageChange($event, state.pagination)">
      </mat-paginator>
    </div>
  </div>

  <hr class="metrics-title-separator"/>

  @if(!state.metric){
    <div class="loading-container">
      <span class="loading-icon lds-dual-ring"></span>
      Loading...
    </div>
  }@else if(!state.metric.length){
    <div class="empty-message">
      No results available.
    </div>
  }@else {
    <div class="dashboard-data">
      <mam-top-asset-card class="dashboard-data-item"
          *ngFor="let item of (state.metric | slice:(state.pageIndex * state.pageSize):(((state.pageIndex + 1) * state.pageSize)))"
          [topAsset]="item">
      </mam-top-asset-card>
    </div>
  }
</ng-container>
</section>
</ng-container>
