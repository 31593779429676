import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject} from 'rxjs';

import {ResourceService} from '../landing/clip-bin-section/service/resource.service';
import {BinSectionContent} from '../services/bin.service';
import {SnackBarService} from '../services/snackbar_service';
import {StateService} from '../services/state_service';
import {SharedLinkClipBinService} from '../shared_clipbin/services/shared_link_clipbin.service';

/** Clipbin rename dialog */
@Component({
  selector: 'mam-rename-bin-dialog',
  templateUrl: './rename_bin_dialog.ng.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
    @import './shared/mixins';
    :host {
      max-width: 502px;
      width: 70vw;
      display: block;

        @include breakpoint-md-max {
          max-width: 95vw;
          width: 65vw;
          }

        @include breakpoint-sm-max {
          width: initial;
          max-width: initial;
        }
      }`
  ]
})
export class RenameBinDialog implements AfterViewInit {
  @ViewChild('input') input!: ElementRef;

  static readonly dialogOptions = {hasBackdrop: true};

  isBusy$ = new BehaviorSubject<boolean>(false);

  constructor(
    readonly dialogRef: MatDialogRef<RenameBinDialog>,
    private readonly snackBar: SnackBarService,
    private readonly sharedLinkClipBinService: SharedLinkClipBinService,
    private readonly stateService: StateService,
    private readonly resourceService: ResourceService,
    @Inject(MAT_DIALOG_DATA) public data: {title: string, name: string},
  ) {

  }


  onCancel(){
    this.dialogRef.close();
  }

  onSubmit(renamedTitle:string){
    const _renamedTitle = renamedTitle.trim();

    if (!_renamedTitle || _renamedTitle === this.data.title) return;
    this.isBusy$.next(true);

    this.dialogRef.close(_renamedTitle);

    this.resourceService.renameClipBin(encodeURIComponent(this.data.name), _renamedTitle).subscribe({
      next: () => {
        this.resourceService.isLoading$.next(true);
        this.updateClipBinSharedLinkTitle(encodeURIComponent(this.data.name),_renamedTitle);
        this.snackBar.message('Clip bin has been renamed successfully.');
        this.stateService.searchModeSelected$.next(BinSectionContent.BIN);
        this.resourceService.updateLocalResource(this.data.name, _renamedTitle);
      },
      error: (error) => {
        console.error('Clip bin could not be renamed.', error);
        this.snackBar.error('Clip bin could not be renamed.', undefined, error);
      },
      complete: () => {
        this.isBusy$.next(false);
      }
    });
  }

  isDisabled(): boolean {
    if(!this.input || !this.input.nativeElement)
      return false;

    return this.input.nativeElement.value.trim().length === 0 || this.isBusy$.value;
  }

  ngAfterViewInit() {
    // Select input on next tick to avoid an
    // `ExpressionChangedAfterItHasBeenCheckedError`, see
    // https://angular.io/errors/NG0100.
    setTimeout(() => {
      this.input.nativeElement.select();
    });
  }

  private updateClipBinSharedLinkTitle(name: string, newTitle: string) {
    this.sharedLinkClipBinService.updateClipBinSharedLinkTitle(name, newTitle);
  }
}
