import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable, of, Subject, throwError } from 'rxjs';
import { delay, map } from 'rxjs/operators';

import { AssetsListRequestParams } from 'api/ias/api/projects.service';
import { assertExists } from 'asserts/asserts';
import { AssetCopyStats, AssetRendition, ExportInfo, Metadata, PfrInfo, SignedUrl } from 'models';


import {ResourceChange} from './api_client.module';
import {AssetApiService, MetadataField} from './asset_api_service';
import {Asset, AssetCopy, AssetState, Clip, ListResponse, Original} from './asset_service';
import { BinWithClips } from './bin.service';
import { makeFakeClipBin } from './bin_api_fake_service';
import {createPseudoRandomGenerator, pseudoRandom} from './fake_api_utils';
import {ApiApprovalState, RenditionVersionEnum} from './ias_types';
import {SignUrlResponse} from './shared_links_api_service';
import { FAKE_SITES } from './sites_api_fake_service';
import {Interface} from './utils_service';

/**
 * GCS bucket location:
 * https://pantheon.corp.google.com/storage/browser/ias-nonprod-open-data?project=cloud-media-api-demo
 */
const FAKE_DATA_BUCKET = 'https://storage.googleapis.com/ias-nonprod-open-data';

const FAKE_METADATA: Record<string, unknown> = {
	ContentType: 'Melt',
	Site: 'LAX',
	EventLeague: ' MLB',
	Sport: 'Baseball',
	Filename: '1614914434 - melt.mxf',
	EventLocation: ['Bronx, New York', 'Jersey City, New Jersey'],
	Description: '10/11/2015 Seattle Seahawks vs Cincinnati Bengals',
	Title: ' MLB 20150411 BOS at NYY BOS Kelly strikes out Beltran wide',
	Category: 'CATEGORIES/Sports Library/MLB/Melts/2015/Week 1/BOS/NYY',
	EventCourtesy: 'FOX',
	SourceRouter: 'Some source',

	CreatedAt: '2015-04-14T00:00:08.410Z',
	EventDate: '2015-04-11T07:00:00Z',
	ModifiedAt: '2020-06-10T00:18:06.330Z',
	StartOfMaterial: '2015-04-13T20:16:27.372Z',

	ViewCount: 20,
	DisableMLAnnotations: true,
	CategoryList: ['Sports Library ', 'MLB ', 'Melts'],

	EventAwayTeam: 'Miami Marlins',
	EventHomeTeam: 'Kansas City Royals',
};

/** Serves assets */
@Injectable({ providedIn: 'root' })
export class FakeAssetApiService implements Interface<AssetApiService> {
  getRecentsPagination(pageToken?: string): Observable<ListResponse<Original>> {
		return of({assets:getFakeVodOriginals().slice(1, 4), nextPageToken:pageToken}).pipe(delay(300));
  }

	readonly assetChanged$ = new Subject<ResourceChange<Original>>();

	readonly assetsChanged$ = new Subject<{ updates: Map<string, Original> }>();

	getRecents(): Observable<Original[]> {
		return of(getFakeVodOriginals().slice(1, 4)).pipe(delay(300));
	}

	getOne(name: string): Observable<Original> {
		return of(getAllFakeAssets()).pipe(
			map((assets) => {
				const found = assets.find((asset) => asset.name === name);
				if (!found) {
					throw new Error(`Asset not found`);
				}
				return found;
			}),
			delay(300),
		);
	}

	list(params: Partial<AssetsListRequestParams> = {}) {
		const assets = getFakeVodOriginals();
		const index = Number(params?.pageToken || 0);
		const pageSize = params?.pageSize || 30;

		const first = index * pageSize;
		const firstOnNextPage = (index + 1) * pageSize;
		const nextPageToken = firstOnNextPage < assets.length ? String(index + 1) : '';

		return of({ assets: assets.slice(first, firstOnNextPage), nextPageToken });
	}

	getProcessing() {
		// Every 60s, returns a decreasing number of assets (3 to 0).
		const nbAssets = Math.floor((Date.now() % 60_000) / 15_000);

		const assets = [
			makeFakeOriginal({ name: 'Some_Asset' }),
			makeFakeOriginal({ name: 'Another_One' }),
			makeFakeOriginal({ name: 'Latest_Item' }),
		];
		const response = { assets: assets.slice(nbAssets), nextPageToken: '' };
		return of(response).pipe(delay(100));
	}

	signUrls(rawUrls: string[]): Observable<SignUrlResponse> {
		const signedUrls = rawUrls.map((rawUrl) => {
			return new SignedUrl({
				rawUrl,
				signedUrl: rawUrl.replace('gs://', 'https://storage.cloud.google.com/'),
			});
		});
		return of({ signedUrls }).pipe(delay(100));
	}

	updateMetadata(current: Asset, schema: string, jsonMetadata: Record<string, unknown>) {
		const asset = getAllFakeAssets().find((asset) => asset.name === current.name);
		if (!asset) {
			return throwError(() => 'Asset to update metadata not found');
		}
		return of({
			...asset,
			assetMetadata: new Metadata({ jsonMetadata, schema }),
		}).pipe(delay(400));
	}

	updateApprovalState(current: Asset, state: ApiApprovalState) {
		const asset = getAllFakeAssets().find((asset) => asset.name === current.name);
		if (!asset) return throwError(() => 'Asset not found');

		return of({ ...asset, approved: state === 'APPROVED' }).pipe(delay(400));
	}

	generateUploadUri(fileName: string, mimeType: string) {
		return of('https://storage.googleapis.com/test-folder/' + fileName + mimeType);
	}

	delete(asset: Original) {
		return of({ ...asset, isDeleted: true }).pipe(delay(400));
	}

	undelete(asset: Original) {
		return of({ ...asset, isDeleted: false }).pipe(delay(400));
	}

	deleteEvent(asset: Original) {
		return this.delete(asset);
	}

	createCopy(): Observable<AssetCopy> {
		throw new Error('Method not implemented.');
	}

	listCopies(): Observable<AssetCopy[]> {
		throw new Error('Method not implemented.');
	}

	approveCopy(): Observable<AssetCopy> {
		throw new Error('Method not implemented.');
	}

	deleteCopy(): Observable<null> {
		throw new Error('Method not implemented.');
	}

	updateCopyMetadata(): Observable<AssetCopy> {
		throw new Error('Method not implemented.');
	}

	refreshAsset(asset: Original): Observable<Original> {
		return of(asset).pipe(delay(400));
	}
}

/** Used for local development only */
const fakeDistinctAssets: Original[] = [
	makeFakeOriginal({
		name: 'ChromeCast',
		title: 'ChromeCast Ad',
		duration: 60.07,
	}),
	makeFakeOriginal({
		name: 'BBB',
		title: 'Big Buck Bunny',
		duration: 596.475,
	}),
	makeFakeOriginal({
		name: 'SundarInterview',
		title: 'Sundar Interview',
		duration: 3613.884,
	}),
	makeFakeOriginal({
		name: 'Sintel',
		title: 'Sintel',
		duration: 887.999,
	}),
];

/** Used for local development only */
const fakeDistinctLiveAssetPartials: Array<Partial<Original>> = [
	{
		name: 'NBAFinals',
		title: '#1 NBA Finals',
		renditions: [
			new AssetRendition({
				url: 'https://storage.googleapis.com/livestream-demo-output/demo/mini-manifest-1/manifest.mpd',
				version: 'LIVE_MAIN_DASH',
			}),
		],
	},
	{
		name: 'PostGameShow',
		title: '#2 Post Game Show',
		renditions: [
			new AssetRendition({
				url: 'https://storage.googleapis.com/livestream-demo-output/demo/mini-manifest-2/manifest.mpd',
				version: 'LIVE_MAIN_DASH',
			}),
		],
	},
	{
		name: 'MMAFridayLive',
		title: '#3 MMA Friday Live',
		renditions: [
			new AssetRendition({
				url: 'https://storage.googleapis.com/livestream-demo-output/demo/mini-manifest-3/manifest.mpd',
				version: 'LIVE_MAIN_DASH',
			}),
		],
	},
	{
		name: 'FootballFootballFootball',
		title: '#4 Football! Football! Football!',
		renditions: [
			new AssetRendition({
				url: 'https://storage.googleapis.com/livestream-demo-output/demo/mini-manifest-4/manifest.mpd',
				version: 'LIVE_MAIN_DASH',
			}),
		],
	},
];

/** Helper function for local development and unit tests. */
export function makeFakeOriginal(
	values: Partial<Original> = {},
	metadataValues: Record<string, unknown> = {},
): Original {
	const name = values.name || 'fake-id';
	const prefix = FAKE_DATA_BUCKET;
	const duration = values.duration || 1000;
	const endTime = values.endTime || duration;

	const defaultValues: Original = {
		name,
		title: 'Fake Asset',
		startTime: 0,
		endTime,
		eventStartTime: 0,
		eventEndTime: 0,
		preCut: 0,
		postCut: 0,
		duration,
		eventTime: DateTime.fromObject({ year: 2020, month: 3, day: 14 }).toMillis(),
		createTime: DateTime.fromObject({ year: 2021, month: 7, day: 15 }).toMillis(),
		updateTime: DateTime.fromObject({ year: 2021, month: 8, day: 1 }).toMillis(),
		renditions: [
			new AssetRendition({
				version: RenditionVersionEnum.PREVIEW_MAIN,
				url: `${prefix}/${name}_preview_30.mp4`,
			}),
			new AssetRendition({
				version: RenditionVersionEnum.PREVIEW_SEEK,
				url: `${prefix}/${name}_preview_seek.mp4`,
			}),
			new AssetRendition({
				version: RenditionVersionEnum.SMALL_LOW_FPS,
				url: `${prefix}/${name}_0.1fps.mp4`,
			}),
			new AssetRendition({
				version: RenditionVersionEnum.SMALL_MID_FPS,
				url: `${prefix}/${name}_1fps.mp4`,
			}),
			new AssetRendition({
				version: RenditionVersionEnum.SMALL_HIGH_FPS,
				url: `${prefix}/${name}_5fps.mp4`,
			}),
			new AssetRendition({
				version: RenditionVersionEnum.SMALL_DYNAMIC_FPS,
				url: `${prefix}/${name}_5fps.mp4`,
			}),
		],
		thumbnail: `${prefix}/${name}_thumbnail.png`,
		gcsLocationUrl: `gs://${name}.mp4`,
		state: AssetState.VOD,
		streamingState: 'STREAMING_STATE_UNSPECIFIED',
		assetMetadata: new Metadata({
			jsonMetadata: { ...FAKE_METADATA, ...metadataValues },
			schema: '/fake/schema#1',
		}),
		isLive: false,
		hasError: false,
		isDeleted: false,
		approved: true,
		videoFormat: {
			frameRate: '60',
			widthPixels: 1080,
			heightPixels: 720,
		},
		copyStats: new AssetCopyStats(),
	};

	return { ...defaultValues, ...values };
}

/** Helper function for local development and unit tests. */
export function makeFakeClip(clipValues: Partial<Clip> = {}, originalValues: Partial<Original> = {}): Clip {
	const original = makeFakeOriginal(originalValues);

	const defaultClipValues = {
		label: 'fakeBin-id-0',
		pfrInfo: createClipExportFolders(originalValues),
		exportInfo: new ExportInfo({}),
	};

	return {
		...original,
		...defaultClipValues,
		...clipValues,
		original,
	};

	function createClipExportFolders(originalValues: Partial<Original>): PfrInfo {
		let pfrInfoDataJson;
		switch (originalValues.title) {
			case '29. Sundar Interview': {
				pfrInfoDataJson = `{
        "stateMap": {
          "projects/234973717435/locations/global/sites/dev/folders/Export Folder 2": {
            "state": "EXPORT_FAILED",
            "createTime": "2022-11-09T00:49:29.219428118Z",
            "updateTime": "2022-11-09T00:50:06.533691591Z",
            "errorDetails": {
              "code": 13,
              "message": "Failed to export clip."
            }
          }
        }
      }`;
				break;
			}
			case '27. ChromeCast Ad': {
				pfrInfoDataJson = `{
        "stateMap": {
          "projects/234973717435/locations/global/sites/dev/folders/Export Folder 3": {
            "state": "EXPORT_PENDING",
            "createTime": "2022-11-09T00:49:29.219428118Z",
            "updateTime": "2022-11-09T00:50:06.533691591Z"
          }
        }
      }`;
				break;
			}
			case '26. Big Buck Bunny': {
				pfrInfoDataJson = `{
        "stateMap": {
          "projects/234973717435/locations/global/sites/dev/folders/Export Folder 4": {
            "state": "EXPORT_COMPLETED",
            "createTime": "2022-11-09T00:49:29.219428118Z",
            "updateTime": "2022-11-09T00:50:06.533691591Z"
          }
        }
      }`;
				break;
			}
			default:
				return new PfrInfo({});
		}
		return JSON.parse(pfrInfoDataJson) as PfrInfo;
	}
}

/** Helper function for local development and unit tests. */
export function makeFakeLiveOriginal(
    values: Partial<Original> = {},
    metadataValues: Record<string, unknown> = {}): Original {
  const original = makeFakeOriginal({
    state: AssetState.AIRING,
    streamingState: 'STREAMING',
    isLive: true,
    startTime: 0,
    endTime: 0,
    duration: 0,
    eventStartTime: DateTime.fromObject({year: 2021, month: 6, day: 12, hour: 13, minute: 45}).toMillis(),
    eventEndTime: DateTime.fromObject({year: 2021, month: 6, day: 12, hour: 17, minute: 15}).toMillis(),
    assetMetadata: new Metadata({
      jsonMetadata: {...FAKE_METADATA, ...metadataValues},
      schema: '/fake/schema#1',
    }),
    // Live assets normally exist in DRAFT state unlike VoDs.
    approved: false,
    ...values,
    source: getRandomFakeSite(),
  });

	original.duration = original.endTime - original.startTime;

	if (!values.renditions) {
		original.renditions = [
			new AssetRendition({
				version: RenditionVersionEnum.LIVE_MAIN_DASH,
				url: `fake/${original.name}_live_main.mpd`,
			}),
			new AssetRendition({
				version: RenditionVersionEnum.LIVE_PREVIEW_DASH,
				url: `fake/${original.name}_live_preview.mpd`,
			}),
		];
	}

	original.assetMetadata.jsonMetadata = {
		[MetadataField.HI_RES_FILE_PATH]: 'A://high_res.mp4',
		[MetadataField.SITE]: 'site0',
		...original.assetMetadata.jsonMetadata,
	};

	return original;
}

/** Helper function for local development and unit tests. */
export function makeFakeLiveClip(values: Partial<Clip> = {}): Clip {
	const original = makeFakeLiveOriginal({ ...values, original: undefined });
	return makeFakeClip({ endTime: 1000, ...values }, original);
}

function makeFakeOriginals(sources: Original[], count: number): Original[] {
	const assets: Original[] = [];
	for (let i = 0; i < count / 2; i++) {
		const rand = pseudoRandom(`make-fake-asset-${count}-${i}`);
		const asset: Original = { ...sources[Math.floor(rand * sources.length)] };
		const created = new Date(2021, 6, i % 30);
		const eventTime = new Date(2020, 2, i % 30);
		asset.name = `${i}.${asset.name}`;
		asset.title = `${i}. ${asset.title}`;
		asset.createTime = created.setHours(created.getHours() - (i % 3) * 12);
		asset.eventTime = eventTime.setHours(eventTime.getHours() - (i % 3) * 12);
		asset.state = AssetState.PROCESSING;
		assets.push(asset);
	}
	for (let i = 0; i < count / 2; i++) {
		const rand = pseudoRandom(`make-fake-asset-${count}-${i}`);
		const asset: Original = { ...sources[Math.floor(rand * sources.length)] };
		const created = new Date(2021, 6, i % 30);
		const eventTime = new Date(2020, 2, i % 30);
		asset.name = `${i}.${asset.name}`;
		asset.title = `${i}. ${asset.title}`;
		asset.createTime = created.setHours(created.getHours() - (i % 3) * 12);
		asset.eventTime = eventTime.setHours(eventTime.getHours() - (i % 3) * 12);
		assets.push(asset);
	}
	return assets;
}

/** Simulates a database call with new references each call. */
export function getFakeVodOriginals() {
	// Assets are always ordered by createTime.
	return makeFakeOriginals(fakeDistinctAssets, 100).sort((asset1, asset2) => {
		return asset2.createTime - asset1.createTime;
	});
}

/** Generates pseudo random live assets. */
export function getFakeLiveOriginals(states: AssetState[], date?: DateTime, count?: number): Original[] {
	const sources = fakeDistinctLiveAssetPartials;
	const assets: Original[] = [];
	const seed = `fake-${date}${states.join('')}`;
	const rand = createPseudoRandomGenerator(`fake-${date}${states.join('')}`);
	let correlationIdCounter = 0;
	let cameraCounter = 0;
	// TODO: Skip first generated psuedo-random value as it consistently small.
	rand();
	date = date ?? DateTime.fromObject({ year: 2020, month: 4, day: 14 });
	count = count ?? Math.ceil(500 * rand());

	let currentPrimaryAsset: Asset | undefined = undefined;

	for (let i = 0; i < count; i++) {
		const startDate = date.plus({ hours: Math.floor(rand() * 24) });
		const endDate = startDate.plus({ minutes: Math.floor(rand() * 30) + 30 });
		const asset = makeFakeLiveOriginal({
			...sources[Math.floor(rand() * sources.length)],
			eventStartTime: startDate.valueOf(),
			eventEndTime: endDate.valueOf(),
			startTime: 0,
			endTime: 0,
			createTime: DateTime.fromObject({ year: 2020, month: 3, day: 14 }).valueOf(),
			state: states[Math.floor(states.length * rand())],
		});
		asset.name = `${i}-${asset.name}-${seed}`;
		asset.title = `${i}.${asset.state} ${asset.title}`;

		// Make some assets part of multi-camera events.
		if ([AssetState.AIRING, AssetState.ENDED].includes(asset.state) && rand() > 0.7) {
			// Non-broadcast events are not displayed on Live landing view and are
			// only accessible through multi-camera view.
			const isBroadcast = !currentPrimaryAsset || rand() > 0.8;
			if (isBroadcast) {
				cameraCounter = 0;
				correlationIdCounter++;
				currentPrimaryAsset = asset;
			}
			asset.camera = {
				isBroadcast,
				correlationId: `${correlationIdCounter}`,
				label: `camera ${++cameraCounter}`,
			};
			assertExists(currentPrimaryAsset?.camera);
			currentPrimaryAsset.camera.totalCount = cameraCounter;
		}
		assets.push(asset);
	}

	return assets;
}

/** Simulates a database call with new references each call. */
export function getDefaultFakeLiveAssets(): Original[] {
	return [
		...getFakeLiveOriginals(
			[AssetState.AIRING, AssetState.ENDED, AssetState.PENDING, AssetState.PROCESSING, AssetState.SCHEDULED],
			undefined,
			1000,
		),
		...makeFakeOriginals(fakeDistinctAssets, 2),
	];
}

/** Simulates a database call of all original VoD and Live assets. */
export function getAllFakeAssets() {
	return [...getFakeVodOriginals(), ...getDefaultFakeLiveAssets()];
}

/** Generate fake asset cutdown for test purposes */
export function makeFakeAssetCopy(
	properties: Partial<AssetCopy> = {},
	metadata: Record<string, unknown> = {},
): AssetCopy {
	const copy: AssetCopy = {
		name: 'fake-name',
		startOffset: 25,
		endOffset: 50,
		isFullCopy: false,
		fileName: 'fake.mxf',
		metadata: new Metadata(),
		state: 'STATE_DRAFT',
		...properties,
	};

	if (copy.isFullCopy) {
		copy.startOffset = 0;
		copy.endOffset = 0;
	}

	copy.metadata.jsonMetadata = {
		...copy.metadata.jsonMetadata,
		...metadata,
	};
	return copy;
}

/**
 * Test helper function to generate fake clip bins
 *
 * @param pageIndex the page index
 */
export function makeFakeClipBins(pageIndex: number) {
	const testBins: BinWithClips[] = [];
	for (let i = 0; i < 50; i++) {
		const customizedBin: Partial<BinWithClips> = {};
		customizedBin.name = `fakeBin-id-${i}`;
		customizedBin.assetCount = String(i);
		customizedBin.createTime = Date.now();
		switch (i % 2) {
			case 0:
				customizedBin.title = `Even Clip Bin ${pageIndex} ${i}`;
				break;
			default:
				customizedBin.title = `Odd Clip Bin ${pageIndex} ${i}`;
				break;
		}
		testBins.push(makeFakeClipBin(customizedBin));
	}
	return testBins;
}

function getRandomFakeSite(): string {
  return FAKE_SITES[Math.floor(FAKE_SITES.length * Math.random())].toUpperCase();
}
