<div class="content">
  <main #scrollView class="main--{{ transferView }}">
    <div class="main-block">
      <!-- Transfer type panel -->
      <mat-tab-group
          mat-stretch-tabs="false"
          mat-align-tabs="start"
          [selectedIndex]="selectedTabIndex"
          (selectedTabChange)="changeTab($event.index)">
        <ng-container *ngIf="transferView === TransferViewType.STAGING">
          <mat-tab
              mam-firebase-ga="Navigate to live content staging"
              label="Live Staging">
            <ng-template mat-tab-label>
              <span>Live Staging</span>
            </ng-template>
          </mat-tab>
          <mat-tab
              mam-firebase-ga="Navigate to VoD content staging"
              label="Staging">
            <ng-template mat-tab-label>
              <span>VoD Staging</span>
            </ng-template>
          </mat-tab>
          <mat-tab
              mam-firebase-ga="Navigate to VoD content staging errors"
              label="VoD Errors">
            <ng-template mat-tab-label>
              <span>VoD Errors</span>
            </ng-template>
          </mat-tab>
        </ng-container>
        <ng-container *ngIf="transferView === TransferViewType.JOBS">
          <mat-tab
              *ngIf="transferView === TransferViewType.JOBS"
              mam-firebase-ga="Navigate to transfers"
              label="Transfers">
            <ng-template mat-tab-label>
              <span>Transfers</span>
            </ng-template>
          </mat-tab>
          <mat-tab
              mam-firebase-ga="Navigate to local upload"
              label="LocalUpload">
            <ng-template mat-tab-label>
              <span>Local Uploads</span>
            </ng-template>
          </mat-tab>
          <mat-tab
              *featureFlagOn="'use-cloud-ingest'"
              mam-firebase-ga="Navigate to cloud ingest"
              label="Storage">
            <ng-template mat-tab-label>
              <span>Cloud Ingests</span>
            </ng-template>
          </mat-tab>
          <mat-tab
              mam-firebase-ga="Navigate to deleted items"
              label="DeletedItems">
            <ng-template mat-tab-label>
              <span>Deleted Items</span>
            </ng-template>
          </mat-tab>
          <mat-tab
              mam-firebase-ga="Navigate to VoD export table"
              label="VodExport"
              *featureFlagOn="'use-vod-live-export-monitor'">
            <ng-template mat-tab-label>
              <span>VoD Clips Export</span>
            </ng-template>
          </mat-tab>
          <mat-tab
              mam-firebase-ga="Navigate to live export table"
              label="LiveExport"
              *featureFlagOn="'use-vod-live-export-monitor'">
            <ng-template mat-tab-label>
              <span>Live Clips Export</span>
            </ng-template>
          </mat-tab>
          <mat-tab
              mam-firebase-ga="Navigate to comp reel export table"
              label="CompReel"
              *featureFlagOn="'use-comp-reel-export-monitor'">
            <ng-template mat-tab-label>
              <span>Comp Reels Export</span>
            </ng-template>
          </mat-tab>
        </ng-container>
      </mat-tab-group>

      <!-- Selected tab content -->
      <ng-container *ngIf="selectedTabIndex != null">
        <ng-container [ngSwitch]="tabs[selectedTabIndex]">
          <div *ngSwitchCase="'live'">
            <mam-live-staging-table (scrollTopNeeded)="scrollTop()">
            </mam-live-staging-table>
          </div>
          <div *ngSwitchCase="'vod'">
            <mam-vod-staging-table (scrollTopNeeded)="scrollTop()">
            </mam-vod-staging-table>
          </div>
          <div *ngSwitchCase="'vod-error'">
            <mam-vod-staging-error-table (scrollTopNeeded)="scrollTop()">
            </mam-vod-staging-error-table>
          </div>
          <div *ngSwitchCase="'local-upload'">
            <mam-local-upload-table [displayDuration]="false">
            </mam-local-upload-table>
          </div>
          <div *ngSwitchCase="'cloudIngest'">
            <mam-cloud-ingest-table>
            </mam-cloud-ingest-table>
          </div>
          <div *ngSwitchCase="'deleted-assets'">
            <mam-asset-deletion-table-legacy (scrollTopNeeded)="scrollTop()">
            </mam-asset-deletion-table-legacy>
          </div>
          <div *ngSwitchCase="'deleted-items'">
            <mam-item-deletion-table (scrollTopNeeded)="scrollTop()">
            </mam-item-deletion-table>
          </div>
          <div *ngSwitchCase="'vod-export'">
            <mam-export-monitor
                [exportType]="'VoD'"
                (scrollTopNeeded)="scrollTop()">
            </mam-export-monitor>
          </div>
          <div *ngSwitchCase="'live-export'">
            <mam-export-monitor
                [exportType]="'Live'"
                (scrollTopNeeded)="scrollTop()">
            </mam-export-monitor>
          </div>
          <div *ngSwitchCase="'comp-reel'">
            <mam-export-monitor
                [exportType]="'CompReel'"
                [displayDuration]="false"
                (scrollTopNeeded)="scrollTop()">
            </mam-export-monitor>
          </div>
          <mam-transfer-monitor *ngSwitchDefault>
          </mam-transfer-monitor>
        </ng-container>
      </ng-container>
    </div>
  </main>
</div>
